import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';

import classNames from 'classnames/bind';

import _SignUp from '~/components/_SignUp';
import _Login from '~/components/_Login';
import _Industries from '~/components/_Industries';
import ForgotPassword from '~/components/ForgotPassword';
import _GigGikStatement from '~/components/_GigGikStatement';
import styles from './Popup.module.scss';
import './Modal.scss';
import { useDispatch, useSelector } from 'react-redux';
import { close } from '~/reducers/popupReducer';

const cx = classNames.bind(styles);

Modal.setAppElement('#root');

function Popup({ modal, show }) {
    const dispatch = useDispatch();

    useEffect(() => {
        document.body.style.overflow = show ? 'hidden' : 'unset';
    }, [show]);

    return (
        <Modal
            isOpen={show}
            preventScroll={true}
            style={{
                overlay: {
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'rgba(153, 153, 153, 0.75)',
                    zIndex: 1001,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                },
            }}
            className={`popup-modal ${modal}`}
        >
            <div className={cx('modal-background')}></div>
            <div className={cx('modal-wrapper')}>
                <div className={cx('modal-header')}>
                    <button type="button" className="close" onClick={() => dispatch(close())}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                {modal == 'login' && <_Login />}
                {modal == 'industries' && <_Industries />}
                {modal == 'signup' && <_SignUp />}
                {modal == 'statement' && <_GigGikStatement />}
                {modal == 'forgotPassword' && <ForgotPassword />}
            </div>
        </Modal>
    );
}

export default Popup;
