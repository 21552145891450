import { createSlice } from '@reduxjs/toolkit';

export const cookiePopupSlice = createSlice({
    name: 'cookiePopup',
    initialState: {
        show: false,
    },
    reducers: {
        setShowCookiePopup: (state, action) => {
            return {
                show: action.payload,
            };
        },
    },
});

// Action creators are generated for each case reducer function
export const { setShowCookiePopup } = cookiePopupSlice.actions;

export default cookiePopupSlice.reducer;
