import classNames from 'classnames/bind';
import { useState } from 'react';
import styles from './ForgotPassword.module.scss';

import _Password from '../_Password';
import PhoneNumber from '../PhoneNumber';
import { useTranslation } from 'react-i18next';

import Loading from '../Loading';
import { requestCheckVerifyCode, requestSendVerifyCode, requestResetPassword } from '~/services/authService';
import { open, close } from '~/reducers/popupReducer';
import { login } from '~/reducers/authReducer';
import { useDispatch, useSelector } from 'react-redux';


const defaultCredential = {
    email: '',
    phone: '',
    areaCode: 100,
    code: '',
    password: '',
    password_confirmation: '',

}
const cx = classNames.bind(styles);

function ForgotPassword() {

    const [loading, setLoading] = useState(false)
    const [getVerifyCode, setGetVerifyCode] = useState(false)
    const [verified, setVerified] = useState(false)
    const [tab, setTab] = useState('email')
    const [error, setError] = useState({})
    const dispatch = useDispatch()
    const { auth, dropdown } = useSelector(state => state)

    const handleGetVerifyCode = async (e) => {
        e.preventDefault()
        setError({})
        let isValid = true
        let data = {}
        let error = {}
        if (tab === 'phone') {
            data = {
                type: tab,
                area_code_id: loginCredential.areaCode,
                phone: loginCredential.phone
            }
        } else {
            data = {
                type: tab,
                email: loginCredential.email
            }
        }
        if (data.phone && data.area_code_id) {
            let areaCode = dropdown ? dropdown.areacodes.find(areaCode => areaCode.id === data.area_code_id) : undefined
            if (areaCode) {
                if (areaCode.area_code.includes('852')) {
                    var phone_format = new RegExp("^[0-9]{8}$")
                    if (!data.phone.match(phone_format)) {
                        isValid = false
                        error = {
                            ...error,
                            phone: [t('error.phone_must_be_eight')]
                        }
                    }
                }
            }
        }

        if ((data.phone && data.area_code_id) || data.email) {

        } else {
            isValid = false
        }


        if (isValid) {
            setLoading(true)
            const response = await requestSendVerifyCode(data)
            setLoading(false)
            if (response.message) {
                setError(response.message)
            } else {
                setError({})
                setGetVerifyCode(true)
            }
        } else {
            setError(error)
        }
    }

    const handleVerify = async (e) => {
        e.preventDefault();
        const { areaCode, phone, email, tab, code } = loginCredential
        let data = {}
        if (tab === 'phone') {
            data = {
                type: tab,
                area_code_id: areaCode,
                phone,
                code
            }
        } else {
            data = {
                type: tab,
                email,
                code
            }
        }

        const res = await requestCheckVerifyCode({
            ...data
        })
        if (res.message) {
            setError(res.message);
        } else {
            setVerified(true)
            setGetVerifyCode(false)
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const { areaCode, phone, email, tab, password, password_confirmation, code } = loginCredential
        let data = {}
        if (tab == 'phone') {
            data = {
                type: tab,
                area_code_id: areaCode,
                phone,
                password,
                password_confirmation,
                code,
            }
        } else {
            data = {
                type: tab,
                email,
                password,
                password_confirmation,
                code,
            }
        }


        const response = await requestResetPassword(data)
        if (response.message) {
            setError(response.message)
        } else {
            dispatch(login(response.user))
            dispatch(close())
        }
    }

    const onPhoneChange = (value) => {
        setLoginCredential({
            ...loginCredential,
            ...value
        })
    }

    const onChangeEmail = (e) => {
        setLoginCredential({
            ...loginCredential,
            email: e.target.value
        })
    }

    const onChangeVerifyCode = (e) => {
        setLoginCredential({
            ...loginCredential,
            code: e.target.value
        })
    }

    const onChangePassword = (e) => {
        setLoginCredential({
            ...loginCredential,
            password: e.target.value
        })
    }

    const onChangeConfirmPassword = (e) => {
        setLoginCredential({
            ...loginCredential,
            password_confirmation: e.target.value
        })
    }

    const onTabChange = (tab) => (e) => {
        e.preventDefault()
        setTab(tab)
    }

    const { t } = useTranslation()
    const [loginCredential, setLoginCredential] = useState(defaultCredential)

    return (
        <div className={cx('modal-body', 'modal-forgotpassword')}>
            <div className={cx('title-wrapper')}>
                <h2>{t('forgotPassword.forgot_password_title')}</h2>
            </div>
            <div className={cx('fields')}>
                <p style={{ margin: '10px 0px 20px 0', textAlign: 'center', fontSize: '16px', width: '85%' }}>{t('forgotPassword.hints')}</p>
                {
                    verified === false &&
                    <form className='__w100' onSubmit={handleGetVerifyCode}>
                        <div className={cx('tabs')}>
                            <a href="#" className={`${tab === 'email' ? cx('active') : ''}`} onClick={onTabChange('email')}>{t('login.email')}</a>
                            <a href="#" className={`${tab === 'phone' ? cx('active') : ''}`} onClick={onTabChange('phone')}>{t('login.phone')}</a>
                        </div>
                        <div className={`${tab === 'email' ? 'field-wrapper __flex __flex-d-column __w100' : '__d-none'}`}>
                            <div className={cx('input')}>
                                <input type='text' name='email' placeholder={t('placeholder.email')} value={loginCredential.email} onChange={onChangeEmail} />
                                {
                                    error.email &&
                                    <div className="help-block">{error.email.map(error => error)}</div>
                                }
                            </div>
                        </div>
                        <div className={`${tab === 'phone' ? 'field-wrapper __flex __flex-d-column __w100' : '__d-none'}`}>
                            <PhoneNumber codes={dropdown?.areacodes} value={loginCredential} onChange={onPhoneChange} />
                            {
                                error.phone &&
                                <div className="help-block">{error.phone.map(error => error)}</div>
                            }
                        </div>
                        <button className={cx('button-submit', 'field-wrapper')} type="submit" onClick={handleGetVerifyCode} disabled={(loginCredential.email === '' && loginCredential.phone === '') || loading === true}>{t('signup.get_verification_code')}</button>
                    </form>
                }
                {
                    getVerifyCode === true &&
                    <div className={cx('password-form', '__w100')}>
                        <div className={cx('field-wrapper')}>
                            <input className={cx('input')} name="code" value={loginCredential.code} onChange={onChangeVerifyCode} placeholder={t('placeholder.verify_code')} />
                            {
                                error.code &&
                                <div className="help-block">{error.code.map(error => t('error.' + error))}</div>
                            }
                        </div>
                        <button className={cx('button-submit', 'field-wrapper')} type="submit" onClick={handleVerify} disabled={loginCredential.code === ''}>{t('forgotPassword.submit')}</button>
                    </div>
                }
                {
                    verified === true &&
                    <div className={cx('password-form', '__w100')}>
                        <div className={cx('field-wrapper')}>
                            <_Password value={loginCredential.password} onChange={onChangePassword} />
                        </div>
                        <div className={cx('field-wrapper')}>
                            <_Password value={loginCredential.password_confirmation} onChange={onChangeConfirmPassword} />
                            {
                                error.password &&
                                <div className="help-block">{error.password.map(error => t('error.' + error))}</div>
                            }
                        </div>
                        <div className={cx('border-blue-remind-wrap', 'field-wrapper')}>
                            {t('placeholder.password_requirement')}
                        </div>
                        <button className={cx('button-submit', 'field-wrapper')} type="submit" onClick={handleSubmit} disabled={(loginCredential.password === '' || loginCredential.password_confirmation === '')}>{t('forgotPassword.submit')}</button>
                    </div>

                }
                {
                    loading === true &&
                    <div className="center-center mb-2">
                        <Loading />
                    </div>
                }

                <p className="cursor-pointer" style={{ textAlign: 'center', fontSize: '1.6rem', padding: '10px', textDecoration: 'underline', marginTop: '20px' }} onClick={() => dispatch(open('login'))}>{t('forgotPassword.back')}</p>
            </div>
        </div>
    )
}

export default ForgotPassword