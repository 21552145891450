import { createSlice } from '@reduxjs/toolkit'


let user = localStorage.getItem('user')
if (user) {
    user = {
        ...JSON.parse(user),
        loggedIn: true
    }
} else {
    user = {
        loggedIn: false
    }
}

export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        ...user
    },
    reducers: {
        login: (state, action) => {
            localStorage.setItem('user', JSON.stringify(action.payload));
            return state = {
                ...action.payload,
                loggedIn: true
            }
        },
        logout: (state) => {
            localStorage.removeItem('user');
            localStorage.removeItem('token');
            return state = {
                loggedIn: false
            }
        },
    },
})

// Action creators are generated for each case reducer function
export const { login, logout } = authSlice.actions

export default authSlice.reducer