import React, { useEffect } from 'react';
import classNames from 'classnames/bind';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCheckCircle,
    faChevronRight,
    faCircleDollarToSlot,
    faDollar,
    faExpandAlt,
    faLocationDot,
    faMapLocationDot,
} from '@fortawesome/free-solid-svg-icons';
import images from '~/assets/images';
import Button from '../Button';
import {
    FavoriteIcon,
    FavoriteSelectedIcon,
    SlidePrevArrowIcon,
    SlideNextArrowIcon,
    BagFillIcon,
    UserFillIcon,
    LocationDotIcon,
    EyeIconWhite,
    EyeIconBlack,
    HumanResourceIcon,
} from '~/components/Icons';

import styles from './_Industries.module.scss';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as homepageService from '~/services/homepageService';
const cx = classNames.bind(styles);

function _Popular() {
    const dropdown = useSelector(state => state.dropdown)
    const [industries, setIndustries] = useState([])
    const { t, i18n } = useTranslation()

    useEffect(() => {
        let newIndustries = []
        if (dropdown?.industries?.length > 0) {
            newIndustries = dropdown?.industries?.map((industry) => {
                return {
                    link: `${process.env.REACT_APP_BASE_URL}/${i18n.languages[0]}/search?industries[]=${industry.id}`,
                    textContent: industry.value,
                    slug: industry.slug
                }
            })
        }

        if (newIndustries.length > 0) {
            setIndustries(newIndustries)
        }
    }, [dropdown])

    const handleJobClick = (id) => () => {
        homepageService.itemClick({
            section: 'homepage',
            sub_section: 'Industry',
            object_type: 'industry',
            object_id: id,
        })
    }

    let leftCol = []
    let rightCol = []
    industries.forEach((article, index) => {
        if (index % 2 == 0) {
            leftCol.push(
                <a className={cx('popular-item')} key={index} href={article?.link} onClick={handleJobClick(article?.id)}>
                    <div className={cx('icon-wrapper')}>
                        <img src={`${process.env.REACT_APP_RESOURCE_URL}/images/candidate/home/industries/${article?.slug}.png`} />
                    </div>
                    <span>{article?.textContent}</span>
                </a>
            );
        } else {
            rightCol.push(
                <a className={cx('popular-item')} key={index} href={article?.link} onClick={handleJobClick(article?.id)}>
                    <div className={cx('icon-wrapper')}>
                        <img src={`${process.env.REACT_APP_RESOURCE_URL}/images/candidate/home/industries/${article?.slug}.png`} />
                    </div>
                    <span>{article?.textContent}</span>
                </a>
            )
        }
    });

    return (
        <>
            <div className={cx('popup-popular')}>
                <div className={cx('wrapper')}>
                    <div className={cx('col')}>{leftCol}</div>
                    <div className={cx('col')}>{rightCol}</div>
                </div>
            </div>
        </>
    );
}

export default _Popular;
