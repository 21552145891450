

import React, { useEffect, useState } from 'react';

import axios from "axios";

import FacebookLogin from 'react-facebook-login';
import { requestLoginWithSocialMedia, requestLoginWithGoogle } from '~/services/authService';
import { isMobile } from 'react-device-detect';
import { login } from '~/reducers/authReducer';
import { close } from '~/reducers/popupReducer';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Loading from '../Loading';

import { faFacebook, faLinkedin, faWeixin } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useLinkedIn } from 'react-linkedin-login-oauth2';
import classNames from 'classnames/bind';
import styles from './_SocialMedia.module.scss';
const cx = classNames.bind(styles);

function _SocialMedia({ isLogin }) {
    // constructor(props) {
    //     super(props)
    //     state = {
    //         error: false,
    //         wechatToken: {
    //             code: urlParamsWechatCode,
    //             access_token: '',
    //             refresh_token: '',
    //             openid: '',
    //         },
    //         wechatLoadingStatus: false,
    //         GoogleAuth: undefined,
    //     }
    //     wechatLoginExternalWindow = null;
    //     googleConnectExternalWindow = null;
    // }

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState("")
    const [googleStatus, setGoogleStatus] = useState({
        connected: false
    })
    const { t, i18n } = useTranslation()
    const dispatch = useDispatch()

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const urlParamsType = urlParams.get('type') || '';

    const wetchAppid = 'wx4f2834d98ea9b35e';
    const encodeRefUrl = encodeURIComponent(process.env.REACT_APP_BASE_URL + '/' + i18n.languages[0] + '/account/');
    const wechatEncodeUrl = encodeURIComponent(process.env.REACT_APP_BASE_URL + '/wechat' + '?ref=' + encodeRefUrl + '&type=wechat&option=login&with_openid=');

    let wechatLoginExternalWindow = undefined
    let googleConnectExternalWindow = undefined

    useEffect(() => {
        window.addEventListener('message', receiveMessage, false)
    }, [])

    useEffect(() => {
        if (googleStatus.connected) {
            handleAction({
                code: googleStatus.code,
                type: 'google',
                isLogin
            })
        }
    }, [googleStatus])

    const receiveMessage = (event) => {
        if (event.data.code && event.data.from === 'wechat') {
            wechatLoginExternalWindow && wechatLoginExternalWindow.close();
            if (event.data.option === 'login') {
                handleAction({
                    type: 'wechat',
                    isLogin,
                    wechatCode: event.data.code,
                })
            }
        } else if (event.data.code && event.data.from === 'google') {
            googleConnectExternalWindow && googleConnectExternalWindow.close();
            if (event.data.error) {
            } else {
                setGoogleStatus({
                    connected: true,
                    code: event.data.code,
                })
            }
        }
    }

    const openNewWindow = (url) => (e) => {
        e.preventDefault()
        wechatLoginExternalWindow = window.open(url, '_blank', 'width=600,height=600');
        wechatLoginExternalWindow.removeEventListener('message', receiveMessage, false);
        wechatLoginExternalWindow.addEventListener('message', receiveMessage, false);
    }

    const openNewGoogleWindow = async () => {

        const response = await requestLoginWithGoogle()
        if (response && response.url) {
            googleConnectExternalWindow = window.open(response.url, '_blank', 'width=600,height=600');
            googleConnectExternalWindow.removeEventListener('message', receiveMessage, false);
            googleConnectExternalWindow.addEventListener('message', receiveMessage, false);
        } else if (!response) {
            setError('internal_error')
        } else {
            setError(response.message);
        }
    }

    // componentWillUnmount() {
    //     if (wechatLoginExternalWindow && !wechatLoginExternalWindow.closed) {
    //         wechatLoginExternalWindow.removeEventListener('message', receiveMessage, false);
    //         wechatLoginExternalWindow.close();
    //     }
    // }

    const handleAction = async (data) => {
        const response = await requestLoginWithSocialMedia(data)
        if (response.message) {
            setError(response.message)
        } else if (!response) {
            setError('internal_error')
        } else {
            dispatch(login(response.user))
            dispatch(close())
        }
    }

    const handleFacebookResponse = (data) => {
        console.log('Facebook ', data)
        if (data.id) {
            handleAction({
                social_media_id: data.id,
                first_name: data.name,
                last_name: '',
                type: 'facebook',
                isLogin,
                token: data.accessToken,
                email: data.email
            })
        }
    }

    const { linkedInLogin } = useLinkedIn({
        clientId: '86ukuw5jzmddzj',
        redirectUri: `${process.env.REACT_APP_BASE_URL}/linkedin`,
        scope: "r_emailaddress r_liteprofile",
        onSuccess: (code) => {
            console.log('Linkedin ', code)
            handleAction({
                code: code,
                type: 'linkedin',
                isLogin
            })
        },
        // Change from `onFailure` to `onError`
        onError: (error) => {
            console.log('Fail ', error)
        },
    });

    // initGoogle = () => {
    //     if (state.GoogleAuth == undefined) {
    //         if (window.googleLoad) {
    //             if (window.gapi && window.gapi.auth2) {
    //                 let GoogleAuth = window.gapi.auth2.getAuthInstance()
    //                 setState({ GoogleAuth })
    //             }
    //         }
    //     }
    // }

    const handleGoogleSignIn = () => {
        if (window.googleLoad && window.gapi && window.gapi.auth2) {
            let GoogleAuth = window.gapi.auth2.getAuthInstance()
            if (GoogleAuth.isSignedIn.get()) {
                handleGoogleResponse(true)
            } else {
                GoogleAuth.isSignedIn.listen(handleGoogleResponse)
                GoogleAuth.signIn()
            }
        }
    }

    const handleGoogleResponse = (isSignedIn) => {
        if (isSignedIn) {
            let GoogleAuth = window.gapi.auth2.getAuthInstance()
            let GoogleUser = GoogleAuth.currentUser.get()
            let profile = GoogleUser.getBasicProfile()
            if (profile) {
                handleAction({
                    social_media_id: profile.getId(),
                    first_name: profile.getGivenName(),
                    last_name: profile.getFamilyName(),
                    type: 'google',
                    isLogin,
                    email: profile.getEmail(),
                    accessToken: GoogleUser.getAuthResponse().access_token,
                })
            }
        }
    }

    return (
        <div className={cx('social-container')}>
            <div className="__flex">
                <div className={cx('login-facebook')}>
                    {
                        urlParamsType != 'wechat' &&
                        <FacebookLogin
                            icon={<FontAwesomeIcon icon={faFacebook} />}
                            cssClass={cx('social-button', 'btn')}
                            textButton='Facebook'
                            appId="581179929271004"
                            redirectUri={process.env.REACT_APP_BASE_URL}
                            fields="name,email,picture"
                            disableMobileRedirect={true}
                            callback={handleFacebookResponse}
                        />
                    }
                </div>
                <div className={cx('login-linkedin')}>
                    <button className={cx('social-button', 'btn')} onClick={linkedInLogin}>
                        <FontAwesomeIcon icon={faLinkedin} /> LinkedIn
                    </button>
                </div>
            </div>
            <div className="__flex">
                <div className={cx('login-google')}>
                    <button className={cx('social-button', 'btn')} onClick={openNewGoogleWindow} disabled={window.googleLoad && window.gapi && window.gapi.auth2}>
                        <img src={`${process.env.REACT_APP_RESOURCE_URL}/images/common/google-login.png`} />Google {googleStatus.connected && <Loading />}
                    </button>
                </div>
                <div className={cx('login-wechat')} >
                    <button className={cx('social-button', 'btn')} onClick={openNewWindow(`https://open.weixin.qq.com/connect/qrconnect?appid=${wetchAppid}&redirect_uri=${wechatEncodeUrl}&response_type=code&scope=snsapi_login&state=123&connect_redirect=1#wechat_redirect`)} disabled={isMobile}>
                        <FontAwesomeIcon icon={faWeixin} /> WeChat{loading && !error && <Loading />}
                    </button>
                </div>
            </div>
            {
                error &&
                <div className="help-block">{t('error.' + error)}</div>
            }
        </div >
    );
}

export default _SocialMedia