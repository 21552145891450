import { createSlice } from '@reduxjs/toolkit'

export const dropdownSlice = createSlice({
    name: 'dropdown',
    initialState: {
    },
    reducers: {
        get: (state, action) => {
            return {
                ...action.payload
            }
        },
        clear: (state, action) => {
            return {}
        }
    },
})

// Action creators are generated for each case reducer function
export const { get, clear } = dropdownSlice.actions

export default dropdownSlice.reducer