import { createSlice } from '@reduxjs/toolkit'

export const popupSlice = createSlice({
    name: 'popup',
    initialState: {
        show: false,
        type: ''
    },
    reducers: {
        open: (state, action) => {
            return {
                show: true,
                type: action.payload
            }
        },
        close: (state, action) => {
            return {
                show: false,
                type: ''
            }
        }
    },
})

// Action creators are generated for each case reducer function
export const { open, close } = popupSlice.actions

export default popupSlice.reducer