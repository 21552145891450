import classNames from 'classnames/bind';
import { useEffect } from 'react';
import styles from './Chatroom.module.scss';

import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Echo from 'laravel-echo';
import { useDispatch, useSelector } from 'react-redux';

import { toggleChatroom } from '~/reducers/chatroomReducer';
import { useTranslation } from 'react-i18next';

window.io = require('socket.io-client');
const cx = classNames.bind(styles);

function Chatroom() {

    const { auth, chatroom } = useSelector(state => state)
    const { t, i18n } = useTranslation()
    const dispatch = useDispatch()

    useEffect(() => {
        // window.Echo = new Echo({
        //     broadcaster: 'socket.io',
        //     host: `${process.env.REACT_APP_RESOURCE_URL}`,
        //     client: window.io,
        //     auth:
        //     {
        //         headers:
        //         {
        //             Guard: 'candidate',
        //             Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('token')),
        //             Accept: "application/json"
        //         }
        //     },
        // });
    }, [])


    useEffect(() => {
        if (auth.loggedIn && window.Echo) {
            console.log(window.Echo)
            window.Echo.private(`notify-candidate-${auth.id}`)
                .listen('.NotifyPushed', (event) => {
                    console.log('NotifyPushed', event)
                });

            window.Echo.private(`notify-candidate-${auth.id}`)
                .listen('.NotifyChatMessage', (event) => {
                    console.log('Candidate NotifyChatMessage', event)
                });
        }

    }, [auth.loggedIn])

    return (
        <>
            {
                chatroom.open &&
                <div className={cx('container')}>
                    <div className={cx('header')}>
                        <span>{t('chatroom.title')}</span>
                        <img className={cx('list-exit')} src={`${process.env.REACT_APP_RESOURCE_URL}/images/chat_exit.png`} onClick={() => dispatch(toggleChatroom())}></img>
                    </div>
                    <div className={cx('search-bar-container')}>
                        <form className={cx('search-form')}>
                            <img className={cx('search-icon')} src={`${process.env.REACT_APP_RESOURCE_URL}/images/chat_search.png`} />
                            <input type="text" placeholder={t('chatroom.search')} required value="" />
                            <span className={cx('search-exit')}> <FontAwesomeIcon icon={faTimes} /></span>
                        </form>
                    </div>
                    <div className={cx('body')}>
                        {
                            chatroom.chatList.length > 0 ? chatroom.chatList.map((chat) => {
                                <div> Chat </div>
                            })
                                :
                                <p className='text-center'>{t('chatroom.no_new_chat')}</p>
                        }
                    </div>
                </div>
            }
        </>
    )
}

export default Chatroom