import React, { useState, useEffect, useCallback } from 'react';
import classNames from 'classnames/bind';
import styles from './HomeEmblaCarousel.module.scss';
import useEmblaCarousel from 'embla-carousel-react';
import images from '~/assets/images';
import { DotButton, NextButton, PrevButton } from './EmblaCarouselButton';
import { useTranslation } from 'react-i18next';
import Autoplay from 'embla-carousel-autoplay';

import * as homepageService from '~/services/homepageService';
import DetectItemShow from '~/components/DetectItemShow';

const cx = classNames.bind(styles);

function HomeEmblaCarousel({ banners }) {
    const { i18n } = useTranslation();
    const [viewportRef, embla] = useEmblaCarousel({ skipSnaps: false, loop: true }, [
        Autoplay({ stopOnInteraction: false }),
    ]);
    const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
    const [nextBtnEnabled, setNextBtnEnabled] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [scrollSnaps, setScrollSnaps] = useState([]);

    const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla]);
    const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla]);
    const scrollTo = useCallback((index) => embla && embla.scrollTo(index), [embla]);

    const onSelect = useCallback(() => {
        if (!embla) return;
        setSelectedIndex(embla.selectedScrollSnap());
        setPrevBtnEnabled(embla.canScrollPrev());
        setNextBtnEnabled(embla.canScrollNext());
    }, [embla, setSelectedIndex]);

    useEffect(() => {
        if (!embla) return;
        onSelect();
        setScrollSnaps(embla.scrollSnapList());
        embla.on('select', onSelect);
    }, [embla, setScrollSnaps, onSelect]);

    // User has switched back to the tab
    const onFocus = () => {
        console.log('Tab is in focus');
        Autoplay();
    };

    // User has switched away from the tab (AKA tab is hidden)
    const onBlur = () => {
        console.log('Tab is blurred');
    };

    useEffect(() => {
        window.addEventListener('focus', onFocus);
        window.addEventListener('blur', onBlur);
        return () => {
            window.removeEventListener('focus', onFocus);
            window.removeEventListener('blur', onBlur);
        };
    }, []);

    const slides = banners?.map((banner) => {
        return {
            id: banner.id,
            source: banner.src,
            link: banner.url,
        };
    });

    const handleJobClick = (id) => () => {
        homepageService.itemClick({
            section: 'homepage',
            sub_section: 'Main Banner',
            object_type: 'banner',
            object_id: id,
        })
    }

    // const slides = [
    //     {
    //         source: images.topBannerCarousel7,
    //         link: `https://cutt.ly/KaiShingwhatsapp`,
    //     },
    //     {
    //         source: images.topBannerCarousel1,
    //         link: `https://api.whatsapp.com/send?phone=85257207854&text=Hello%20%E6%88%91%E6%83%B3%E6%9F%A5%E8%A9%A2%E6%9C%89%E9%97%9C%22%E5%85%83%E6%B0%97%E5%AF%BF%E5%8F%B8%22%E8%81%B7%E4%BD%8D%E7%A9%BA%E7%BC%BA%E3%80%82`,
    //     },
    //     {
    //         source: images.topBannerCarousel2,
    //         link: `https://www.giggik.com/recruit/${i18n.languages[0]}/search?keyword=Skinmax&searchType=all`,
    //     },
    //     {
    //         source: images.topBannerCarousel3,
    //         link: `https://cutt.ly/AromeBakerywhatsappgg`,
    //     },
    //     {
    //         source: images.topBannerCarousel4,
    //         link: `https://www.giggik.com/recruit/${i18n.languages[0]}/search?keyword=%E5%85%81%E8%A8%98&searchType=all`,
    //     },
    //     {
    //         source: images.topBannerCarousel5,
    //         link: `https://www.giggik.com/recruit/${i18n.languages[0]}/search?keyword=starbucks&searchType=all`,
    //     },
    //     {
    //         source: images.topBannerCarousel6,
    //         link: `https://www.giggik.com/recruit/${i18n.languages[0]}/search?keyword=cathay%20pacific%20&searchType=all`,
    //     },
    // ];

    return (
        <div className={cx('embla__outer-container')}>
            <div className={cx('embla')}>
                {slides.length > 0 && (
                    <div className={cx('embla__viewport')} ref={viewportRef}>
                        <div className={cx('embla__container')}>
                            {slides.map((each, index) => (
                                <div className={cx('embla__slide')} key={index}>
                                    <DetectItemShow data={{
                                        section: 'homepage',
                                        sub_section: 'Main Banner',
                                        object_type: 'banner',
                                        object_id: each?.id,
                                    }} />
                                    <a href={each.link} onClick={handleJobClick(each?.id)} target="_blank">
                                        <img className={cx('embla__slide__img')} src={each.source} />
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </div>
            <div className={cx('embla__dots')}>
                {scrollSnaps.map((each, index) => (
                    <DotButton key={index} selected={index === selectedIndex} onClick={() => scrollTo(index)} />
                ))}
            </div>
        </div>
    );
}

export default HomeEmblaCarousel;
