import classNames from 'classnames/bind';
import { useEffect, useState } from 'react';
import styles from './_SignUp.module.scss';

import Loading from '../Loading';
import PhoneNumber from '../PhoneNumber';
import _Password from '../_Password/_Password';
import { useTranslation, Trans } from 'react-i18next';

import { requestVerificationCode, requestRegister, requestSetupName } from '~/services/authService';
import { login } from '~/reducers/authReducer';
import { close } from '~/reducers/popupReducer';
import { useDispatch, useSelector } from 'react-redux';
import SocialMedia from '../_SocialMedia';

const defaultCredential = {
    email: '',
    phone: '',
    areaCode: 100,
}

const cx = classNames.bind(styles);

function _SignUp() {

    const [loading, setLoading] = useState(false)
    const [setupName, setSetupName] = useState(false)
    const [getVerifyCode, setGetVerifyCode] = useState(false)
    const [tab, setTab] = useState('email')
    const [error, setError] = useState({})
    const dispatch = useDispatch()
    const { auth, popup, dropdown } = useSelector(state => state)

    const [loginCredential, setLoginCredential] = useState(defaultCredential)
    const [registerForm, setRegisterForm] = useState({
        password: '',
        password_confirmation: '',
        referral_code: '',
        code: '',
        subscription: true,
        agreement: true,
    })
    const [userInfo, setUserInfo] = useState({
        first_name: '',
        last_name: ''
    })

    const { t, i18n } = useTranslation()

    useEffect(() => {
        if (!popup.show) {
            setError({})
            setLoginCredential(defaultCredential)
        }
    }, [popup.show])

    useEffect(() => {
        setError({})
        setLoginCredential({
            ...loginCredential,
            email: '',
            phone: ''
        })
    }, [tab])

    const onChangeEmail = (e) => {
        setLoginCredential({
            ...loginCredential,
            email: e.target.value
        })
    }

    const onPhoneChange = (value) => {
        setLoginCredential({
            ...loginCredential,
            ...value
        })
    }

    const onChangeVerifyCode = (e) => {
        setRegisterForm({
            ...registerForm,
            code: e.target.value
        })
    }

    const onChangePassword = (e) => {
        setRegisterForm({
            ...registerForm,
            password: e.target.value
        })
    }

    const onChangeConfirmPassword = (e) => {
        setRegisterForm({
            ...registerForm,
            password_confirmation: e.target.value
        })
    }

    const onChangeReferralCode = (e) => {
        setRegisterForm({
            ...registerForm,
            referral_code: e.target.value
        })
    }

    const onChangeSubscription = (e) => {
        setRegisterForm({
            ...registerForm,
            subscription: !registerForm.subscription
        })
    }

    const onChangeAgreement = (e) => {
        setRegisterForm({
            ...registerForm,
            agreement: !registerForm.agreement
        })
    }

    const onChangeName = (e) => {
        setUserInfo({
            ...userInfo,
            [e.target.name]: e.target.value
        })
    }

    const handleGetVerifyCode = async (e) => {
        e.preventDefault()
        setError({})
        let isValid = true
        let data = {}
        let error = {}
        if (tab == 'phone') {
            data = {
                type: tab,
                area_code_id: loginCredential.areaCode,
                phone: loginCredential.phone
            }
        } else {
            data = {
                type: tab,
                email: loginCredential.email
            }
        }
        if (data.phone && data.area_code_id) {
            let areaCode = dropdown ? dropdown.areacodes.find(areaCode => areaCode.id == data.area_code_id) : undefined
            if (areaCode) {
                if (areaCode.area_code.includes('852')) {
                    var phone_format = new RegExp("^[0-9]{8}$")
                    if (!data.phone.match(phone_format)) {
                        isValid = false
                        error = {
                            ...error,
                            phone: [t('error.phone_must_be_eight')]
                        }
                    }
                }
            }
        }

        if ((data.phone && data.area_code_id) || data.email) {

        } else {
            isValid = false
        }


        if (isValid) {
            setLoading(true)
            const response = await requestVerificationCode(data)
            setLoading(false)
            if (response.message) {
                setError(response.message)
            } else {
                setError({})
                setGetVerifyCode(true)
            }
        } else {
            setError(error)
        }
    }

    const handleRegister = async (e) => {
        let data = {}
        if (tab == 'phone') {
            data = {
                type: tab,
                area_code_id: loginCredential.areaCode,
                phone: loginCredential.phone,
                password: registerForm.password,
                password_confirmation: registerForm.password_confirmation,
                code: registerForm.code,
                referral_code: registerForm.referral_code,
                subscription: registerForm.subscription,
            }
        } else {
            data = {
                type: tab,
                email: loginCredential.email,
                password: registerForm.password,
                password_confirmation: registerForm.password_confirmation,
                code: registerForm.code,
                referral_code: registerForm.referral_code,
                subscription: registerForm.subscription,
            }
        }

        setLoading(true)
        const response = await requestRegister(data);
        setLoading(false)
        console.log({ response })
        if (response.message) {
            setError({
                ...response.message
            })
        } else {
            setSetupName(true)
        }

    }

    const handleSetupName = async (e) => {

        const response = await requestSetupName(userInfo);
        console.log({ response })
        if (response.message) {
            setError({
                ...response.message
            })
        } else if (response.user) {
            dispatch(login(response.user))
            dispatch(close())
        }
    }

    return (
        <div className={cx('modal-body', 'modal-signup')}>
            <div className={cx('title-wrapper')}>
                <h2>{t('signup.signup_title')}</h2>
            </div>
            <div className={cx('social-wrapper')}>
                <SocialMedia isLogin={false} />
            </div>
            <div className={cx('fields')}>
                {
                    !setupName &&
                    <form className='__w100' onSubmit={handleGetVerifyCode}>
                        <div className={cx('tabs')}>
                            <a className={`${tab == 'email' ? cx('active') : ''}`} onClick={() => setTab('email')}>{t('login.email')}</a>
                            <a className={`${tab == 'phone' ? cx('active') : ''}`} onClick={() => setTab('phone')}>{t('login.phone')}</a>
                        </div>
                        <div className={`${tab == 'email' ? 'field-wrapper __flex __flex-d-column __w100' : '__d-none'}`}>
                            <div className={cx('input')}>
                                <input type='text' name='email' placeholder={t('placeholder.email')} value={loginCredential.email} onChange={onChangeEmail} disabled={getVerifyCode} />
                                {
                                    error.email &&
                                    <div className="help-block">{error.email.map(error => error)}</div>
                                }
                            </div>
                        </div>
                        <div className={`${tab == 'phone' ? 'field-wrapper __flex __flex-d-column __w100' : '__d-none'}`}>
                            <PhoneNumber codes={dropdown?.areacodes} value={loginCredential} onChange={onPhoneChange} disabled={getVerifyCode} />
                            {
                                error.phone &&
                                <div className="help-block">{error.phone.map(error => error)}</div>
                            }
                        </div>
                        <button className={cx('button-submit', 'field-wrapper')} type="submit" onClick={handleGetVerifyCode} disabled={(loginCredential.email == '' && loginCredential.phone == '')}>{t('signup.get_verification_code')}</button>
                    </form>
                }
                {
                    getVerifyCode == true && !setupName &&
                    <div className={cx('password-form')}>
                        <div className={cx('field-wrapper')}>
                            <input className={cx('input')} name="code" value={registerForm.code} onChange={onChangeVerifyCode} placeholder={t('placeholder.verify_code')} />
                            {
                                error.code &&
                                <div className="help-block">{error.code.map(error => error)}</div>
                            }
                        </div>
                        <div className={cx('field-wrapper')}>
                            <_Password value={registerForm.password} onChange={onChangePassword} />
                        </div>
                        <div className={cx('field-wrapper')}>
                            <_Password value={registerForm.password_confirmation} onChange={onChangeConfirmPassword} />
                            {
                                error.password &&
                                <div className="help-block">{error.password.map(error => error)}</div>
                            }
                        </div>
                        <div className={cx('border-blue-remind-wrap', 'field-wrapper')}>
                            {t('placeholder.password_requirement')}
                        </div>
                        <div className={cx('field-wrapper')}>
                            <input className={cx('input')} name="referral_code" value={registerForm.referral_code} onChange={onChangeReferralCode} placeholder={t('placeholder.referral_code')} />
                        </div>
                        <div>
                            <div className="__flex">
                                <label className={cx('checkbox-label')} style={{ margin: '0 5px 0 0' }}>
                                    <input id="subscription" type="checkbox" name="subscription" className="mr-1" checked={registerForm.subscription} onChange={onChangeSubscription} />
                                    <span className={cx('checkbox-custom', 'rectangular')}></span>
                                </label>
                                <label htmlFor="subscription"> {t('signup.agree_email')}</label>
                            </div>
                            <div className="__flex">
                                <label className={cx('checkbox-label')} style={{ margin: '0 5px 0 0' }}>
                                    <input id="agreement" type="checkbox" name="agreement" className="mr-1" checked={registerForm.agreement} onChange={onChangeAgreement} />
                                    <span className={cx('checkbox-custom', 'rectangular')}></span>
                                </label>
                                <label htmlFor="agreement">
                                    <Trans i18nKey="signup.signup_terms">
                                        我同意職極的
                                        <a className="text-dark-blue" target="_blank" href={`${process.env.REACT_APP_BASE_URL}/${i18n.languages[0]}/privacy-policy`}>私隱政策聲明</a>
                                        和
                                        <a className="text-dark-blue" target="_blank" href={`${process.env.REACT_APP_BASE_URL}/${i18n.languages[0]}/terms-of-use`}>條款及細則</a>
                                        。
                                    </Trans>
                                </label>
                            </div>
                        </div>
                       
                        <button className={cx('button-submit', 'field-wrapper')} type="submit" onClick={handleRegister} disabled={((loginCredential.email == '' && loginCredential.phone == '') || !registerForm.agreement)}>{t('signup.register')}</button>
                    </div>
                }
                {
                    loading &&
                    <div className="center-center mb-2">
                        <Loading />
                    </div>
                }
                {
                    setupName &&
                    <div className={cx('name-form')}>
                        <img src={`${process.env.REACT_APP_RESOURCE_URL}/images/candidate/popup/orange-box-mascot.png`} className="test__mascot mt-2 mr-2" />
                        <p className="mb-0">{'Welcome aboard!'}</p>
                        <p className="mb-3">{'I am Gig Gig, What is your name?'}</p>
                        <div className={cx('setup-name-form')}>
                            <div className={cx('field-wrapper')}>
                                <input type="text" className={cx('input')} name="first_name" placeholder={t('placeholder.first_name')} value={userInfo.first_name} onChange={onChangeName} />
                                {
                                    error.first_name &&
                                    <div className="help-block">{error.first_name}</div>
                                }
                            </div>
                            <div className={cx('field-wrapper')}>
                                <input type="text" className={cx('input')} name="last_name" placeholder={t('placeholder.last_name')} value={userInfo.last_name} onChange={onChangeName} />
                                {
                                    error.last_name &&
                                    <div className="help-block">{error.last_name}</div>
                                }
                            </div>
                        </div>
                        <button className={cx('button-submit', 'field-wrapper')} type="submit" onClick={handleSetupName} disabled={(loginCredential.email == '' && loginCredential.phone == '')}>{t('signup.submit')}</button>
                    </div>
                }
            </div>
        </div>
    )
}

export default _SignUp