import { createSlice } from '@reduxjs/toolkit'

export const searchSlice = createSlice({
    name: 'search',
    initialState: {
    },
    reducers: {
        set: (state, action) => {
            return {
                ...action.payload
            }
        },
        clear: (state, action) => {
            return {}
        }
    },
})

// Action creators are generated for each case reducer function
export const { set, clear } = searchSlice.actions

export default searchSlice.reducer