import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames/bind';
import { useLocation } from 'react-router-dom';
import styles from './Recommend.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import images from '~/assets/images';
import Button from '../Button';
import { useTranslation } from 'react-i18next';

import * as homepageService from '~/services/homepageService';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';

import 'swiper/css';
import 'swiper/css/navigation';

import DetectItemShow from '~/components/DetectItemShow';

const cx = classNames.bind(styles);

function Recommend() {
    const prevRef = useRef();
    const nextRef = useRef();

    const { pathname } = useLocation();
    const [gikpediaData, setGikpediaData] = useState([]);
    const { t, i18n } = useTranslation();

    useEffect(() => {
        const fetchApi = async () => {
            const result = await homepageService.loadGikpedia();
            if (result?.result) {
                setGikpediaData(result?.result);
            }
        };

        fetchApi();
    }, [i18n.language]);

    const recommendResponseArray = {
        articleNewsTips: {
            header: {
                link: '#',
                icon: images.homepage.recommendArticle1Icon,
                title: t('recommendToYou.vocationalSkillsGuide.gikpedia'),
                subTitle: t('recommendToYou.vocationalSkillsGuide.aboutYourJob'),
                width: '32px',
                height: '36px',
            },
            body: [
                {
                    link: '#',
                    buttonText: t('homepage.recommend.view'),
                    thumbnail: images.homepage.recommendArticle1Thumb1,
                    title: '[職場求生術|腦細 陷阱問題你要識答]',
                    date: '19-05-2022',
                },
                {
                    link: '#',
                    buttonText: t('homepage.recommend.view'),
                    thumbnail: images.homepage.recommendArticle1Thumb2,
                    title: '[第二屆「職」幫你搵Intern-2022',
                    date: '16-05-2022',
                },
            ],
        },
        articleHelpGroup: {
            header: {
                link: 'https://chat.whatsapp.com/CCmrody65uN59yip5gFZys',
                icon: images.homepage.recommendArticle2Icon,
                title: t('recommendToYou.helperGroup.jobHunt'),
                subTitle: t('recommendToYou.helperGroup.whatApp'),
                width: '35px',
                height: '35px',
            },
            body: [
                {
                    link: 'https://bit.ly/GigGik腦力事',
                    buttonText: '加入',
                    thumbnail: images.homepage.recommendArticle2Thumb1,
                    title: t('banner.officeWork'),
                    subTitle: t('banner.recruitmentGroup'),
                },
                {
                    link: 'https://bit.ly/GigGik勞力事',
                    buttonText: '加入',
                    thumbnail: images.homepage.recommendArticle2Thumb2,
                    title: t('banner.service'),
                    subTitle: t('banner.recruitmentGroup'),
                },
            ],
        },
        articleApply: {
            header: {
                link: 'https://docs.google.com/forms/d/1rXDO4sT0REXCaK44765iCY88ip1ziG-YFFvAhgo5cTA/viewform?edit_requested=true',
                icon: images.homepage.recommendArticle3Icon,
                title: t('recommendToYou.workerTntroduction.findPlace'),
                subTitle: t('recommendToYou.workerTntroduction.handCV'),
                tag: {
                    text: 'beta',
                },
                width: '34px',
                height: '37px',
            },
            body: {
                link: 'https://docs.google.com/forms/d/1rXDO4sT0REXCaK44765iCY88ip1ziG-YFFvAhgo5cTA/viewform?edit_requested=true',
                buttonText: '申請',
                thumbnail: images.homepage.recommendArticle3Thumb,
                title: t('recommendToYou.workerTntroduction.cv'),
                subTitle: t('recommendToYou.workerTntroduction.applyForm'),
            },
        },
        articleSlash: {
            header: {
                link: `${process.env.REACT_APP_NEWGIG_URL}/${i18n.languages[0]}/find-gigs`,
                icon: images.homepage.recommendArticle4Icon,
                title: t('recommendToYou.slash.findSlash'),
                subTitle: t('recommendToYou.slash.findNow'),
                tag: {
                    text: 'beta',
                },
                width: '35px',
                height: '37px',
            },
            body: {
                link: `${process.env.REACT_APP_NEWGIG_URL}/${i18n.languages[0]}/find-gigs`,
                buttonText: '尋找',
                thumbnail: {
                    en: images.homepage.recommendArticle4ThumbEn,
                    tc: images.homepage.recommendArticle4ThumbTc,
                    sc: images.homepage.recommendArticle4ThumbSc,
                },
                title: 'New Gig',
                subTitle: t('recommendToYou.slash.interactive'),
            },
        },
    };

    const handleJobClick = (id) => () => {
        homepageService.itemClick({
            section: 'homepage',
            sub_section: 'Recommend',
            object_type: 'gikpedia',
            object_id: id,
        })
    }

    return (
        <div className={cx('wrapper')}>
            <Swiper
                onInit={(swiper) => {
                    swiper.params.navigation.prevEl = prevRef.current;
                    swiper.params.navigation.nextEl = nextRef.current;
                    swiper.navigation.init();
                    swiper.navigation.update();
                }}
                // spaceBetween={25}
                breakpoints={{
                    340: {
                        slidesPerView: 1,
                        // spaceBetween: 25,
                    },
                    640: {
                        slidesPerView: 2,
                        // spaceBetween: 50,
                    },
                    930: {
                        slidesPerView: 3,
                        slidesPerGroup: 3,
                        spaceBetween: 20,
                    },
                    1024: {
                        slidesPerView: 3,
                        slidesPerGroup: 3,
                        spaceBetween: 10,
                    },
                    1440: {
                        slidesPerView: 4,
                        slidesPerGroup: 4,
                        spaceBetween: 10,
                    },
                }}
                onReachBeginning={(swiper) => {
                    swiper.params.navigation.disabledClass = 'swiper-button-disabled';
                    swiper.navigation.update();
                }}
                onReachEnd={(swiper) => {
                    swiper.params.navigation.disabledClass = 'swiper-button-disabled';
                    swiper.navigation.update();
                }}
                scrollbar={{ draggable: true }}
                modules={[Navigation]}
                grabCursor={true}
                className="recommend-articles-carousel"
            >
                <SwiperSlide>
                    <div className={pathname === '/en' ? cx('article', 'tips', 'en') : cx('article', 'tips')}>
                        <div className={cx('header')}>
                            <span className={cx('icon')}>
                                <img
                                    width={recommendResponseArray?.articleNewsTips?.header.width}
                                    height={recommendResponseArray?.articleNewsTips?.header.height}
                                    src={recommendResponseArray?.articleNewsTips?.header?.icon}
                                    alt="article-icon"
                                />
                            </span>
                            <span className={cx('title')}>
                                <a href={`${process.env.REACT_APP_BASE_URL}/${i18n.languages[0]}/gikpedia`}>
                                    <h2>{recommendResponseArray?.articleNewsTips?.header?.title}</h2>
                                </a>
                                <h4>{recommendResponseArray?.articleNewsTips?.header?.subTitle}</h4>
                            </span>
                            <span className={cx('read-more')}>
                                <a href={`${process.env.REACT_APP_BASE_URL}/${i18n.languages[0]}/gikpedia`}>
                                    {t('homepage.recommend.view_more')}
                                    <FontAwesomeIcon icon={faChevronRight} />
                                </a>
                            </span>
                        </div>
                        <div className={cx('body')}>
                            {gikpediaData.map((item, index) => {
                                return (
                                    <div className={cx('item')} key={index}>
                                        <DetectItemShow data={{
                                            section: 'homepage',
                                            sub_section: 'Recommend',
                                            object_type: 'gikpedia',
                                            object_id: item?.id,
                                        }} />
                                        <span className={cx('thumbnail')}>
                                            <a href={item.link} onClick={handleJobClick(item?.id)}>
                                                <img src={item?.thumbnail} alt="item-thumbnail" />
                                            </a>
                                        </span>
                                        <span className={cx('item-title')}>
                                            <a href={item.link} className={cx('item-title-button')} onClick={handleJobClick(item?.id)}>
                                                <h3>{item?.title}</h3>
                                            </a>
                                            <p className={cx('title-date')}>{item?.date}</p>
                                        </span>
                                        <div className={cx('button')}>
                                            <div
                                                className={
                                                    pathname === '/en' ? cx('buttonItem', 'en') : cx('buttonItem')
                                                }
                                            >
                                                <Button small rounded primary href={item.link} onClick={handleJobClick(item?.id)}>
                                                    {t('homepage.recommend.view')}
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </SwiperSlide>

                <SwiperSlide>
                    <div className={pathname === '/en' ? cx('article', 'helps', 'en') : cx('article', 'helps')}>
                        <div className={cx('header')}>
                            <span className={cx('icon')}>
                                <img
                                    width={recommendResponseArray?.articleHelpGroup?.header.width}
                                    height={recommendResponseArray?.articleHelpGroup?.header.height}
                                    src={recommendResponseArray?.articleHelpGroup?.header?.icon}
                                    alt="article-icon"
                                />
                            </span>
                            <span className={cx('title')}>
                                <a href={recommendResponseArray?.articleHelpGroup?.header?.link}>
                                    <h2>{recommendResponseArray?.articleHelpGroup?.header?.title}</h2>
                                </a>
                                <h4>{recommendResponseArray?.articleHelpGroup?.header?.subTitle}</h4>
                            </span>
                            <span className={cx('read-more')}>
                                <a href={recommendResponseArray?.articleHelpGroup?.header?.link}>
                                    {t('homepage.recommend.view_more')}
                                    <FontAwesomeIcon icon={faChevronRight} />
                                </a>
                            </span>
                        </div>
                        <div className={cx('body')}>
                            {recommendResponseArray?.articleHelpGroup?.body.map((item, index) => {
                                return (
                                    <div className={cx('item')} key={index}>
                                        <span className={cx('thumbnail')}>
                                            <a href={item.link}>
                                                <img src={item?.thumbnail} alt="item-thumbnail" />
                                            </a>
                                        </span>
                                        <span className={cx('item-title')}>
                                            <a href={item.link} className={cx('item-title-button')}>
                                                <h3>{item?.title}</h3>
                                            </a>
                                            <p className={cx('sub-title')}>{item?.subTitle}</p>
                                        </span>
                                        <div className={cx('button')}>
                                            <div
                                                className={
                                                    pathname === '/en' ? cx('buttonItem', 'en') : cx('buttonItem')
                                                }
                                            >
                                                <Button small rounded primary href={item.link}>
                                                    {t('homepage.recommend.join')}
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </SwiperSlide>

                <SwiperSlide>
                    <div
                        className={
                            pathname === '/en'
                                ? cx('article', 'apply', 'recommend_single-item-article', 'en')
                                : cx('article', 'apply', 'recommend_single-item-article')
                        }
                    >
                        <div className={cx('header')}>
                            <span className={cx('icon')}>
                                <img
                                    width={recommendResponseArray?.articleApply?.header.width}
                                    height={recommendResponseArray?.articleApply?.header.height}
                                    src={recommendResponseArray?.articleApply?.header?.icon}
                                    alt="article-icon"
                                />
                            </span>
                            <span className={cx('title')}>
                                <a
                                    href={recommendResponseArray?.articleApply?.header?.link}
                                    className={cx('has-badge')}
                                >
                                    <h2>{recommendResponseArray?.articleApply?.header?.title}</h2>
                                    <span className={cx('badge', 'yellow')}>Beta</span>
                                </a>
                                <h4>{recommendResponseArray?.articleApply?.header?.subTitle}</h4>
                            </span>
                            <span className={cx('read-more')}>
                                <a href={recommendResponseArray?.articleApply?.header?.link}>
                                    {t('homepage.recommend.view_more')}
                                    <FontAwesomeIcon icon={faChevronRight} />
                                </a>
                            </span>
                        </div>
                        <div className={cx('body')}>
                            <div className={cx('item-single')}>
                                <div className={cx('thumbnail-full-width')}>
                                    <a href={recommendResponseArray?.articleApply?.body?.link}>
                                        <img
                                            src={recommendResponseArray?.articleApply?.body?.thumbnail}
                                            alt="item-thumbnail"
                                        />
                                    </a>
                                </div>
                                <div className={cx('bottom')}>
                                    <span className={cx('item-title')}>
                                        <a href={recommendResponseArray?.articleApply?.body?.link}>
                                            <h3>{recommendResponseArray?.articleApply?.body?.title}</h3>
                                        </a>
                                        <p>{recommendResponseArray?.articleApply?.body?.subTitle}</p>
                                    </span>
                                    <div className={cx('button')}>
                                        <div className={pathname === '/en' ? cx('buttonItem', 'en') : cx('buttonItem')}>
                                            <Button
                                                small
                                                rounded
                                                primary
                                                href={recommendResponseArray?.articleApply?.body?.link}
                                            >
                                                {t('homepage.recommend.apply')}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>

                <SwiperSlide>
                    <div
                        className={
                            pathname === '/en'
                                ? cx('article', 'slash', 'recommend_single-item-article', 'en')
                                : cx('article', 'slash', 'recommend_single-item-article')
                        }
                    >
                        <div className={cx('header')}>
                            <span className={cx('icon')}>
                                <img
                                    width={recommendResponseArray?.articleSlash?.header.width}
                                    height={recommendResponseArray?.articleSlash?.header.height}
                                    src={recommendResponseArray?.articleSlash?.header?.icon}
                                    alt="article-icon"
                                />
                            </span>
                            <span className={cx('title')}>
                                <a href={recommendResponseArray?.articleSlash?.header?.link}>
                                    <h2>{recommendResponseArray?.articleSlash?.header?.title}</h2>
                                </a>
                                <h4>{recommendResponseArray?.articleSlash?.header?.subTitle}</h4>
                            </span>
                            <span className={cx('read-more')}>
                                <a href={recommendResponseArray?.articleSlash?.header?.link}>
                                    {t('homepage.recommend.view_more')}
                                    <FontAwesomeIcon icon={faChevronRight} />
                                </a>
                            </span>
                        </div>
                        <div className={cx('body')}>
                            <div className={cx('item-single')}>
                                <div className={cx('thumbnail-full-width')}>
                                    <a href={recommendResponseArray?.articleSlash?.body?.link}>
                                        <img
                                            src={recommendResponseArray?.articleSlash?.body?.thumbnail[i18n.language]}
                                            alt="item-thumbnail"
                                        />
                                    </a>
                                </div>
                                <div className={cx('bottom')}>
                                    <span className={cx('item-title')}>
                                        <a href={recommendResponseArray?.articleSlash?.body?.link}>
                                            <h3>{recommendResponseArray?.articleSlash?.body?.title}</h3>
                                        </a>
                                        <p>{recommendResponseArray?.articleSlash?.body?.subTitle}</p>
                                    </span>
                                    <div className={cx('button')}>
                                        <div className={pathname === '/en' ? cx('buttonItem', 'en') : cx('buttonItem')}>
                                            <Button
                                                small
                                                rounded
                                                primary
                                                href={recommendResponseArray?.articleSlash?.body?.link}
                                                className="article-click-btn"
                                            >
                                                {t('homepage.recommend.find')}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
            </Swiper>
        </div>
    );
}

export default Recommend;
