import { useEffect, useState, useRef, memo } from 'react';
import { faCircleXmark, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HeadlessTippy from '@tippyjs/react/headless';
import classNames from 'classnames/bind';

import { useDebounce } from '~/hooks';
import styles from './Filter.module.scss';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Select, { components } from 'react-select';
// import makeAnimated from 'react-select/animated';
// import { colourOptions } from '../data';

// react hook form
import { useForm, Controller } from 'react-hook-form';
import NestCheckBox from './Custom/NestCheckbox';
import SingleCheckbox from './Custom/SingleCheckbox';
import SelectCheckbox from './Custom/SelectCheckbox';
import SelectChildCheckbox from './Custom/SelectChildCheckbox';
import SalaryFilter from './Custom/SalaryFilter';
import { ReactComponent as IconFilterBrief } from './../../../assets/images/svgIcons/filter/filter-brief.svg';
import { ReactComponent as IconFilterIndustry } from './../../../assets/images/svgIcons/filter/filter-industry.svg';
import { ReactComponent as IconFilterLevel } from './../../../assets/images/svgIcons/filter/filter-levels.svg';
import { ReactComponent as IconFilterLocation } from './../../../assets/images/svgIcons/filter/filter-location-v3.svg';
import { ReactComponent as IconFilterEducation } from './../../../assets/images/svgIcons/filter/filter-education.svg';
import { ReactComponent as IconFilterGlobal } from './../../../assets/images/svgIcons/filter/filter-global.svg';
import { ReactComponent as IconFilterTag } from './../../../assets/images/svgIcons/filter/filter-tag.svg';
import { ReactComponent as IconFilterLikeHand } from './../../../assets/images/svgIcons/filter/filter-likehand.svg';
import { useDispatch, useSelector } from 'react-redux';
import { set } from '~/reducers/searchReducer';
import useOnClickOutside from '~/hooks/useClickOutside';

// import { zodResolver } from '@hookform/resolvers/zod';
// import * as z from 'zod';

// validate
// const schema = z.object({
//     email: z.string().email().min(2),
//     password: z.string().min(6),
// });

const cx = classNames.bind(styles);

function Filter({ onFilter }) {
    const { t, i18n } = useTranslation();
    let navigate = useNavigate();
    const dispatch = useDispatch();

    const [dropDownData, setDropDownData] = useState({});
    const [regions, setRegions] = useState([]);
    const [skills, setSkills] = useState([]);
    const [careerlevels, setCareerlevels] = useState([]);

    const dropdown = useSelector((state) => state.dropdown);

    const [salaryDropdownData, setSalaryDropdownData] = useState(0);
    const inputRef1 = useRef();
    const inputRef2 = useRef();
    const inputRef3 = useRef();
    const inputRef4 = useRef();
    const inputRef5 = useRef();
    const inputRef6 = useRef();
    const inputRef7 = useRef();
    const inputRef9 = useRef();

    const conatainerRef1 = useRef();
    const conatainerRef2 = useRef();
    const conatainerRef3 = useRef();
    const conatainerRef4 = useRef();
    const conatainerRef5 = useRef();
    const conatainerRef6 = useRef();
    const conatainerRef7 = useRef();
    const conatainerRef9 = useRef();

    const { handleSubmit, control, watch } = useForm();

    useEffect(() => {
        if (Object.keys(dropdown).length > 0) {
            let dropdownResult = {
                ...dropdown,
            };
            setDropDownData(dropdownResult);
            const dataRegions = dropdownResult?.regions.map((region) => {
                return {
                    ...region,
                    get_child: region.districts.map((item) => {
                        return {
                            ...item,
                            id: `${item.region_id}-${item.id}`,
                        };
                    }),
                };
            });
            setRegions(dataRegions);
            const skillData = [];
            dropdownResult?.skills?.forEach((skill) => {
                const existIndex = skillData.findIndex((item) => item.id === skill.type);
                if (existIndex > -1) {
                    skillData[existIndex].get_child.push({
                        ...skill,
                        id: `${skill.type}-${skill.id}`,
                    });
                } else {
                    const valueTranslate =
                        skill.type === 'languages' ? t('filter.skills.languages') : t('filter.skills.others');
                    skillData.push({
                        id: skill.type,
                        value: valueTranslate,
                        get_child: [
                            {
                                ...skill,
                                id: `${skill.type}-${skill.id}`,
                            },
                        ],
                    });
                }
            });
            setSkills(skillData);

            let careerlevelData = dropdownResult?.careerlevels.map((careerlevel) => {
                return {
                    ...careerlevel,
                    id: careerlevel.code,
                };
            });
            setCareerlevels(careerlevelData);
        }
    }, [dropdown]);

    const onSubmit = (values) => {
        console.log('values', values);
    };

    const customStyles = {
        control: (base, state) => ({
            ...base,
            border: '1.5px solid rgba(196, 196, 196, 0.9)',
            borderRadius: '20px',
            paddingLeft: 13,
            minHeight: 'unset',
        }),
        valueContainer: (base, state) => ({
            ...base,
            svg: {
                marginRight: 8,
            },
        }),
        placeholder: (base, state) => ({
            ...base,
            svg: {
                marginRight: 8,
            },
        }),
        indicatorSeparator: (base, state) => ({
            ...base,
            display: 'none',
        }),
        indicatorsContainer: (base, state) => ({
            ...base,
            paddingRight: 18,
            svg: {
                width: 16,
                height: 16,
            },
        }),
        input: (base, state) => ({
            ...base,
            padding: 0,
            margin: 0,
        }),
    };

    const ContactValueContainer = ({ children, ...props }) => {
        const values = props.getValue();
        if (!values.length) {
            return <components.ValueContainer {...props}>{children}</components.ValueContainer>;
        }
        const listItems = [];
        dropDownData?.jobfunctions.forEach((item) => {
            listItems.push(item);
            item.get_child.forEach((subItem) => {
                listItems.push(subItem);
            });
        });
        const firstItem = listItems.find((item) => values.includes(item.id));
        return (
            <components.ValueContainer {...props}>
                <div style={{ color: '#828282' }} className={cx('valueContainerCustom')}>
                    <IconFilterBrief /> {firstItem?.translation?.value ? firstItem.translation.value : firstItem.value}
                    {values.length > 1 ? ` + ${values.length - 1}${t('header.filter.item')}` : ''}
                </div>
            </components.ValueContainer>
        );
    };

    const IndustryValueContainer = ({ ...props }) => {
        const values = props.getValue();
        if (!values.length) {
            return <components.ValueContainer {...props}>{props.children}</components.ValueContainer>;
        }
        const firstItem = dropDownData?.industries.find((item) => values.includes(item.id));
        return (
            <components.ValueContainer {...props}>
                <div style={{ color: '#828282' }} className={cx('valueContainerCustom')}>
                    <IconFilterIndustry /> {firstItem.value}
                    {values.length > 1 ? ` + ${values.length - 1}${t('header.filter.item')}` : ''}
                </div>
            </components.ValueContainer>
        );
    };

    const JoblevelValueContainer = ({ ...props }) => {
        const values = props.getValue();
        if (!values.length) {
            return <components.ValueContainer {...props}>{props.children}</components.ValueContainer>;
        }
        const firstItem = careerlevels.find((item) => values.includes(item.id));
        return (
            <components.ValueContainer {...props}>
                <div style={{ color: '#828282' }} className={cx('valueContainerCustom')}>
                    <IconFilterLevel /> {firstItem.value}
                    {values.length > 1 ? ` + ${values.length - 1}${t('header.filter.item')}` : ''}
                </div>
            </components.ValueContainer>
        );
    };

    const RegionValueContainer = ({ ...props }) => {
        const values = props.getValue();
        if (!props.getValue().length) {
            return <components.ValueContainer {...props}>{props.children}</components.ValueContainer>;
        }
        const listItems = [];
        regions.forEach((item) => {
            listItems.push(item);
            item.get_child.forEach((subItem) => {
                listItems.push(subItem);
            });
        });
        const firstItem = listItems.find((item) => values.includes(item.id));
        return (
            <components.ValueContainer {...props}>
                <div style={{ color: '#828282' }} className={cx('valueContainerCustom')}>
                    <IconFilterLocation />{' '}
                    {firstItem?.translation?.value ? firstItem.translation.value : firstItem.value}
                    {values.length > 1 ? ` + ${values.length - 1}${t('header.filter.item')}` : ''}
                </div>
            </components.ValueContainer>
        );
    };

    const EducationlevelValueContainer = ({ ...props }) => {
        const values = props.getValue();
        if (!values.length) {
            return <components.ValueContainer {...props}>{props.children}</components.ValueContainer>;
        }
        const firstItem = dropDownData?.educationlevels.find((item) => values.includes(item.id));
        return (
            <components.ValueContainer {...props}>
                <div style={{ color: '#828282' }} className={cx('valueContainerCustom')}>
                    <IconFilterEducation /> {firstItem.value}
                    {values.length > 1 ? ` + ${values.length - 1}${t('header.filter.item')}` : ''}
                </div>
            </components.ValueContainer>
        );
    };

    const SkillValueContainer = ({ ...props }) => {
        const values = props.getValue();
        if (!props.getValue().length) {
            return <components.ValueContainer {...props}>{props.children}</components.ValueContainer>;
        }
        const listItems = [];
        skills.forEach((item) => {
            item.get_child.forEach((subItem) => {
                listItems.push(subItem);
            });
        });
        const firstItem = listItems.find((item) => values.includes(item.id));
        return (
            <components.ValueContainer {...props}>
                <div style={{ color: '#828282' }} className={cx('valueContainerCustom')}>
                    <IconFilterGlobal /> {firstItem.name}
                    {values.length > 1 ? ` + ${values.length - 1}${t('header.filter.item')}` : ''}
                </div>
            </components.ValueContainer>
        );
    };

    const ContractTypeValueContainer = ({ ...props }) => {
        const values = props.getValue();
        if (!values.length) {
            return <components.ValueContainer {...props}>{props.children}</components.ValueContainer>;
        }
        const firstItem = dropDownData?.contracttypes.find((item) => values.includes(item.id));
        return (
            <components.ValueContainer {...props}>
                <div style={{ color: '#828282' }} className={cx('valueContainerCustom')}>
                    <IconFilterTag /> {firstItem.value}
                    {values.length > 1 ? ` + ${values.length - 1}${t('header.filter.item')}` : ''}
                </div>
            </components.ValueContainer>
        );
    };

    const WelfaresValueContainer = ({ ...props }) => {
        const values = props.getValue();
        if (!props.getValue().length) {
            return <components.ValueContainer {...props}>{props.children}</components.ValueContainer>;
        }
        const firstItem = dropDownData?.welfares.find((item) => values.includes(item.id));
        return (
            <components.ValueContainer {...props}>
                <div style={{ color: '#828282' }} className={cx('valueContainerCustom')}>
                    <IconFilterLikeHand /> {firstItem.value}
                    {values.length > 1 ? ` + ${values.length - 1}${t('header.filter.item')}` : ''}
                </div>
            </components.ValueContainer>
        );
    };

    const contact = watch('contact', []);
    const industry = watch('industry', []);
    const levels = watch('levels', []);
    const selectedregions = watch('regions', []);
    const educationLevels = watch('educationLevels', []);
    const skill = watch('skill', []);
    const contracttype = watch('contracttype', []);
    const welfares = watch('welfares', []);

    useEffect(() => {
        let natures = [];
        if (contact && contact.length > 0) {
            dropDownData?.jobfunctions.forEach((jobfunction) => {
                if (contact.includes(jobfunction.id)) {
                    natures.push(jobfunction.id);
                } else {
                    jobfunction.get_child.forEach((child) => {
                        if (contact.includes(child.id)) {
                            natures.push(jobfunction.id + '-' + child.id);
                        }
                    });
                }
            });
        }
        dispatch(
            set({
                natures,
                industries: industry,
                careerlevels: levels,
                districts: selectedregions,
                educationlevels: educationLevels,
                skills: skill,
                contracts: contracttype,
                welfares: welfares,
            }),
        );
    }, [contact, industry, levels, selectedregions, educationLevels, skill, contracttype, welfares]);

    useOnClickOutside(conatainerRef1, () => {
        inputRef1.current.props.onMenuClose();
    });
    useOnClickOutside(conatainerRef2, () => {
        inputRef2.current.props.onMenuClose();
    });
    useOnClickOutside(conatainerRef3, () => {
        inputRef3.current.props.onMenuClose();
    });
    useOnClickOutside(conatainerRef4, () => {
        inputRef4.current.props.onMenuClose();
    });
    useOnClickOutside(conatainerRef5, () => {
        inputRef5.current.props.onMenuClose();
    });
    useOnClickOutside(conatainerRef6, () => {
        inputRef6.current.props.onMenuClose();
    });
    useOnClickOutside(conatainerRef7, () => {
        inputRef7.current.props.onMenuClose();
    });
    useOnClickOutside(conatainerRef9, () => {
        inputRef9.current.props.onMenuClose();
    });

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)} action="">
                <div className={cx('filter-inner')}>
                    <div className={cx('filter-module')} ref={conatainerRef1}>
                        <Controller
                            control={control}
                            name="contact"
                            defaultValue={[]}
                            rules={{
                                required: 'Please enter at least one client name group.',
                            }}
                            render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { error } }) => (
                                <Select
                                    onChange={(value) => {
                                        onChange(value);
                                    }}
                                    styles={customStyles}
                                    placeholder={
                                        <div className={cx('valueContainerCustom')}>
                                            <IconFilterBrief /> <label>{t('header.filter.contact')}</label>
                                        </div>
                                    }
                                    isClearable={false}
                                    isMulti={true}
                                    onBlur={onBlur}
                                    onFocus={() => {
                                        inputRef2.current.props.onMenuClose();
                                        inputRef3.current.props.onMenuClose();
                                        inputRef4.current.props.onMenuClose();
                                        inputRef5.current.props.onMenuClose();
                                        inputRef6.current.props.onMenuClose();
                                        inputRef7.current.props.onMenuClose();
                                        inputRef9.current.props.onMenuClose();
                                    }}
                                    value={value}
                                    name={name}
                                    ref={(ref) => (inputRef1.current = ref)}
                                    className={'parentOption'}
                                    options={dropDownData?.jobfunctions || []}
                                    components={{
                                        MenuList: NestCheckBox,
                                        ValueContainer: ContactValueContainer,
                                    }}
                                />
                            )}
                        />
                    </div>
                    <div className={cx('filter-module')} ref={conatainerRef2}>
                        <Controller
                            control={control}
                            name="industry"
                            defaultValue={[]}
                            rules={{
                                required: 'Please enter at least one client name group.',
                            }}
                            render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { error } }) => (
                                <Select
                                    onChange={(value) => {
                                        onChange(value);
                                    }}
                                    styles={customStyles}
                                    placeholder={
                                        <div className={cx('valueContainerCustom')}>
                                            <IconFilterIndustry /> {t('header.filter.industry')}
                                        </div>
                                    }
                                    onBlur={onBlur}
                                    onFocus={() => {
                                        inputRef1.current.props.onMenuClose();
                                        inputRef3.current.props.onMenuClose();
                                        inputRef4.current.props.onMenuClose();
                                        inputRef5.current.props.onMenuClose();
                                        inputRef6.current.props.onMenuClose();
                                        inputRef7.current.props.onMenuClose();
                                        inputRef9.current.props.onMenuClose();
                                    }}
                                    value={value}
                                    isClearable={false}
                                    isMulti={true}
                                    name={name}
                                    ref={(ref) => (inputRef2.current = ref)}
                                    className={'parentOption'}
                                    options={dropDownData?.industries || []}
                                    components={{
                                        MenuList: SingleCheckbox,
                                        ValueContainer: IndustryValueContainer,
                                    }}
                                />
                            )}
                        />
                    </div>
                    <div className={cx('filter-module')} ref={conatainerRef3}>
                        <Controller
                            control={control}
                            name="levels"
                            defaultValue={[]}
                            rules={{
                                required: 'Please enter at least one client name group.',
                            }}
                            render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { error } }) => (
                                <Select
                                    onChange={(value) => {
                                        onChange(value);
                                    }}
                                    placeholder={
                                        <div className={cx('valueContainerCustom')}>
                                            <IconFilterLevel /> {t('header.filter.levels')}
                                        </div>
                                    }
                                    styles={customStyles}
                                    onBlur={onBlur}
                                    onFocus={() => {
                                        inputRef2.current.props.onMenuClose();
                                        inputRef1.current.props.onMenuClose();
                                        inputRef4.current.props.onMenuClose();
                                        inputRef5.current.props.onMenuClose();
                                        inputRef6.current.props.onMenuClose();
                                        inputRef7.current.props.onMenuClose();
                                        inputRef9.current.props.onMenuClose();
                                    }}
                                    value={value}
                                    isClearable={false}
                                    isMulti={true}
                                    name={name}
                                    ref={(ref) => (inputRef3.current = ref)}
                                    className={'parentOption'}
                                    options={careerlevels || []}
                                    components={{ MenuList: SelectCheckbox, ValueContainer: JoblevelValueContainer }}
                                />
                            )}
                        />
                    </div>
                </div>
                <div className={cx('filter-inner')}>
                    <div className={cx('filter-module')} ref={conatainerRef4}>
                        <Controller
                            control={control}
                            name="regions"
                            defaultValue={[]}
                            rules={{
                                required: 'Please enter at least one client name group.',
                            }}
                            render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { error } }) => (
                                <Select
                                    onChange={(value) => {
                                        onChange(value);
                                    }}
                                    placeholder={
                                        <div className={cx('valueContainerCustom')}>
                                            <IconFilterLocation /> {t('header.filter.regions')}
                                        </div>
                                    }
                                    styles={customStyles}
                                    onBlur={onBlur}
                                    onFocus={() => {
                                        inputRef2.current.props.onMenuClose();
                                        inputRef3.current.props.onMenuClose();
                                        inputRef1.current.props.onMenuClose();
                                        inputRef5.current.props.onMenuClose();
                                        inputRef6.current.props.onMenuClose();
                                        inputRef7.current.props.onMenuClose();
                                        inputRef9.current.props.onMenuClose();
                                    }}
                                    value={value}
                                    isSearchable={true}
                                    name={name}
                                    ref={(ref) => (inputRef4.current = ref)}
                                    className={'parentOption'}
                                    options={regions || []}
                                    components={{ MenuList: NestCheckBox, ValueContainer: RegionValueContainer }}
                                />
                            )}
                        />
                    </div>
                    <div className={cx('filter-module')} ref={conatainerRef5}>
                        <Controller
                            control={control}
                            name="educationLevels"
                            defaultValue={[]}
                            rules={{
                                required: 'Please enter at least one client name group.',
                            }}
                            render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { error } }) => (
                                <Select
                                    onChange={(value) => {
                                        onChange(value);
                                    }}
                                    placeholder={
                                        <div className={cx('valueContainerCustom')}>
                                            <IconFilterEducation /> {t('header.filter.education')}
                                        </div>
                                    }
                                    styles={customStyles}
                                    onBlur={onBlur}
                                    onFocus={() => {
                                        inputRef2.current.props.onMenuClose();
                                        inputRef3.current.props.onMenuClose();
                                        inputRef4.current.props.onMenuClose();
                                        inputRef1.current.props.onMenuClose();
                                        inputRef6.current.props.onMenuClose();
                                        inputRef7.current.props.onMenuClose();
                                        inputRef9.current.props.onMenuClose();
                                    }}
                                    value={value}
                                    isSearchable={true}
                                    name={name}
                                    ref={(ref) => (inputRef5.current = ref)}
                                    className={'parentOption'}
                                    options={dropDownData?.educationlevels || []}
                                    components={{
                                        MenuList: SelectCheckbox,
                                        ValueContainer: EducationlevelValueContainer,
                                    }}
                                />
                            )}
                        />
                    </div>
                    <div className={cx('filter-module')} ref={conatainerRef6}>
                        <Controller
                            control={control}
                            name="skill"
                            defaultValue={[]}
                            rules={{
                                required: 'Please enter at least one client name group.',
                            }}
                            render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { error } }) => (
                                <Select
                                    onChange={(value) => {
                                        onChange(value);
                                    }}
                                    placeholder={
                                        <div className={cx('valueContainerCustom')}>
                                            <IconFilterGlobal /> {t('header.filter.skills')}
                                        </div>
                                    }
                                    styles={customStyles}
                                    onBlur={onBlur}
                                    onFocus={() => {
                                        inputRef2.current.props.onMenuClose();
                                        inputRef3.current.props.onMenuClose();
                                        inputRef4.current.props.onMenuClose();
                                        inputRef5.current.props.onMenuClose();
                                        inputRef1.current.props.onMenuClose();
                                        inputRef7.current.props.onMenuClose();
                                        inputRef9.current.props.onMenuClose();
                                    }}
                                    value={value}
                                    isSearchable={true}
                                    name={name}
                                    ref={(ref) => (inputRef6.current = ref)}
                                    className={'parentOption'}
                                    options={skills || []}
                                    components={{ MenuList: SelectChildCheckbox, ValueContainer: SkillValueContainer }}
                                />
                            )}
                        />
                    </div>
                </div>
                <div className={cx('filter-inner')}>
                    <div className={cx('filter-module')} ref={conatainerRef7}>
                        <Controller
                            control={control}
                            name="contracttype"
                            defaultValue={[]}
                            rules={{
                                required: 'Please enter at least one client name group.',
                            }}
                            render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { error } }) => (
                                <Select
                                    onChange={(value) => {
                                        onChange(value);
                                    }}
                                    placeholder={
                                        <div className={cx('valueContainerCustom')}>
                                            <IconFilterTag /> {t('header.filter.contract')}
                                        </div>
                                    }
                                    styles={customStyles}
                                    onBlur={onBlur}
                                    onFocus={() => {
                                        inputRef2.current.props.onMenuClose();
                                        inputRef3.current.props.onMenuClose();
                                        inputRef4.current.props.onMenuClose();
                                        inputRef5.current.props.onMenuClose();
                                        inputRef6.current.props.onMenuClose();
                                        inputRef1.current.props.onMenuClose();
                                        inputRef9.current.props.onMenuClose();
                                    }}
                                    value={value}
                                    isSearchable={true}
                                    name={name}
                                    ref={(ref) => (inputRef7.current = ref)}
                                    className={'parentOption'}
                                    options={dropDownData?.contracttypes || []}
                                    components={{
                                        MenuList: SelectCheckbox,
                                        ValueContainer: ContractTypeValueContainer,
                                    }}
                                />
                            )}
                        />
                    </div>
                    <div className={cx('filter-module')}>
                        <SalaryFilter dropdownData={setSalaryDropdownData} />
                    </div>
                    <div className={cx('filter-module')} ref={conatainerRef9}>
                        <Controller
                            control={control}
                            name="welfares"
                            defaultValue={[]}
                            rules={{
                                required: 'Please enter at least one client name group.',
                            }}
                            render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { error } }) => (
                                <Select
                                    onChange={(value) => {
                                        onChange(value);
                                    }}
                                    placeholder={
                                        <div className={cx('valueContainerCustom')}>
                                            <IconFilterLikeHand /> {t('header.filter.welfares')}
                                        </div>
                                    }
                                    styles={customStyles}
                                    onBlur={onBlur}
                                    onFocus={() => {
                                        inputRef2.current.props.onMenuClose();
                                        inputRef3.current.props.onMenuClose();
                                        inputRef4.current.props.onMenuClose();
                                        inputRef5.current.props.onMenuClose();
                                        inputRef6.current.props.onMenuClose();
                                        inputRef7.current.props.onMenuClose();
                                        inputRef1.current.props.onMenuClose();
                                    }}
                                    value={value}
                                    isSearchable={true}
                                    name={name}
                                    ref={(ref) => (inputRef9.current = ref)}
                                    className={'parentOption'}
                                    options={dropDownData?.welfares || []}
                                    components={{ MenuList: SingleCheckbox, ValueContainer: WelfaresValueContainer }}
                                />
                            )}
                        />
                    </div>
                </div>
            </form>
        </>
    );
}
export default memo(Filter);
