import classNames from 'classnames/bind';
import { useEffect, useState } from 'react';
import styles from './_Login.module.scss';

import PhoneNumber from '../PhoneNumber';
import _Password from '../_Password/_Password';
import { useTranslation } from 'react-i18next';

import { requestLogin } from '~/services/authService';
import { login } from '~/reducers/authReducer';
import { open, close } from '~/reducers/popupReducer';
import { useDispatch, useSelector } from 'react-redux';
import SocialMedia from '../_SocialMedia';

const defaultCredential = {
    email: '',
    phone: '',
    areaCode: 100,
    password: ''
}

const cx = classNames.bind(styles);

function _Login() {

    const [tab, setTab] = useState('email')
    const [error, setError] = useState({})
    const dispatch = useDispatch()
    const { auth, popup, dropdown } = useSelector(state => state)

    const [loginCredential, setLoginCredential] = useState(defaultCredential)

    const { t } = useTranslation()

    useEffect(() => {
        if (!popup.show) {
            setError({})
            setLoginCredential(defaultCredential)
        }
    }, [popup.show])

    useEffect(() => {
        setLoginCredential({
            ...loginCredential,
            email: '',
            phone: ''
        })
    }, [tab])

    const onChangePassword = (e) => {
        setLoginCredential({
            ...loginCredential,
            password: e.target.value
        })
    }

    const onChangeEmail = (e) => {
        setLoginCredential({
            ...loginCredential,
            email: e.target.value
        })
    }

    const onPhoneChange = (value) => {
        setLoginCredential({
            ...loginCredential,
            ...value
        })
    }

    const handleLogin = async (e) => {
        e.preventDefault()
        setError({})
        let data = {}
        let error = {}
        let isValid = true
        if (tab == 'phone') {
            data = {
                type: tab,
                area_code_id: loginCredential.areaCode,
                phone: loginCredential.phone,
                password: loginCredential.password
            }
        } else {
            data = {
                type: tab,
                email: loginCredential.email,
                password: loginCredential.password
            }
        }
        if (data.phone && data.area_code_id) {
            let areaCode = dropdown ? dropdown.areacodes.find(areaCode => areaCode.id == data.area_code_id) : undefined
            if (areaCode) {
                if (areaCode.area_code.includes('852')) {
                    var phone_format = new RegExp("^[0-9]{8}$")
                    if (!data.phone.match(phone_format)) {
                        isValid = false
                        error = {
                            ...error,
                            phone: [t('error.phone_must_be_eight')]
                        }
                    }
                }
            }
        }

        if ((data.phone && data.area_code_id) || data.email) {

        } else {
            isValid = false
        }

        if (isValid) {
            const res = await requestLogin({
                ...data
            })
            if (res.message) {
                setError({
                    ...res.message
                })
            } else if (res.user) {
                setError({})
                dispatch(login(res.user))
                dispatch(close())
            }
        } else {
            setError(error)
        }
    }

    return (
        <div className={cx('modal-body', 'modal-login')}>
            <div className={cx('title-wrapper')}>
                <h2>{t('login.login_title')}</h2>
            </div>
            <div className={cx('social-wrapper')}>
                <SocialMedia isLogin={true} />
            </div>
            <div className={cx('fields')}>
                <div className="row no-gutters login-division-line-container">
                    <div className="col-5">
                        <div className={cx('login-division-line')}></div>
                    </div>
                    <div className="col-2" style={{ textAlign: 'center' }}>{t('signup.or')}</div>
                    <div className="col-5">
                        <div className={cx('login-division-line')}></div>
                    </div>
                </div>
                <form className='__w100' onSubmit={handleLogin}>
                    <div className={cx('tabs')}>
                        <a className={`cursor-pointer ${tab == 'email' ? cx('active') : ''}`} onClick={() => setTab('email')}>{t('login.email')}</a>
                        <a className={`cursor-pointer ${tab == 'phone' ? cx('active') : ''}`} onClick={() => setTab('phone')}>{t('login.phone')}</a>
                    </div>
                    <div className={`${tab == 'email' ? '__flex __flex-d-column __w100' : '__d-none'}`}>
                        <div className={cx('input')} style={{ marginBottom: '1.6rem' }}>
                            <input type='text' name='email' placeholder={t('placeholder.email')} value={loginCredential.email} onChange={onChangeEmail} />
                        </div>
                        <div>
                            <_Password value={loginCredential.password} onChange={onChangePassword} />
                            {
                                error.password &&
                                <div className="help-block">{error.password.map(error => error)}</div>
                            }
                        </div>
                    </div>
                    <div className={`${tab == 'phone' ? '__flex __flex-d-column __w100' : '__d-none'}`}>
                        <div style={{ marginBottom: '1.6rem' }}>
                            <PhoneNumber codes={dropdown?.areacodes} value={loginCredential} onChange={onPhoneChange} />
                            {
                                error.phone &&
                                <div className="help-block">{error.phone.map(error => error)}</div>
                            }
                        </div>

                        <div>
                            <_Password value={loginCredential.password} onChange={onChangePassword} />
                            {
                                error.password &&
                                <div className="help-block">{error.password.map(error => t('error.' + error))}</div>
                            }
                        </div>
                    </div>
                    <button className={cx('button-submit')} type="submit" onClick={handleLogin} disabled={(loginCredential.email == '' && loginCredential.phone == '') || loginCredential.password == ''}>登入</button>
                </form>
                <p className="cursor-pointer" style={{ textAlign: 'center', fontSize: '1.6rem', padding: '10px', textDecoration: 'underline', marginTop: '20px' }} onClick={() => dispatch(open('forgotPassword'))}>{t('login.forget_password')}</p>
                <div className="row no-gutters login-division-line-container">
                    <div className="col-5">
                        <div className={cx('login-division-line')}></div>
                    </div>
                    <div className="col-2" style={{ textAlign: 'center', fontSize: '1.6rem' }}>{t('signup.or')}</div>
                    <div className="col-5">
                        <div className={cx('login-division-line')}></div>
                    </div>
                </div>
                <button className={cx('button-submit')} onClick={() => dispatch(open('signup'))}>{t('signup.signup_title')}</button>
            </div>
        </div>
    )
}

export default _Login