import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Link, useLocation } from 'react-router-dom';
import styles from './Menu.module.scss';
import { useState } from 'react';

import * as Icon from '~/components/Icons';
import { useTranslation } from 'react-i18next';
const cx = classNames.bind(styles);

function Menu({ items = [], isShowIcon }) {
    const { i18n } = useTranslation();
    const location = useLocation();
    const { pathname } = location;
    const renderItems = () => {
        return items.map((item, index) => {
            const isBadge = !!item.badge;
            const key = isBadge ? [item.badge.tag] : 'default';
            const classNameItem = isBadge ? cx('has-badge', i18n.languages[0]) : '';

            let Type = Link;
            const props = {};

            if (item?.to) {
                props.to = item?.to;
                Type = Link;
            } else if (item?.href) {
                props.href = item?.href;
                Type = 'a';
            }
            const mobileIcon = item?.mobileIcon ? item?.mobileIcon : null;
            const MobileIconComp = Icon[mobileIcon];
            return (
                <li key={index} className={classNameItem}>
                    <Type {...props}>
                        {isShowIcon && !!item?.mobileIcon && <MobileIconComp />}
                        {item.title}
                    </Type>

                    {isBadge && <span className={cx(`${styles.badge} ${styles[key]}`)}>{item.badge.title}</span>}
                </li>
            );
        });
    };

    return <ul className={pathname === '/en' ? cx('menu-list', 'eng') : cx('menu-list')}> {renderItems()} </ul>;
}

Menu.propTypes = {
    items: PropTypes.array,
};

export default Menu;
