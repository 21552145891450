import { configureStore } from '@reduxjs/toolkit';

import authReducer from './reducers/authReducer';
import chatroomReducer from './reducers/chatroomReducer';
import dropdownReducer from './reducers/dropdownReducer';
import popupReducer from './reducers/popupReducer';
import searchReducer from './reducers/searchReducer';
import cookiePopupReducer from './reducers/cookiePopupReducer';

export default configureStore({
    reducer: {
        auth: authReducer,
        popup: popupReducer,
        dropdown: dropdownReducer,
        search: searchReducer,
        chatroom: chatroomReducer,
        cookiePopup: cookiePopupReducer,
    },
});
