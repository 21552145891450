import React from 'react';
import classNames from 'classnames/bind';
import { Trans, useTranslation } from 'react-i18next';
import styles from './CookiesPopUp.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { setShowCookiePopup } from '~/reducers/cookiePopupReducer';
import { useCookies } from 'react-cookie';

const cx = classNames.bind(styles);

function CookiesPopUp() {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const { show } = useSelector((state) => state.cookiePopup);
    const [popUpCookies, setPopUpCookies] = useCookies(['giggik-cookie-alert-shown']);

    const closeCookiePopup = () => {
        dispatch(setShowCookiePopup(false));
        setPopUpCookies('giggik-cookie-alert-shown', true, {
            maxAge: 60 * 60 * 24 * 30,
        });
    };

    if (popUpCookies['giggik-cookie-alert-shown']) return null;

    return (
        <div className={cx('container')}>
            <p>
                <Trans i18nKey="header.cookies_popup">
                    本網站使用Cookies來改善您的瀏覽體驗，請確定您同意及接受我們的
                    <a href={`${process.env.REACT_APP_BASE_URL}/${i18n.languages[0]}/privacy-policy`}>私隱政策</a>
                    聲明與
                    <a href={`${process.env.REACT_APP_BASE_URL}/${i18n.languages[0]}/terms-of-use`}>條款及細則</a>
                    ，才繼續瀏覽此網站。
                </Trans>
            </p>
            <button onClick={() => closeCookiePopup()}>{t('header.read_and_agree')}</button>
        </div>
    );
}

export default CookiesPopUp;
