import { useState, useEffect, useLayoutEffect } from 'react';

function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState({});
    const { innerWidth: width, innerHeight: height } = window;

    function handleSetDimension() {
        setWindowDimensions({ width, height });
    }

    useEffect(() => {
        handleSetDimension();
    }, []);

    useLayoutEffect(() => {
        window.addEventListener('resize', handleSetDimension);
        return () => window.removeEventListener('resize', handleSetDimension);
    }, [windowDimensions]);

    return windowDimensions;
}

export default useWindowDimensions;
