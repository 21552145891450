import React from 'react';
import classNames from 'classnames/bind';
import images from '~/assets/images';
import styles from './HomeSection.module.scss';
import { HomeSectionBgTitleIconShort, HomeSectionBgTitleIconLong } from '~/components/Icons';
import { useLocation } from 'react-router-dom';
const cx = classNames.bind(styles);

function HomeSection({
    title,
    longBgTitle,
    background,
    optionClass,
    optionClassTitle,
    children,
    headlineEnglishType = -1,
    isDisplayJobTitle = false,
}) {
    const location = useLocation();
    const yellowColor = '#FED521';
    const blueColor = '#69CBE1';

    const backgroundColor =
        background === 'yellow' ? yellowColor : background === 'blue' ? blueColor : background ? background : blueColor;

    let backgroundClass = '';
    let backgroundOptionalColor = '';

    const headlineEnglishOptions = [
        images.headerLine[1],
        images.headerLine[2],
        images.headerLine[3],
        images.headerLine[4],
    ];
    if (['bg-yellow', 'bg-blue', 'bg-default'].indexOf(backgroundColor) >= 0) {
        backgroundClass = [backgroundColor];
    } else {
        backgroundOptionalColor = [backgroundColor];
    }
    var BgTitleIcon;
    if (location !== '/en') {
        BgTitleIcon = longBgTitle ? HomeSectionBgTitleIconLong : HomeSectionBgTitleIconShort;
    } else {
        // EN
    }
    return (
        <div className={cx('wrapper', optionClass)}>
            {title && location.pathname !== '/en' ? (
                <header className={cx('title', optionClassTitle)}>
                    <BgTitleIcon color={backgroundColor} />
                    <h1>{title}</h1>
                </header>
            ) : (
                // <header className={cx('title', optionClassTitle)}>
                //     <img src={headlineEnglishOptions[headlineEnglishType]} />
                //     <h1>{title}</h1>
                // </header>
                <>
                    {headlineEnglishType === -1 ? (
                        <>
                            {isDisplayJobTitle && (
                                <header className={cx('title', optionClassTitle)}>
                                    <BgTitleIcon color={backgroundColor} />
                                    <h1>{title}</h1>
                                </header>
                            )}
                        </>
                    ) : (
                        <header className={cx('title', optionClassTitle)}>
                            <img src={headlineEnglishOptions[headlineEnglishType]} />
                            <h1>{title}</h1>
                        </header>
                    )}
                </>
            )}

            {children}
        </div>
    );
}

export default HomeSection;
