import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import Header from '~/layouts/components/Header';
import styles from './DefaultLayout.module.scss';
import Footer from '~/layouts/components/Footer';
import { Navigate, useParams } from 'react-router-dom';

import { useState, useLayoutEffect, useEffect, useCallback } from 'react';
import { useWindowDimensions } from '~/hooks';
import { getAllDropdown } from '~/services/dropdownService';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { get } from '~/reducers/dropdownReducer';
import CookiesPopUp from '../components/CookiesPopUp/CookiesPopUp';
import config from '~/config';
// import { useEventListener, useIsomorphicLayoutEffect } from 'usehooks-ts';

const cx = classNames.bind(styles);

function DefaultLayout({ children }) {
    const getDimensions = useWindowDimensions();
    const [dimensions, setDimensions] = useState({
        height: window.innerHeight,
        width: window.innerWidth,
    });
    const dispatch = useDispatch();
    const { i18n } = useTranslation();

    const routesNew = [config.routes.projectSupportStation];

    useEffect(() => {
        const fetchApi = async () => {
            const result = await getAllDropdown();
            if (result) {
                dispatch(get(result));
            }
        };

        fetchApi();
    }, [i18n.language]);

    // windowHeight
    useLayoutEffect(() => {
        function handleResize() {
            setDimensions({
                height: getDimensions.height,
                width: getDimensions.width,
            });
        }
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [dimensions]);

    let { locale } = useParams();
    if (!['en', 'tc', 'sc'].includes(locale)) {
        return <Navigate to={`/en/${locale}`} replace />;
    }

    return children;
}

DefaultLayout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default DefaultLayout;
