import { useEffect, useState, useRef, memo } from 'react';
import { faCircleXmark, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HeadlessTippy from '@tippyjs/react/headless';
import classNames from 'classnames/bind';

import * as searchServices from '~/services/searchService';
import { Wrapper as PopperWrapper } from '~/components/Popper';
import AccountItem from '~/components/AccountItem';
import { SearchIcon, SearchMenuFilterIcon } from '~/components/Icons';
import { useDebounce } from '~/hooks';
import styles from './Search.module.scss';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useContext } from 'react';
// import { HeaderProvider, HeaderContext } from '~/layouts/components/Header/HeaderContext';
import { HeaderContext, HeaderProvider } from '~/ThemeContext';
import { useSelector } from 'react-redux';
import queryString from 'query-string';
import * as homepageService from '~/services/homepageService';
import DetectItemShow from '~/components/DetectItemShow';

const cx = classNames.bind(styles);

function Search() {
    const { t, i18n } = useTranslation();
    let navigate = useNavigate();
    const [searchValue, setSearchValue] = useState('');
    const [searchResult, setSearchResult] = useState([]);
    const [showResult, setShowResult] = useState(false);
    const [loading, setLoading] = useState(false);
    // const [showFilter, setShowFilter] = useState(false);
    const search = useSelector((state) => state.search);
    const filterContext = useContext(HeaderContext);

    const debouncedValue = useDebounce(searchValue, 500);

    const inputRef = useRef();

    useEffect(() => {
        if (!debouncedValue.trim()) {
            setSearchResult([]);
            return;
        }

        const fetchApi = async () => {
            setLoading(true);

            const result = await searchServices.search(debouncedValue);

            setSearchResult(result);
            setLoading(false);
        };

        // fetchApi();
    }, [debouncedValue]);

    const handleClear = () => {
        setSearchValue('');
        setSearchResult([]);
        inputRef.current.focus();
    };

    const handleHideResult = () => {
        setShowResult(false);
    };

    const handleChange = (e) => {
        const searchValue = e.target.value;
        if (!searchValue.startsWith(' ')) {
            setSearchValue(searchValue);
        }
    };

    const handleSearchOnClick = (e) => {
        e.preventDefault();

        let url = queryString.stringify(
            {
                ...search,
                keyword: searchValue,
                searchType: 'all',
                // salary: this.state.query.salary ? JSON.stringify(this.state.query.salary) : undefined
            },
            { arrayFormat: 'bracket' },
        );

        let object_value = searchValue;
        if (url.split("&").length > 2) {
            object_value = url;
        }
        homepageService.itemClick({
            section: 'homepage',
            sub_section: 'Search',
            object_type: 'search',
            object_value: object_value,
        })

        window.location.href = `${process.env.REACT_APP_BASE_URL}/${i18n.languages[0]}/search?${url}`;
    };
    return (
        // Using a wrapper <div> tag around the reference element solves
        // this by creating a new parentNode context.
        <>
            <HeadlessTippy
                interactive
                visible={showResult && searchResult.length > 0}
                // render={(attrs) => (
                //     <div className={cx('search-result')} tabIndex="-1" {...attrs}>
                //         <PopperWrapper>
                //             <h4 className={cx('search-title')}>Accounts</h4>
                //             {searchResult.map((result) => (
                //                 <AccountItem key={result.id} data={result} />
                //             ))}
                //         </PopperWrapper>
                //     </div>
                // )}
                onClickOutside={handleHideResult}
            >
                <div className={cx('search')}>
                    <span className={cx('search-icon')}>
                        <SearchIcon />
                    </span>

                    <form onSubmit={handleSearchOnClick}>
                        <input
                            ref={inputRef}
                            value={searchValue}
                            placeholder={t('common.search_placeholder')}
                            spellCheck={false}
                            onChange={handleChange}
                            onFocus={() => setShowResult(true)}
                        />
                    </form>
                    {!!searchValue && !loading && (
                        <button className={cx('clear')} onClick={handleClear}>
                            <FontAwesomeIcon icon={faCircleXmark} />
                        </button>
                    )}
                    {loading && <FontAwesomeIcon className={cx('loading')} icon={faSpinner} />}
                    <button
                        className={cx('filter-btn')}
                        // style={{ background: 'transparent' }}
                        onClick={filterContext.handleShowFilter}
                    >
                        <SearchMenuFilterIcon color={`${filterContext?.showFilter ? '#36A9DA' : '#37313C'}`} />
                    </button>
                    <span className={cx('wrapper-search-btn')}>
                        <button className={cx('search-btn')} onMouseDown={handleSearchOnClick}>
                            {t('common.search')}
                        </button>
                    </span>
                </div>
            </HeadlessTippy>
        </>
    );
}

export default memo(Search);
