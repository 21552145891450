import config from '~/config';

// Layouts
import { HeaderOnly } from '~/layouts';

// Pages
import Home from '~/pages/Home';
import Profile from '~/pages/Profile';
import Guide from '~/pages/Guide';
import Help from '~/pages/Help';
import About from '~/pages/About';
import Login from '~/pages/Login';
import Signup from '~/pages/Signup';

import Candidate from '~/pages/Candidate';
import Employer from '~/pages/Employer';
import MoreInformation from '~/pages/MoreInformation';
import Recruitment from '~/pages/Recruitment';
import ContactInfo from '~/pages/ContactInfo';

import Following from '~/pages/Following';
import Upload from '~/pages/Upload';
import Search from '~/pages/Search';
import Live from '~/pages/Live';
import ProjectSupportStation from '~/pages/ProjectSupportStation';
import HumanResourceStation from '~/pages/HumanResourceStation';
import XiaoHongShu from '~/pages/XiaoHongShu';
import Bud from '~/pages/Bud';
import HomeProjectSupport from '~/pages/HomeProjectSupport';

// Public routes
const publicRoutes = [
    { path: config.routes.home, component: HomeProjectSupport },
    { path: config.routes.projectSupportStation, component: ProjectSupportStation },
    { path: config.routes.humanResourceStation, component: HumanResourceStation },
    { path: config.routes.xiaoHongShu, component: XiaoHongShu },
    { path: config.routes.bud, component: Bud },
    // { path: config.routes.projectSupportStation, component: ProjectSupportStation },
    // { path: config.routes.profile, component: Profile },
    // { path: config.routes.guide, component: Guide },
    // { path: config.routes.help, component: Help },
    // { path: config.routes.about, component: About },
    // { path: config.routes.login, component: Login },
    // { path: config.routes.signup, component: Signup },

    // { path: config.routes.candidate, component: Candidate },
    // { path: config.routes.employer, component: Employer },
    // { path: config.routes.moreInformation, component: MoreInformation },
    // { path: config.routes.recruitment, component: Recruitment },
    // { path: config.routes.contactInfo, component: ContactInfo },

    // { path: config.routes.following, component: Following },
    // { path: config.routes.live, component: Live },
    // { path: config.routes.upload, component: Upload, layout: HeaderOnly },
    // { path: config.routes.search, component: Search, layout: null },
];

const privateRoutes = [];

export { publicRoutes, privateRoutes };
