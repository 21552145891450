import './css/bootstrap.min.css';
import './css/owl.carousel.min.css';
import './css/owl.theme.default.min.css';
import './css/style.css';
import './css/responsive.css';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

function Header({ menu = [], logo, page = '', onClick }) {
    const { i18n } = useTranslation();
    const REACT_APP_PREFIX = process.env.REACT_APP_PREFIX;

    return (
        <header className="bg-cream" onClick={onClick}>
            <div className="container wave wave_header">
                <div className="row">
                    <div className="col-12 position-relative">
                        <div className="main-menu mb-3">
                            <div className="main-logo">
                                <div
                                    data-bs-toggle="collapse"
                                    data-bs-target="#navbarLogo"
                                    aria-controls="navbarLogo"
                                    aria-expanded="false"
                                    aria-label="Toggle navigation"
                                >
                                    <img src={logo?.image} className="logo" />
                                </div>
                                {logo?.content?.length > 0 && (
                                    <div className="logo_collapse collapse navbar-collapse col-12" id="navbarLogo">
                                        <div style={{ height: '20px' }}></div>
                                        <div className="col-12 row spacing-0 container_logo_collapse pt-2 pb-2">
                                            {logo?.content?.map((value, index) => {
                                                return (
                                                    <a key={String(index)} href={value?.href} className="col-xl-3">
                                                        <img src={value?.image} />
                                                        {value?.title}
                                                    </a>
                                                );
                                            })}
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="d-flex">
                                <ul
                                    className="main-nav collapse navbar-collapse"
                                    id="navbarMobile"
                                    style={{ zIndex: 2 }}
                                >
                                    {menu?.map((value, index) => {
                                        return (
                                            <li key={String(index)}>
                                                <a href={value?.href} target={value?.newTab ? '_blank' : '_self'}>
                                                    {value?.title}
                                                </a>
                                            </li>
                                        );
                                    })}
                                </ul>
                                <div
                                    className="navbar-toggle"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#navbarMobile"
                                    aria-controls="navbarMobile"
                                    aria-expanded="false"
                                    aria-label="Toggle navigation"
                                >
                                    <span />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className="d-flex language">
                <a
                    className={`ms-2 ${i18n.language == 'en' ? 'bg-orange text-white ' : ''}`}
                    style={{
                        fontSize: '12px',
                        borderRadius: 99999,
                        width: '30px',
                        height: '30px',
                        display: 'flex',
                        textAlign: 'center',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                    href={`${REACT_APP_PREFIX != '' ? `/${REACT_APP_PREFIX}` : ''}/en${page}`}
                >
                    ENG
                </a>
                <a
                    className={`ms-2 ${i18n.language == 'tc' ? 'bg-orange text-white ' : ''}`}
                    style={{
                        fontSize: '12px',
                        borderRadius: 99999,
                        width: '30px',
                        height: '30px',
                        display: 'flex',
                        textAlign: 'center',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                    href={`${REACT_APP_PREFIX != '' ? `/${REACT_APP_PREFIX}` : ''}/tc${page}`}
                >
                    繁
                </a>
                <a
                    className={`ms-2 ${i18n.language == 'sc' ? 'bg-orange text-white ' : ''}`}
                    style={{
                        fontSize: '12px',
                        borderRadius: 99999,
                        width: '30px',
                        height: '30px',
                        display: 'flex',
                        textAlign: 'center',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                    href={`${REACT_APP_PREFIX != '' ? `/${REACT_APP_PREFIX}` : ''}/sc${page}`}
                >
                    简
                </a>
            </div> */}
        </header>
    );
}

export default Header;
