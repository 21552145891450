import * as httpRequest from '~/utils/httpRequest';

export const getData = async ({ language }) => {
    try {
        const res = await httpRequest.get(`/getProjectSupportStation?lang=${language}`, {
            params: {},
        });
        return res;
    } catch (error) {
        console.log(error);
    }
};

export const getHumanResource = async ({ language }) => {
    try {
        const res = await httpRequest.get(`/getHumanResourceStation?lang=${language}`, {
            params: {},
        });
        return res;
    } catch (error) {
        console.log(error);
    }
};

export const getXiaoHungShu = async ({ language }) => {
    try {
        const res = await httpRequest.get(`/getXiaoHongShu?lang=${language}`, {
            params: {},
        });
        return res;
    } catch (error) {
        console.log(error);
    }
};

export const getHome = async ({ language }) => {
    try {
        const res = await httpRequest.get(`/getStationHomePage?lang=${language}`, {
            params: {},
        });
        return res;
    } catch (error) {
        console.log(error);
    }
};

export const getBUD = async ({ language }) => {
    try {
        const res = await httpRequest.get(`/getBUD?lang=${language}`, {
            params: {},
        });
        return res;
    } catch (error) {
        console.log(error);
    }
};
