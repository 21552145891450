import classNames from 'classnames/bind';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select, { components } from 'react-select'
import styles from './PhoneNumber.module.scss';

const selectStylesNoIconWithLowHeight = {
    container: (provided, state) => ({
        ...provided,
        borderRadius: '10px',
        minHeight: '50px',
        position: 'relative',
        border: '1px solid #dedede',
        boxShadow: 'inset 0 0 5px #dedede',
        minWidth: '150px',
        maxWidth: '150px',
        backgroundColor: '#fff',
        marginRight: '10px'
    }),
    control: (provided, state) => ({
        ...provided,
        borderColor: 'transparent!important',
        outlineColor: 'transparent!important',
        boxShadow: 'none',
        minHeight: state.isDisabled ? 46 : 46,
        borderRadius: 10,
        backgroundColor: state.isDisabled ? '#fff' : '#fff',
        marginTop: state.isDisabled ? 0 : '',
        marginLeft: state.isDisabled ? 0 : '',
    }),
    menu: (provided, state) => ({
        ...provided,
        zIndex: 100
    }),
    indicatorSeparator: () => ({
        display: 'none'
    }),
    indicatorsContainer: (styles, { isDisabled }) => ({
        ...styles,
        opacity: isDisabled ? 0 : 1,
    }),
    multiValueRemove: (styles, { isDisabled }) => ({
        ...styles,
        display: isDisabled ? 'none' : 'inline',
    }),
    multiValueLabel: (styles, { isDisabled }) => ({
        ...styles,
        paddingRight: isDisabled ? 6 : 3,
    }),

}

const customStyles = {
    ...selectStylesNoIconWithLowHeight,
    menu: (provided, state) => ({
        ...provided,
        ...selectStylesNoIconWithLowHeight.menu(provided, state),
        width: 'auto',
    }),
    option: (provided, state) => ({
        ...provided,
        whiteSpace: 'nowrap',
    }),
}

const Option = props => {
    return (
        <components.Option {...props}>{props.data.choice}</components.Option>
    );
};


const cx = classNames.bind(styles);

function PhoneNumber({ codes = [], value = {}, onChange, disabled }) {

    const [areaCodes, setAreaCodes] = useState([])
    const { t } = useTranslation()

    useEffect(() => {
        setAreaCodes(codes.map(country =>
            ({ value: country.id, label: country.area_code, choice: country.name + ' ' + country.area_code })
        ))
    }, [codes])

    const handleCodeChange = (option) => {
        if ((option || []).length > 1) return false;
        onChange({
            ...value,
            areaCode: option.value
        })
    }

    const handleOnChange = (e) => {
        let phone = ''
        if (e.target.value !== '') {
            phone = e.target.value.replace(/[^0-9.]+/g, '')
        }
        onChange({
            ...value,
            phone
        })
    }

    let defaultValue = {};
    if (value['areaCode']) {
        defaultValue = areaCodes.find(areaCode => areaCode.value === value['areaCode']) || {};
    }

    return (
        <div className={cx('phoneNumber-wrapper')}>
            <div className={cx('code-field')}>
                <Select
                    options={areaCodes}
                    styles={customStyles}
                    components={{ Option }}
                    value={defaultValue}
                    onChange={handleCodeChange}
                    isDisabled={disabled}
                />
            </div>
            <div className={cx('phone-field')}>
                <input
                    type={"text"}
                    placeholder={t('placeholder.phonenumber')}
                    name='phone'
                    value={value['phone'] ? value['phone'] : ''}
                    onChange={handleOnChange}
                    disabled={disabled}
                />
            </div>
        </div>
    )
}

export default PhoneNumber