import { Fragment, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { publicRoutes } from '~/routes';
import DefaultLayout from '~/layouts';
import { useDispatch } from 'react-redux';
import { open } from '~/reducers/popupReducer';
import { useCookies } from 'react-cookie';
import { setShowCookiePopup } from './reducers/cookiePopupReducer';

function App() {
    const i18nextLng = localStorage.getItem('i18nextLng');
    let locale = 'tc';
    // if (i18nextLng) {
    //     if (i18nextLng !== 'en-GB') {
    //         locale = i18nextLng;
    //     }
    // }
    const dispatch = useDispatch();
    const [cookies, setCookie] = useCookies(['giggik-statement-shown']);

    const _displayStatement = () => {
        dispatch(open('statement'));
        setCookie('giggik-statement-shown', true, {
            maxAge: 60 * 60 * 24,
        });
    };

    // useEffect(() => {
    //     if (!cookies['giggik-statement-shown']) {
    //         _displayStatement();
    //     }
    // }, []);

    return (
        <Router basename={process.env.REACT_APP_PREFIX}>
            <div className="App">
                <Routes>
                    {publicRoutes.map((route, index) => {
                        const Page = route.component;
                        let Layout = DefaultLayout;

                        if (route.layout) {
                            Layout = route.layout;
                        } else if (route.layout === null) {
                            Layout = Fragment;
                        }
                        return (
                            <Route
                                key={index}
                                path={'/:locale' + route.path}
                                element={
                                    <Layout>
                                        <Page />
                                    </Layout>
                                }
                            />
                        );
                    })}
                    {/* <Route path={'*'} element={<Navigate to={`/${locale}`} replace />} /> */}
                    <Route path={'*'} element={<Navigate to={`/tc`} replace />} />
                </Routes>
            </div>
        </Router>
    );
}

export default App;
