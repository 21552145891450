import { useTranslation } from 'react-i18next';
import routes from '~/config/routes';
import { useEffect, useLayoutEffect, useState } from 'react';
import '../ProjectSupportStation/css/bootstrap.min.css';
import '../ProjectSupportStation/css/owl.carousel.min.css';
import '../ProjectSupportStation/css/owl.theme.default.min.css';
import '../ProjectSupportStation/css/style.css';
import '../ProjectSupportStation/css/responsive.css';
import Header from '../ProjectSupportStation/Header';
import Footer from '../ProjectSupportStation/Footer';
import * as Services from '~/services/projectSupportStationService';
import { useWindowDimensions } from '~/hooks';
import $ from 'jquery';

const HomeProjectSupport = () => {
    const { t, i18n } = useTranslation();
    const REACT_APP_PREFIX = process.env.REACT_APP_PREFIX;

    // const { width } = useWindowDimensions();

    const _addScript = (source) => {
        let scripts = document.getElementsByTagName('script');
        for (var i = scripts.length; i--; ) {
            if (
                scripts[i].src == `http://${window.location.host}${source}` ||
                scripts[i].src == `https://${window.location.host}${source}`
            )
                return;
        }

        const script = document.createElement('script');

        script.src = process.env.PUBLIC_URL + source;
        script.async = true;

        document.body.appendChild(script);
    };

    // const menu = [
    //     {
    //         title: t('logoName.supportStation'),
    //         href: `${REACT_APP_PREFIX != '' ? `/${REACT_APP_PREFIX}` : ''}/${i18n.language}${
    //             routes.projectSupportStation
    //         }`,
    //     },
    //     {
    //         title: t('logoName.littleRedBook'),
    //         href: `${REACT_APP_PREFIX != '' ? `/${REACT_APP_PREFIX}` : ''}/${i18n.language}${routes.xiaoHongShu}`,
    //     },
    //     {
    //         title: t('logoName.budFund'),
    //         href: `${REACT_APP_PREFIX != '' ? `/${REACT_APP_PREFIX}` : ''}/${i18n.language}${routes.bud}`,
    //     },
    //     {
    //         title: t('logoName.recruitmentStation'),
    //         href: 'https://www.giggik.com/',
    //         newTab: true,
    //     },
    //     {
    //         title: t('logoName.talentStation'),
    //         href: `${REACT_APP_PREFIX != '' ? `/${REACT_APP_PREFIX}` : ''}/${i18n.language}${
    //             routes.humanResourceStation
    //         }`,
    //     },
    // ];

    const logo = {
        image: require('~/assets/images/projectsupportstation/images/home.png'),
        content: [
            {
                title: t('logoName.supportStation'),
                href: `${REACT_APP_PREFIX != '' ? `/${REACT_APP_PREFIX}` : ''}/${i18n.language}${
                    routes.projectSupportStation
                }`,
                image: require('~/assets/images/projectsupportstation/images/support-station.png'),
            },
            {
                title: t('logoName.littleRedBook'),
                image: require('~/assets/images/projectsupportstation/images/xiao-hong-shu.png'),
                href: `${REACT_APP_PREFIX != '' ? `/${REACT_APP_PREFIX}` : ''}/${i18n.language}${routes.xiaoHongShu}`,
            },
            {
                title: t('logoName.talentStation'),
                href: `${REACT_APP_PREFIX != '' ? `/${REACT_APP_PREFIX}` : ''}/${i18n.language}${
                    routes.humanResourceStation
                }`,
                image: require('~/assets/images/projectsupportstation/images/human-resource.png'),
            },
            {
                title: t('logoName.budFund'),
                image: require('~/assets/images/projectsupportstation/images/bud.png'),
                href: `${REACT_APP_PREFIX != '' ? `/${REACT_APP_PREFIX}` : ''}/${i18n.language}${routes.bud}`,
            },
        ],
    };

    const [data, setData] = useState(null);
    const [showContent, setShowContent] = useState(-1);
    const [hoverContent, setHoverContent] = useState(-1);
    const [banner, setBanner] = useState([]);
    const [showStickey, setShowStickey] = useState(false);
    const [iconFooter, setIconFooter] = useState([]);
    const [menu, setMenu] = useState([]);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    // windowHeight
    useLayoutEffect(() => {
        function handleResize() {
            setWidth(window.innerWidth);
            setHeight(window.innerHeight);
        }
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [width, height]);

    // _addScript('/js/owl-carousel/owl.carousel.min.js');

    useEffect(() => {
        if (data) {
            _addScript('/js/custom.js');
            _addScript('/js/bootstrap.bundle.min.js');

            $(document).on('click', function (event) {
                var $target = $(event.target);
                // if (!$target.closest('.icon-banner').length) {
                //     setShowContent(-1);
                // }
            });
        }
    }, [data]);

    useEffect(() => {
        Services.getHome({ language: i18n.language }).then((res) => {
            setData(res);

            setBanner([
                {
                    icon: require('~/assets/images/projectsupportstation/images/icon_banner_home_1.png'),
                    icon_1: require('~/assets/images/projectsupportstation/images/icon_banner_home_1_1.png'),
                    icon_2: require('~/assets/images/projectsupportstation/images/icon_banner_home_1_2.png'),
                    dropdown: require('~/assets/images/projectsupportstation/images/dropdown_banner_home_1.png'),
                    title: res?.banner_setting[0]?.colum1[0]?.title,
                    content: res?.banner_setting[0]?.colum1[0]?.content,
                    color: 'color-red',
                    link: res?.banner_setting[0]?.colum1[0]?.hyper_link,
                },
                {
                    icon: require('~/assets/images/projectsupportstation/images/icon_banner_home_2.png'),
                    icon_1: require('~/assets/images/projectsupportstation/images/icon_banner_home_2_1.png'),
                    icon_2: require('~/assets/images/projectsupportstation/images/icon_banner_home_2_2.png'),
                    dropdown: require('~/assets/images/projectsupportstation/images/dropdown_banner_home_2.png'),
                    title: res?.banner_setting[0]?.colum2[0]?.title,
                    content: res?.banner_setting[0]?.colum2[0]?.content,
                    color: 'color-blue',
                    link: res?.banner_setting[0]?.colum2[0]?.hyper_link,
                },
                {
                    icon: require('~/assets/images/projectsupportstation/images/icon_banner_home_3.png'),
                    icon_1: require('~/assets/images/projectsupportstation/images/icon_banner_home_3_1.png'),
                    icon_2: require('~/assets/images/projectsupportstation/images/icon_banner_home_3_2.png'),
                    dropdown: require('~/assets/images/projectsupportstation/images/dropdown_banner_home_3.png'),
                    title: res?.banner_setting[0]?.colum3[0]?.title,
                    content: res?.banner_setting[0]?.colum3[0]?.content,
                    color: 'color-green',
                    link: res?.banner_setting[0]?.colum3[0]?.hyper_link,
                },
            ]);

            const iconFooter = res?.footer?.map((value) => {
                let image = require('~/assets/images/projectsupportstation/images/whatsapp.png');
                if (value?.name?.toLocaleUpperCase() == 'WhatApps'?.toLocaleUpperCase()) {
                    image = require('~/assets/images/projectsupportstation/images/whatsapp.png');
                }
                if (value?.name?.toLocaleUpperCase() == 'Facebook'?.toLocaleUpperCase()) {
                    image = require('~/assets/images/projectsupportstation/images/facebook.png');
                }
                if (value?.name?.toLocaleUpperCase() == 'Instagram'?.toLocaleUpperCase()) {
                    image = require('~/assets/images/projectsupportstation/images/instagram.png');
                }
                if (value?.name?.toLocaleUpperCase() == 'Linkedin'?.toLocaleUpperCase()) {
                    image = require('~/assets/images/projectsupportstation/images/linkedin.png');
                }

                return {
                    icon: image,
                    href: value?.url,
                    title: value?.name,
                };
            });

            setIconFooter(iconFooter);

            const menu = res?.menu
                ?.sort((a, b) => {
                    return a?.ordering - b?.ordering;
                })
                ?.map((value) => {
                    return {
                        title: value?.menu_text,
                        href: value?.section_name,
                        newTab: value?.new_tab,
                    };
                });

            setMenu(menu);
        });
    }, [i18n]);

    const _clickIconBanner = (index) => {
        if (index == showContent) {
            setShowContent(-1);
        } else {
            setShowContent(index);
        }
    };

    const _hoverContentBanner = (index) => {
        if (index == hoverContent) {
            setHoverContent(-1);
        } else {
            setHoverContent(index);
        }
    };

    return (
        <>
            {data && (
                <div className="wrapper">
                    <Header logo={logo} menu={menu} page={routes.home} />

                    <div className="">
                        <div className="container-fluid banner_home spacing-0">
                            <div className="row container spacing-0 mt-auto justify-content-center ">
                                <div
                                    className={`d-flex spacing-0 align-items-end pt-5 ${
                                        width < 600 ? 'col-12 row' : 'col-md-10'
                                    }`}
                                >
                                    {banner?.map((value, index) => {
                                        if (width < 600) {
                                            return (
                                                <div
                                                    className={`col-md-4 pt-md-5 pb-md-5 banner-hover spacing-end-0`}
                                                    key={String(index)}
                                                    style={{ marginLeft: '1.5vw' }}
                                                >
                                                    <div className="pb-5">
                                                        <div className="col-12 row align-items-end">
                                                            <div className="col-12 spacing-0 text-center h-auto position-relative">
                                                                <a href={value?.link}>
                                                                    <img
                                                                        className={`icon-banner spacing-end-0`}
                                                                        src={value?.icon_1}
                                                                        style={{
                                                                            // width:
                                                                            //     hoverContent == index ||
                                                                            //     showContent == index
                                                                            //         ? '90%'
                                                                            //         : '70%',
                                                                            width: '90%',
                                                                            height: 'auto',
                                                                        }}
                                                                    />
                                                                </a>
                                                                <a
                                                                    // onClick={_clickIconBanner?.bind(this, index)}
                                                                    // style={{ cursor: 'pointer' }}

                                                                    href={value?.link}
                                                                >
                                                                    <img
                                                                        className={`icon-banner spacing-end-0 mt-3`}
                                                                        src={value?.icon_2}
                                                                        style={{
                                                                            // width:
                                                                            //     hoverContent == index ||
                                                                            //     showContent == index
                                                                            //         ? '90%'
                                                                            //         : '70%',
                                                                            width: '90%',
                                                                            height: 'auto',
                                                                        }}
                                                                    />
                                                                </a>
                                                            </div>
                                                            {/* <div
                                                                className="col-2 spacing-0 dropdown-banner"
                                                                onClick={_clickIconBanner?.bind(this, index)}
                                                                // onMouseOver={_clickIconBanner?.bind(this, 0)}
                                                                // onMouseOut={_clickIconBanner?.bind(this, 0)}
                                                                style={{ cursor: 'pointer' }}
                                                            >
                                                                <img
                                                                    className="icon-banner"
                                                                    src={value?.dropdown}
                                                                    style={{ width: '100%' }}
                                                                />
                                                            </div> */}
                                                        </div>

                                                        <div className="col-12 d-flex justify-content-center spacing-0 spacing-end">
                                                            <div
                                                                className="container-content-banner"
                                                                // onMouseOver={_hoverContentBanner?.bind(this, 0)}
                                                                // onMouseOut={_hoverContentBanner?.bind(this, 0)}
                                                                style={{
                                                                    // opacity:
                                                                    //     hoverContent == index || showContent == index
                                                                    //         ? 1
                                                                    //         : 0,
                                                                    // display:
                                                                    //     hoverContent == index || showContent == index
                                                                    //         ? 'block'
                                                                    //         : width < 600
                                                                    //         ? 'none'
                                                                    //         : 'block',
                                                                    opacity: 1,
                                                                    display: 'block',
                                                                    width: '95%',
                                                                    maxWidth: 'none',
                                                                    marginLeft: '2.5%',
                                                                }}
                                                            >
                                                                <div className="space col-12"></div>
                                                                <div className="col-12 bg-white shadow content-banner rounded-5 text-center">
                                                                    <h2
                                                                        className={`text-decoration-underline ${value?.color}`}
                                                                    >
                                                                        {value?.title}
                                                                    </h2>
                                                                    <div
                                                                        className={`${value?.color}`}
                                                                        dangerouslySetInnerHTML={{
                                                                            __html: value?.content,
                                                                        }}
                                                                    ></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        }

                                        return (
                                            <div
                                                className={`col-md-4 banner-hover ${
                                                    width < 600 ? 'spacing-end-0' : ''
                                                }`}
                                                key={String(index)}
                                            >
                                                <div className="col-12 row align-items-end">
                                                    <div className="col-12 spacing-0 text-center h-auto position-relative">
                                                        <a href={value?.link}>
                                                            <img
                                                                className={`icon-banner spacing-end-0`}
                                                                src={value?.icon_1}
                                                                style={{
                                                                    // width:
                                                                    //     hoverContent == index || showContent == index
                                                                    //         ? '90%'
                                                                    //         : '70%',
                                                                    width: '90%',
                                                                    height: 'auto',
                                                                }}
                                                            />
                                                        </a>
                                                        <a
                                                            // onClick={_clickIconBanner?.bind(this, index)}
                                                            // style={{ cursor: 'pointer' }}
                                                            href={value?.link}
                                                        >
                                                            <img
                                                                className={`icon-banner spacing-end-0 mt-3`}
                                                                src={value?.icon_2}
                                                                style={{
                                                                    // width:
                                                                    //     hoverContent == index || showContent == index
                                                                    //         ? '90%'
                                                                    //         : '70%',
                                                                    width: '90%',
                                                                    height: 'auto',
                                                                }}
                                                            />
                                                        </a>
                                                    </div>
                                                </div>

                                                <div className="col-12 d-flex justify-content-end spacing-end">
                                                    <div
                                                        className="container-content-banner"
                                                        style={{
                                                            // opacity:
                                                            //     hoverContent == index || showContent == index ? 1 : 0,
                                                            // display:
                                                            //     hoverContent == index || showContent == index
                                                            //         ? 'block'
                                                            //         : width < 600
                                                            //         ? 'none'
                                                            //         : 'block',
                                                            opacity: 1,
                                                            display: 'block',
                                                            marginRight: '5px',
                                                            width: '95%',
                                                            maxWidth: 'none',
                                                        }}
                                                    >
                                                        <div className="space col-12"></div>
                                                        <div className="col-12 bg-white shadow content-banner rounded-5 text-center">
                                                            <h2 className={`text-decoration-underline ${value?.color}`}>
                                                                {value?.title}
                                                            </h2>
                                                            <div
                                                                className={`${value?.color}`}
                                                                dangerouslySetInnerHTML={{
                                                                    __html: value?.content,
                                                                }}
                                                            ></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>

                    <section className="service">
                        <div className="container mt-5">
                            <div
                                className="row align-items-center justify-content-center "
                                style={{ marginLeft: width < 600 ? '10px' : 0 }}
                            >
                                <div className="col-lg-12 col-10 mx-auto text-center">
                                    <h1 className="color-red mb-3">{t('home.oneStopService')}</h1>
                                    <h3 className="color-orange">{t('home.zhijiCooperatesGovernment')}</h3>
                                </div>

                                <div className="col-xl-8 col-lg-9 col-md-12 mt-5" style={{ paddingLeft: 0 }}>
                                    <div className="circle">
                                        <div className="arrow">
                                            <img
                                                src={require('~/assets/images/projectsupportstation/images/arrow-left.png')}
                                                className="image-left"
                                            />
                                        </div>
                                        <div className="center text-center ">
                                            <div className="run">
                                                <img src={data?.stream_service[0]?.image} />
                                            </div>
                                            <ul className="check-circle-small mx-auto check-circle-content text-start ">
                                                {data?.stream_service[0]?.content?.map((value, index) => {
                                                    return (
                                                        <li
                                                            className={`${value?.color} bold-500`}
                                                            key={String(index)}
                                                            // style={{ fontSize: '14px' }}
                                                        >
                                                            {value?.content}
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        </div>
                                        <div className="arrow">
                                            <img
                                                src={require('~/assets/images/projectsupportstation/images/arrow-right.png')}
                                                className="image-right"
                                            />
                                        </div>
                                    </div>
                                    <div className="circle">
                                        <div className="center align-items-end ">
                                            <div className="run d-flex flex-column  align-items-end">
                                                <img src={data?.stream_service[1]?.image} />
                                            </div>
                                            <ul className="check-circle-small check-circle-content">
                                                {data?.stream_service[1]?.content?.map((value, index) => {
                                                    return (
                                                        <li
                                                            className={`${value?.color} bold-500`}
                                                            key={String(index)}
                                                            // style={{ fontSize: '14px' }}
                                                        >
                                                            {value?.content}
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        </div>
                                        <div className="arrow">
                                            <img
                                                src={require('~/assets/images/projectsupportstation/images/arrow-center.png')}
                                                className="image-center"
                                            />
                                        </div>
                                        <div className="center">
                                            <div className="run">
                                                <img src={data?.stream_service[2]?.image} />
                                            </div>
                                            <ul
                                                className="check-circle-small check-circle-content"
                                                style={{ marginRight: '10px' }}
                                            >
                                                {data?.stream_service[2]?.content?.map((value, index) => {
                                                    return (
                                                        <li
                                                            className={`${value?.color} bold-500`}
                                                            key={String(index)}
                                                            // style={{ fontSize: '14px' }}
                                                        >
                                                            {value?.content}
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="about bg-cream top-about-home">
                        <div className="container-fluid wave wave_top_cream">
                            <div className="container row align-items-center mb-4">
                                <div className="col-lg-8 mb-3">
                                    <h1 className="color-red mb-3">{t('home.aboutZhiji')}</h1>
                                    <p className="bold-500">
                                        <span className="color-orange">{t('home.position')}</span>{' '}
                                        {t('home.zjTechnologyCo')}
                                    </p>
                                </div>

                                <div className="col-lg-3 offset-lg-1 text-center">
                                    <img
                                        src={require('~/assets/images/projectsupportstation/images/Gig_Gik_Logo_Chin.png')}
                                    />
                                </div>
                            </div>

                            <div className="container row align-items-center mb-5">
                                <div className="col-lg-4 text-center">
                                    <img
                                        src={require('~/assets/images/projectsupportstation/images/testimonial.png')}
                                    />
                                </div>
                                <div className="col-lg-7 offset-lg-1">
                                    <div className="blockquote">{t('home.iHaveServedHongKong')}</div>
                                    <div className="d-flex justify-content-end mt-3 bold-500">
                                        {t('home.founderZhiji')} -{' '}
                                        <span className="color-orange">{t('home.cherrieChong')}</span>
                                    </div>
                                </div>
                            </div>

                            <div className="container row">
                                <div className="col-12">
                                    <div className="infobox icon_gig icon_eyesmile">
                                        <h2 className="color-orange text-center mb-2">
                                            {t('home.globalExchangeInformation')}
                                        </h2>
                                        <h2 className="color-orange text-center mb-5">
                                            {t('home.globalBangxinIntellectual')}
                                        </h2>
                                        <div className="pt-3"></div>
                                        <div className="row award">
                                            <div className="col-lg-6 bold-500">
                                                <img
                                                    src={require('~/assets/images/projectsupportstation/images/stick-2.png')}
                                                />{' '}
                                                {t('home.creativeEntrepreneurshipAssociationPresident')}
                                            </div>
                                            <div className="col-lg-6 bold-500">
                                                <img
                                                    src={require('~/assets/images/projectsupportstation/images/stick-2.png')}
                                                />{' '}
                                                {t('home.memberMandatoryProvident')}
                                            </div>
                                            <div className="col-lg-6 bold-500">
                                                <img
                                                    src={require('~/assets/images/projectsupportstation/images/stick-2.png')}
                                                />{' '}
                                                {t('home.hongKongPeopleSupport')}
                                            </div>
                                            <div className="col-lg-6 bold-500">
                                                <img
                                                    src={require('~/assets/images/projectsupportstation/images/stick-2.png')}
                                                />{' '}
                                                {t('home.executiveViceChairman')}
                                            </div>
                                            <div className="col-lg-6 bold-500">
                                                <img
                                                    src={require('~/assets/images/projectsupportstation/images/stick-2.png')}
                                                />{' '}
                                                {t('home.greaterBayArea')}
                                            </div>
                                            <div className="col-lg-6 bold-500">
                                                <img
                                                    src={require('~/assets/images/projectsupportstation/images/stick-2.png')}
                                                />{' '}
                                                {t('home.asiaPacificWomenEntrepreneurs')}
                                            </div>
                                            <div className="col-lg-6 bold-500">
                                                <img
                                                    src={require('~/assets/images/projectsupportstation/images/stick-2.png')}
                                                />{' '}
                                                {t('home.viceChairman')}
                                            </div>
                                            <div className="col-lg-6 bold-500">
                                                <img
                                                    src={require('~/assets/images/projectsupportstation/images/stick-2.png')}
                                                />{' '}
                                                {t('home.chairmanHongKongCateringIndustryAssociation')}
                                            </div>
                                            <div className="col-lg-6 bold-500">
                                                <img
                                                    src={require('~/assets/images/projectsupportstation/images/stick-2.png')}
                                                />{' '}
                                                {t('home.honoraryPresident')}
                                            </div>
                                            <div className="col-lg-6 bold-500">
                                                <img
                                                    src={require('~/assets/images/projectsupportstation/images/stick-2.png')}
                                                />{' '}
                                                {t('home.dlabAdvisor')}
                                            </div>
                                        </div>
                                        <div className="col-12 text-center ">
                                            <img
                                                src={require('~/assets/images/projectsupportstation/images/brand.png')}
                                                className="brands"
                                            />
                                        </div>

                                        <div>
                                            <div
                                                // style={{
                                                //     position: 'absolute',
                                                //     top: width < 600 ? '-8vw' : '-3.5vw',
                                                //     zIndex: 3,
                                                //     right: '3vw',
                                                //     display: 'flex',
                                                //     justifyContent: 'flex-end',
                                                // }}

                                                className="icon-table-home"
                                            >
                                                <img
                                                    src={require('~/assets/images/projectsupportstation/images/GigGik_Bullet_Point.png')}
                                                    className="col-2"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="partial-contact bg-cover py-5" id="contact">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12 text-center">
                                    <a
                                        href="http://sys.giggik.com/index.php?c=Form&source_id=13"
                                        target="_blank"
                                        className={`btn btn-default btn-large ${width < 600 ? 'mb-2' : ''}`}
                                    >
                                        {t('home.contactUs')}
                                    </a>
                                    <img
                                        src={require('~/assets/images/projectsupportstation/images/Gig_Gik.png')}
                                        className="Gig_Gik"
                                    />
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="faq" id="faq">
                        <div className="container-fluid wave wave_bottom">
                            <div className="container content-faq">
                                {/* <div className="row">
                                    <div className="col-12 text-center">
                                        <h1 className="mb-4 color-red">{t('projectSupportStation.commonProblem')}</h1>
                                    </div>
                                </div> */}
                                <div className="row">
                                    <div className="col-12">
                                        <div className="accordion accordion-flush" id="accordionFlushExample">
                                            {/* {data?.faq?.map((value, index) => {
                                                return (
                                                    <div className="accordion-item" key={String(index)}>
                                                        <div
                                                            className="accordion-header"
                                                            style={{ marginBottom: '30px' }}
                                                        >
                                                            <button
                                                                className="accordion-button collapsed"
                                                                type="button"
                                                                data-bs-toggle="collapse"
                                                                data-bs-target={`#flush-collapse${index}`}
                                                                aria-expanded="true"
                                                                aria-controls={`flush-collapse${index}`}
                                                            >
                                                                <img
                                                                    src={require('~/assets/images/projectsupportstation/images/Gig_Gik_question.png')}
                                                                    className="icon"
                                                                />
                                                                {value?.category}
                                                            </button>
                                                        </div>

                                                        <div
                                                            id={`flush-collapse${index}`}
                                                            className="accordion-collapse collapse ps-5"
                                                            data-bs-parent="#accordionFlushExample"
                                                        >
                                                            {value?.question?.map((question, iQuestion) => {
                                                                return (
                                                                    <div
                                                                        key={String(iQuestion)}
                                                                        style={{ marginBottom: '30px' }}
                                                                    >
                                                                        <button
                                                                            className="accordion-button spacing-0"
                                                                            type="button"
                                                                            data-bs-toggle="collapse"
                                                                            data-bs-target={`#flush-sub-collapse${index}${iQuestion}`}
                                                                            aria-expanded="false"
                                                                            aria-controls={`flush-sub-collapse${index}${iQuestion}`}
                                                                        >
                                                                            {question?.question}
                                                                        </button>
                                                                        <div
                                                                            id={`flush-sub-collapse${index}${iQuestion}`}
                                                                            className="accordion-collapse collapse"
                                                                            data-bs-parent={`#flush-collapse${index}`}
                                                                        >
                                                                            <div className="accordion-body">
                                                                                {question?.answer}
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-12 row mt-3 align-items-center">
                                                                            <div className="col-1 border-bottom-red-3"></div>
                                                                            <div className="col-11 border-bottom"></div>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    </div>
                                                );
                                            })} */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <Footer
                        logo={data?.sticky_logo[0]}
                        sticky_logo={data?.sticky_logo?.sort((a, b) => a?.ordering - b?.ordering)}
                        showStickey={showStickey}
                        setShowStickey={setShowStickey}
                        iconFooter={iconFooter}
                    />
                </div>
            )}
        </>
    );
};

export default HomeProjectSupport;
