
import React, { useState, useRef, forwardRef, useEffect } from 'react';
import classNames from 'classnames/bind';
import { Link, useLocation } from 'react-router-dom';
// import AliceCarousel from 'react-alice-carousel';
// import 'react-alice-carousel/lib/alice-carousel.css';
import { useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import {
    BagIcon,
    FavoriteIcon,
    FavoriteSelectedIcon,
    SlidePrevArrowIcon,
    SlideNextArrowIcon,
    CircleDollarIcon,
    CalendarCheckIcon,
    ExperienceIcon,
    LocationDotIcon,
} from '~/components/Icons';

import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import { useWindowDimensions } from '~/hooks';
import styles from './_JobSection.module.scss';
import DetectItemShow from '../DetectItemShow';
import * as homepageService from '~/services/homepageService';

const cx = classNames.bind(styles);

const currFormat = (amount) => {
    var parts = amount.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.join('.');
};

// const SwiperButtonNext = forwardRef((ref, props) => {
//     const swiper = useSwiper();
//     return (
//         <button
//             ref={ref}
//             className="swiper-next-button"
//             style={{ background: 'transparent' }}
//             onClick={() => swiper.slideNext()}
//         >
//             {props.children}
//         </button>
//     );
// });
// const SwiperButtonPrev = forwardRef((ref, props) => {
//     const swiper = useSwiper();
//     return (
//         <button
//             ref={ref}
//             className="swiper-prev-button"
//             style={{ background: 'transparent' }}
//             onClick={() => swiper.slidePrev()}
//         >
//             {props.children}
//         </button>
//     );
// });

function _JobSection({ type, titleIconColor, sectionData, handleSaveJob }) {
    const [loading, setLoading] = useState(false);
    const prevRef = useRef();
    const nextRef = useRef();
    const location = useLocation();
    const { pathname } = location;
    // const swiper = useSwiper();

    const { t, i18n } = useTranslation();

    const handleJobClick = (reference_no) => () => {
        homepageService.itemClick({
            section: 'homepage',
            sub_section: sectionData ? sectionData['name_en'] : '',
            object_type: 'job',
            object_id: reference_no
        })
    }

    const articleItems = sectionData?.jobs.map((article, index) => {
        let salary = '';
        if (!article?.body?.hide_salary) {
            if (article?.body?.salary_from) {
                salary = '$' + currFormat(article?.body?.salary_from);
            }
            if (article?.body?.salary_to) {
                if (salary === '') {
                    salary = '$' + currFormat(article?.body?.salary_to);
                } else {
                    salary = salary + '-' + currFormat(article?.body?.salary_to);
                }
            }
            if (salary !== '') {
                if (article?.body?.isNegotiable) {
                    salary = salary + ' (' + t('job.negotiable') + ')';
                }
            }
        }

        let experience = '';
        if (article?.body?.experience_from >= 0) {
            experience = article?.body?.experience_from;
        }
        if (article?.body?.experience_to > 0) {
            if (experience === '') {
                experience = article?.body?.experience_to;
            } else {
                experience = experience + '-' + currFormat(article?.body?.experience_to);
            }
        }
        // } else {
        //     if (experience != '') {
        //         experience = experience;
        //     }
        // }

        return (
            <SwiperSlide key={index}>
                <DetectItemShow data={{
                    section: 'homepage',
                    sub_section: sectionData ? sectionData['name_en'] : '',
                    object_type: 'job',
                    object_id: article?.reference_no
                }} />
                <div className={cx('article', type, i18n.languages[0])}>
                    <a href={article?.header?.link} onClick={handleJobClick(article?.reference_no)}>
                        <div className={cx('header')}>
                            <span className={cx('logo')}>
                                {!!article?.header?.logo && (
                                    <a href={article?.header?.link}>
                                        <img src={article?.header?.logo} alt="article-logo" />
                                    </a>
                                )}
                            </span>
                            <span className={cx('favorite')}>
                                <a href="#" onClick={handleSaveJob(sectionData.section_id, article?.header.job_id)}>
                                    {article?.header?.isFavorite ? <FavoriteSelectedIcon /> : <FavoriteIcon />}
                                </a>
                            </span>
                        </div>
                        <div className={cx('content')}>
                            <div className={cx('title')}>
                                <h2>{article?.header?.title}</h2>
                                <a href={article?.header?.company_link}>
                                    <h4>{article?.header?.subTitle}</h4>
                                </a>
                            </div>
                            <div className={cx('description')}>
                                <div className={cx('item')}>
                                    {/* <FontAwesomeIcon icon={faLocationDot} /> */}
                                    <LocationDotIcon />
                                    {/* <label>{article?.body?.location}</label> */}
                                    <label>
                                        {article?.body?.location?.length >= 2 ? (
                                            <>
                                                {article?.body?.location[0]}{' '}
                                                <a
                                                    style={{
                                                        fontSize: '13px',
                                                        textDecoration: 'underline',
                                                        color: '#3584a7',
                                                    }}
                                                    href={article?.header?.company_link}
                                                >
                                                    +{article?.body?.location.length - 1} {t('homepage.recommend.more')}
                                                </a>
                                            </>
                                        ) : (
                                            article?.body?.location
                                        )}
                                    </label>
                                </div>
                                {salary !== '' && (
                                    <div className={cx('item')}>
                                        <CircleDollarIcon />
                                        <label>{salary}</label>
                                    </div>
                                )}
                                <div className={cx('item')}>
                                    <CalendarCheckIcon />
                                    <label>{article?.body?.postdate}</label>
                                </div>
                                {experience !== '' && experience !== 0 && (
                                    <div className={cx('item')}>
                                        <ExperienceIcon />
                                        <label>{`${experience} ${t('job.year')}`}</label>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className={cx('footer')}>
                            <div className={cx('item')}>
                                <div className={cx('tags', i18n.languages[0])}>
                                    {article?.body?.tags.map((item, index) => (
                                        <span key={index} className={styles[item.color]}>
                                            <a href={item.url}>{item.text}</a>
                                        </span>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
            </SwiperSlide>
        );
    });

    return (
        <>
            <div className={cx('heading')}>
                <span className={cx('icon')}>
                    <BagIcon color={titleIconColor} />
                </span>
                <span className={cx('text')}>
                    <h3>
                        {sectionData && sectionData['name_' + i18n.languages[0]]}（{sectionData?.jobs.length}）
                    </h3>{' '}
                </span>
                <span className={cx('read-more')}>
                    <a
                        href={
                            sectionData && sectionData.url
                                ? sectionData.url
                                : `${process.env.REACT_APP_BASE_URL}/${i18n.languages[0]}/search?keyword=&searchType=all`
                        }
                        onClick={() => {
                            homepageService.itemClick({
                                section: 'homepage',
                                sub_section: sectionData ? sectionData['name_en'] : '',
                                object_type: 'job',
                                object_value: t('common.read_more'),
                            })
                        }}
                    >
                        <p>
                            {t('common.read_more')}
                            <FontAwesomeIcon icon={faChevronRight} />
                        </p>
                    </a>
                </span>
            </div>
            <div className={cx('wrapper', 'wrapper-alice-carousel-optional-style')}>
                <Swiper
                    onInit={(swiper) => {
                        swiper.params.navigation.prevEl = prevRef.current;
                        swiper.params.navigation.nextEl = nextRef.current;
                        swiper.navigation.init();
                        swiper.navigation.update();
                    }}
                    // spaceBetween={25}
                    breakpoints={{
                        340: {
                            slidesPerView: 1,
                            // spaceBetween: 50,
                        },
                        640: {
                            slidesPerView: 2,
                            // spaceBetween: 50,
                        },
                        930: {
                            slidesPerView: 3,
                            slidesPerGroup: 3,
                            spaceBetween: 20,
                        },
                        1024: {
                            slidesPerView: 3,
                            slidesPerGroup: 3,
                            spaceBetween: 10,
                        },
                        1440: {
                            slidesPerView: 4,
                            slidesPerGroup: 4,
                            spaceBetween: 10,
                        },
                    }}
                    maxBackfaceHiddenSlides={50}
                    onReachBeginning={(swiper) => {
                        swiper.params.navigation.disabledClass = 'swiper-button-disabled';
                        swiper.navigation.update();
                    }}
                    onReachEnd={(swiper) => {
                        swiper.params.navigation.disabledClass = 'swiper-button-disabled';
                        swiper.navigation.update();
                    }}
                    scrollbar={{ draggable: true }}
                    modules={[Navigation]}
                    grabCursor={true}
                    className="jobSections-articles-carousel"
                >
                    {articleItems}

                    <button ref={prevRef} className="swiper-prev-button" style={{ background: 'transparent' }}>
                        <SlidePrevArrowIcon />
                    </button>

                    <button ref={nextRef} className="swiper-next-button" style={{ background: 'transparent' }}>
                        <SlideNextArrowIcon />
                    </button>
                </Swiper>
            </div>
        </>
    );
}

export default _JobSection;
