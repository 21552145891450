import { useEffect, useLayoutEffect, useState } from 'react';
import './css/bootstrap.min.css';
import './css/owl.carousel.min.css';
import './css/owl.theme.default.min.css';
import './css/style.css';
import './css/responsive.css';
import * as projectSupportStationService from '~/services/projectSupportStationService';
import { useTranslation } from 'react-i18next';
import Header from './Header';
import Footer from './Footer';
import routes from '~/config/routes';
import { useWindowDimensions } from '~/hooks';

function ProjectSupportStation() {
    const { t, i18n } = useTranslation();
    const REACT_APP_PREFIX = process.env.REACT_APP_PREFIX;

    // const { width } = useWindowDimensions();

    const _addScript = (source) => {
        let scripts = document.getElementsByTagName('script');
        for (var i = scripts.length; i--; ) {
            if (
                scripts[i].src == `http://${window.location.host}${source}` ||
                scripts[i].src == `https://${window.location.host}${source}`
            )
                return;
        }

        const script = document.createElement('script');

        script.src = process.env.PUBLIC_URL + source;
        script.async = true;

        document.body.appendChild(script);
    };

    const [data, setData] = useState(null);
    const [optionIndex, setOptionIndex] = useState(0);
    const [menu, setMenu] = useState([]);
    const [iconFooter, setIconFooter] = useState([]);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    // windowHeight
    useLayoutEffect(() => {
        function handleResize() {
            setWidth(window.innerWidth);
            setHeight(window.innerHeight);
        }
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [width, height]);

    // const menu = [
    //     {
    //         title: t('projectSupportStation.professionalSupportStation'),
    //         href: '#introduction',
    //     },
    //     {
    //         title: t('projectSupportStation.options'),
    //         href: '#option',
    //     },
    //     {
    //         title: t('projectSupportStation.threeMajorBenefitsChoosing'),
    //         href: '#benefits',
    //     },
    //     {
    //         title: t('projectSupportStation.contactUs'),
    //         href: 'https://sys.giggik.com/index.php?c=Form&source_id=13',
    //         newTab: true,
    //     },
    //     {
    //         title: t('projectSupportStation.commonProblem'),
    //         href: '#faq',
    //     },
    // ];

    const logo = {
        image: require('~/assets/images/projectsupportstation/images/support-station.png'),
        content: [
            {
                title: t('logoName.home'),
                href: `${REACT_APP_PREFIX != '' ? `/${REACT_APP_PREFIX}` : ''}/${i18n.language}${routes.home}`,
                image: require('~/assets/images/projectsupportstation/images/home.png'),
            },
            {
                title: t('logoName.littleRedBook'),
                image: require('~/assets/images/projectsupportstation/images/xiao-hong-shu.png'),
                href: `${REACT_APP_PREFIX != '' ? `/${REACT_APP_PREFIX}` : ''}/${i18n.language}${routes.xiaoHongShu}`,
            },
            {
                title: t('logoName.talentStation'),
                image: require('~/assets/images/projectsupportstation/images/human-resource.png'),
                href: `${REACT_APP_PREFIX != '' ? `/${REACT_APP_PREFIX}` : ''}/${i18n.language}${
                    routes.humanResourceStation
                }`,
            },
            {
                title: t('logoName.budFund'),
                image: require('~/assets/images/projectsupportstation/images/bud.png'),
                href: `${REACT_APP_PREFIX != '' ? `/${REACT_APP_PREFIX}` : ''}/${i18n.language}${routes.bud}`,
            },
        ],
    };

    _addScript('/js/owl-carousel/owl.carousel.min.js');

    useEffect(() => {
        if (data) {
            _addScript('/js/custom.js');
            _addScript('/js/bootstrap.bundle.min.js');
        }
    }, [data]);

    useEffect(() => {
        projectSupportStationService.getData({ language: i18n.language }).then((res) => {
            setData(res);
            const menu = res?.menu
                ?.sort((a, b) => {
                    return a?.ordering - b?.ordering;
                })
                ?.map((value) => {
                    return {
                        title: value?.menu_text,
                        href: `#project-support-station-${value?.ordering}`,
                        name: value?.section_name,
                        id: `project-support-station-${value?.ordering}`,
                    };
                });

            setMenu(menu);

            const iconFooter = res?.footer?.map((value) => {
                let image = require('~/assets/images/projectsupportstation/images/whatsapp.png');
                if (value?.name?.toLocaleUpperCase() == 'WhatApps'?.toLocaleUpperCase()) {
                    image = require('~/assets/images/projectsupportstation/images/whatsapp.png');
                }
                if (value?.name?.toLocaleUpperCase() == 'Facebook'?.toLocaleUpperCase()) {
                    image = require('~/assets/images/projectsupportstation/images/facebook.png');
                }
                if (value?.name?.toLocaleUpperCase() == 'Instagram'?.toLocaleUpperCase()) {
                    image = require('~/assets/images/projectsupportstation/images/instagram.png');
                }
                if (value?.name?.toLocaleUpperCase() == 'Linkedin'?.toLocaleUpperCase()) {
                    image = require('~/assets/images/projectsupportstation/images/linkedin.png');
                }

                return {
                    icon: image,
                    href: value?.url,
                    title: value?.name,
                };
            });

            setIconFooter(iconFooter);
        });
    }, [i18n]);

    return (
        <>
            <div id="wrapper">
                <Header menu={menu} logo={logo} page={routes.projectSupportStation} />

                <div className="banner">
                    <div className="container-fluid wave wave_banner spacing-0">
                        <div className="row container-fluid spacing-0">
                            <div className="col-12 spacing-0">
                                <div className="banner-items owl-carousel owl-theme container-fluid spacing-0">
                                    {data?.banner?.map((value, index) => {
                                        return (
                                            <div
                                                className={`item spacing-0 ${
                                                    value.media_type == 'image' ? '' : 'video'
                                                }`}
                                                key={String(index)}
                                            >
                                                {value.media_type == 'image' ? (
                                                    <img src={value?.url} alt={value?.title} />
                                                ) : (
                                                    <video
                                                        className="videoContent"
                                                        width="100%"
                                                        height="100%"
                                                        style={{
                                                            position: 'absolute',
                                                            top: 0,
                                                            left: 0,
                                                            objectFit: 'cover',
                                                        }}
                                                        // autoPlay={index == 0 ? true : false}
                                                        // playsInline
                                                        // muted="muted"
                                                        controls
                                                    >
                                                        <source src={value?.url} type="video/mp4" />
                                                        Your browser does not support the video tag.
                                                    </video>
                                                )}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <section className={`about bg-cream`} id={menu[0]?.id}>
                    <div className="container intro-content mt-5">
                        <div className="row align-items-center mt-5">
                            <div className="col-md-8 content-center">
                                <h1 className="mb-4 color-red">{menu[0]?.name}</h1>
                                <p className="bold-500">{data?.introduction[0]?.content}</p>
                            </div>
                            <div className="col-md-4 text-center ps-5">
                                <img src={data?.introduction[0]?.image} alt={data?.introduction[0]?.title} />
                            </div>
                        </div>
                    </div>
                </section>

                <section className="service" id={menu[1]?.id}>
                    <div className="container-fluid wave wave_top ps-5 pe-5">
                        {/* <div className="row"> */}
                        <div className="col-12 text-center">
                            <h1 className="mb-4 color-red">{menu[1]?.name}</h1>
                        </div>
                        {/* </div> */}
                        <div className="row container">
                            <div className="col-12">
                                <nav>
                                    <div
                                        className="nav nav-tabs justify-content-center mb-3"
                                        id="nav-tab"
                                        role="tablist"
                                    >
                                        {data?.option?.map((value, index) => {
                                            return (
                                                // <button
                                                //     key={String(index)}
                                                //     className={`nav-link ${optionIndex == index && 'active'}`}
                                                //     id={`nav-tab-${index}`}
                                                //     type="button"
                                                //     role="tab"
                                                //     onClick={setOptionIndex?.bind(this, index)}
                                                // >
                                                //     {value?.category}
                                                // </button>

                                                <button
                                                    className={`nav-link ${0 == index && 'active'}`}
                                                    id={`nav-tab-${index}`}
                                                    data-bs-toggle="tab"
                                                    data-bs-target={`#nav-${index}`}
                                                    type="button"
                                                    role="tab"
                                                    aria-controls={`nav-${index}`}
                                                    aria-selected={0 == index ? 'true' : 'false'}
                                                    key={String(index)}
                                                >
                                                    {value?.category}
                                                </button>
                                            );
                                        })}
                                    </div>
                                </nav>
                                <div className="tab-content mt-5" id="nav-tabContent">
                                    {data?.option.map((value, index) => {
                                        return (
                                            <div
                                                className={`tab-pane fade ${index == 0 ? 'active show' : ''}`}
                                                key={String(index)}
                                                id={`nav-${index}`}
                                                role="tabpanel"
                                                aria-labelledby={`nav-tab-${index}`}
                                            >
                                                <div className="row">
                                                    <div className="col-lg-6 mb-5">
                                                        <ul className="check-circle">
                                                            {value?.content?.map((content, iContent) => {
                                                                return (
                                                                    <li
                                                                        key={String(iContent)}
                                                                        className={`${content?.color} mb-5 bold-500`}
                                                                        dangerouslySetInnerHTML={{
                                                                            __html: content?.content,
                                                                        }}
                                                                    ></li>
                                                                );
                                                            })}
                                                        </ul>
                                                        <div>
                                                            <a
                                                                href={`${
                                                                    REACT_APP_PREFIX != '' ? `/${REACT_APP_PREFIX}` : ''
                                                                }/${i18n.language}${routes.xiaoHongShu}`}
                                                                className="btn btn-default mb-3 me-3"
                                                            >
                                                                {t('projectSupportStation.littleRedbook')}
                                                            </a>
                                                            <a
                                                                href={`${
                                                                    REACT_APP_PREFIX != '' ? `/${REACT_APP_PREFIX}` : ''
                                                                }/${i18n.language}${routes.bud}`}
                                                                className="btn btn-default mb-3"
                                                            >
                                                                {t('projectSupportStation.BUD')}
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 text-center">
                                                        <img src={value?.image} alt={value?.category} />
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="benefits bg-cream" id={menu[2]?.id}>
                    <div className="container-fluid wave wave_top_cream">
                        {/* <div className="row"> */}
                        <div className="col-12 text-center">
                            <h1 className="mb-4 color-red">{menu[2]?.name}</h1>
                        </div>
                        {/* </div> */}
                        <div className="container row">
                            {data?.advantages?.map((value, index) => {
                                return (
                                    <div className="col-md-4" key={String(index)}>
                                        <div className="item">
                                            <img src={value?.image} alt={value?.title} />
                                            <strong className="color-orange d-block mb-3 mt-2">{value?.title}</strong>
                                            <ul className="check-circle">
                                                {value?.content?.map((item, iItem) => {
                                                    return (
                                                        <li
                                                            key={String(iItem)}
                                                            className={`${item?.color} mb-3 bold-500`}
                                                            dangerouslySetInnerHTML={{
                                                                __html: item?.content,
                                                            }}
                                                        >
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </section>

                <section className="partial-contact bg-cover py-5" id={menu[3]?.id}>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12 text-center">
                                <a
                                    href="http://sys.giggik.com/index.php?c=Form&source_id=13"
                                    target="_blank"
                                    className={`btn btn-default btn-large ${width < 600 ? 'mb-2' : ''}`}
                                >
                                    {t('projectSupportStation.contactUs')}
                                </a>
                                <img
                                    src={require('~/assets/images/projectsupportstation/images/Gig_Gik.png')}
                                    className="Gig_Gik"
                                />
                            </div>
                        </div>
                    </div>
                </section>

                <section className="faq" id={menu[4]?.id}>
                    <div className="container-fluid wave wave_bottom">
                        <div className="container content-faq">
                            {/* <div className="row"> */}
                            <div className="col-12 text-center">
                                <h1 className="mb-4 color-red">{menu[4]?.name}</h1>
                            </div>
                            {/* </div> */}
                            <div className="row">
                                <div className="col-12">
                                    <div className="accordion accordion-flush" id="accordionFlushExample">
                                        {data?.faq?.map((value, index) => {
                                            return (
                                                <div className="accordion-item" key={String(index)}>
                                                    <div className="accordion-header" style={{ marginBottom: '30px' }}>
                                                        <button
                                                            className="accordion-button collapsed"
                                                            type="button"
                                                            data-bs-toggle="collapse"
                                                            data-bs-target={`#flush-collapse${index}`}
                                                            aria-expanded="true"
                                                            aria-controls={`flush-collapse${index}`}
                                                        >
                                                            <img
                                                                src={require('~/assets/images/projectsupportstation/images/Gig_Gik_question.png')}
                                                                className="icon"
                                                            />
                                                            {value?.category}
                                                        </button>
                                                    </div>

                                                    <div
                                                        id={`flush-collapse${index}`}
                                                        className="accordion-collapse collapse ps-5"
                                                        data-bs-parent="#accordionFlushExample"
                                                    >
                                                        {value?.question?.map((question, iQuestion) => {
                                                            return (
                                                                <div
                                                                    key={String(iQuestion)}
                                                                    style={{ marginBottom: '30px' }}
                                                                >
                                                                    <button
                                                                        className="accordion-button spacing-0"
                                                                        type="button"
                                                                        data-bs-toggle="collapse"
                                                                        data-bs-target={`#flush-sub-collapse${index}${iQuestion}`}
                                                                        aria-expanded="false"
                                                                        aria-controls={`flush-sub-collapse${index}${iQuestion}`}
                                                                    >
                                                                        {/* <img
                                                                        src={require('~/assets/images/projectsupportstation/images/Gig_Gik_question.png')}
                                                                        className="icon"
                                                                    /> */}
                                                                        {question?.question}
                                                                    </button>
                                                                    <div
                                                                        id={`flush-sub-collapse${index}${iQuestion}`}
                                                                        className="accordion-collapse collapse"
                                                                        data-bs-parent={`#flush-collapse${index}`}
                                                                    >
                                                                        <div className="accordion-body">
                                                                            {question?.answer}
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 row mt-3 align-items-center">
                                                                        <div className="col-1 border-bottom-red-3"></div>
                                                                        <div className="col-11 border-bottom"></div>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <Footer logo={data?.sticky_logo[0]} iconFooter={iconFooter} />
            </div>
        </>
    );
}

export default ProjectSupportStation;
