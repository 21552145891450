import classNames from 'classnames/bind';
import { useRef, useState, useEffect, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
	faChevronDown,
	faChevronUp,
	faCircleQuestion,
	faCoins,
	faEarthAsia,
	faGear,
	faKeyboard,
	faSignOut,
	faUser,
} from '@fortawesome/free-solid-svg-icons';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import config from '~/config';
import Button from '~/components/Button';
import styles from './Header.module.scss';
import images from '~/assets/images';
// import Menu from '~/components/Popper/Menu';
import Menu from '~/components/NavMenu/Menu';
import { open } from '~/reducers/popupReducer';
import { toggleChatroom } from '~/reducers/chatroomReducer';
import {
	MenuExpandIcon,
	ToggleMenuHeaderButtonIcon,
	MbMenuHomeIcon,
	MbToggleMenuChecklistIcon,
	MbToggleMenuSettingsIcon,
} from '~/components/Icons';
import { useTranslation } from 'react-i18next';

import { useNavigate } from 'react-router-dom';
import Collapse from '~/components/Collapse';
import { logout } from '~/reducers/authReducer';

import Filter from '../Filter';
import { HeaderContext } from '~/ThemeContext';
import CookiesPopUp from '../CookiesPopUp/CookiesPopUp';

const cx = classNames.bind(styles);

const MENU_ITEMS = [
	{
		icon: <FontAwesomeIcon icon={faEarthAsia} />,
		title: 'English',
		children: {
			title: 'Language',
			data: [
				{
					type: 'language',
					code: 'en',
					title: 'English',
					// onClick: () => handleChangeLanguage('en'),
				},
				{
					type: 'language',
					code: 'vi',
					title: 'Chinese',
					// onClick: () => handleChangeLanguage('zh'),
				},
			],
		},
	},
	{
		icon: <FontAwesomeIcon icon={faCircleQuestion} />,
		title: 'Feedback and help',
		to: '/feedback',
	},
	{
		icon: <FontAwesomeIcon icon={faKeyboard} />,
		title: 'Keyboard shortcuts',
	},
];

function Header() {
	let navigate = useNavigate();
	const dispatch = useDispatch();
	const { t, i18n } = useTranslation();
	const [currentLang, setCurrentLang] = useState(i18n.languages[0]);
	const [showMobileMenu, setShowMobileMenu] = useState(false);
	const [isDropdownLanguageBtn, setIsDropdownLanguageBtn] = useState(false);
	const [showUserSubmenu, setShowUserSubmenu] = useState(false);
	const submenuRef = useRef(null);
	const [showMobileUserSubmenu, setShowMobileUserSubmenu] = useState(false);
	const mobile_submenuRef = useRef(null);
	//
	const [isDropdown_showAllCareer_mobile, setIsDropdown_showAllCareer_mobile] = useState(false);
	const auth = useSelector((state) => state.auth);
	const dropdown = useSelector((state) => state.dropdown);

	const firstTenIndustries = dropdown?.industries?.slice(0, 9) || [];

	const location = useLocation();
	const { pathname } = location;

	function handleChangeLanguage(lang) {
		setCurrentLang(lang);
		i18n.changeLanguage(lang);
		navigate('/' + lang, { replace: true });
	}
	function handleChangeLanguageMobile(lang) {
		setCurrentLang(lang);
		i18n.changeLanguage(lang);
		navigate('/' + lang, { replace: true });
		setIsDropdownLanguageBtn(!isDropdownLanguageBtn);
	}
	function handleToggleItems(lang) {
		setIsDropdown_showAllCareer_mobile(!isDropdown_showAllCareer_mobile);
	}

	// Handle logic
	const handleMenuChange = (menuItem) => {
		switch (menuItem.type) {
			case 'language':
				// Handle change language
				break;
			default:
		}
	};

	function handleToggleMobileMenu() {
		// console.log('click toggle menu');
		setShowMobileMenu(!showMobileMenu);
	}
	function handleToggleLanguageSelect() {
		// console.log('click toggle language');
		setIsDropdownLanguageBtn(!isDropdownLanguageBtn);
	}

	const userMenu = [
		{
			icon: <FontAwesomeIcon icon={faUser} />,
			title: 'View profile',
			to: '/@hoaa',
		},
		{
			icon: <FontAwesomeIcon icon={faCoins} />,
			title: 'Get coins',
			to: '/coin',
		},
		{
			icon: <FontAwesomeIcon icon={faGear} />,
			title: 'Settings',
			to: '/settings',
		},
		...MENU_ITEMS,
		{
			icon: <FontAwesomeIcon icon={faSignOut} />,
			title: 'Log out',
			to: '/logout',
			separate: true,
		},
	];
	const navMenuItems = [
		{
			title: t('header.profile_menu'),
			mobileIcon: 'MbToggleMenuFlagIcon',
			href: process.env.REACT_APP_BASE_URL + '/' + i18n.languages[0] + '/companies',
			badge: {
				title: t('header.profile_menu_badge'),
				tag: 'green',
			},
		},
		{
			title: t('header.guide_menu'),
			mobileIcon: 'MbToggleMenuBookIcon',
			href: `${process.env.REACT_APP_BASE_URL}/${i18n.languages[0]}/gikpedia`,
		},
		{
			title: t('header.help_menu'),
			mobileIcon: 'MbToggleMenuPeopleIcon',
			href: 'https://bit.ly/職極GigGik_Whatsapp_group',
		},
		{
			title: t('header.about_menu'),
			mobileIcon: 'MbToggleMenuCvIcon',
			href: 'https://docs.google.com/forms/d/1rXDO4sT0REXCaK44765iCY88ip1ziG-YFFvAhgo5cTA/viewform?edit_requested=true',
			badge: {
				title: t('header.about_menu_badge'),
				tag: 'yellow',
			},
		},
	];

	const mobileNavMenuItems = [
		...navMenuItems,
		{
			title: t('header.slash'),
			mobileIcon: 'MbToggleMenuSlashIcon',
			href: `${process.env.REACT_APP_NEWGIG_URL}/${i18n.languages[0]}`,
		},
	];

	useEffect(() => {
		if (!auth.loggedIn && showUserSubmenu) {
			setShowUserSubmenu(false);
			setShowMobileUserSubmenu(false);
		}
	}, [auth.loggedIn]);

	useEffect(() => {
		function handleClickOutside(event) {
			if (mobile_submenuRef.current && !mobile_submenuRef.current.contains(event.target)) {
				setShowMobileUserSubmenu(false);
			}
		}
		// Bind the event listener
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [mobile_submenuRef]);

	useEffect(() => {
		function handleClickOutside(event) {
			if (submenuRef.current && !submenuRef.current.contains(event.target)) {
				setShowUserSubmenu(false);
			}
		}
		// Bind the event listener
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [submenuRef]);

	const NavLink = useCallback(({ href, title, marginRight = false }) => {
		return (
			<a href={href} style={{ marginRight }}>
				<span style={{ whiteSpace: "nowrap", fontFamily: i18n.language === "en" && "Roboto", fontWeight: 500, fontSize: i18n.language === "en" ? "14px" : "15px" }}>
					{title}
				</span>
			</a>
		)
	}, [])

	const Badge = useCallback(({ backgroundColor, title, marginRight = false }) => {
		return (
			<div style={{ paddingBottom: "10px", marginRight }}>
				<div style={{ display: "grid", placeItems: "center", backgroundColor, padding: "0 7px", borderRadius: "10px", height: "20px" }}>
					<span style={{ color: "white", fontSize: "1.1rem", fontWeight: 600, textShadow: "0px 1px 1px rgb(0 0 0 / 25%)", textAlign: "center" }}>
						{title}
					</span>
				</div>
			</div>
		)
	}, [])

	const LeftSideNavLinks = useCallback(() => {
		return (
			<div style={{ display: "flex", alignItems: "center" }}>
				<NavLink href={`${process.env.REACT_APP_BASE_URL}/${i18n.languages[0]}/companies`} title={t('header.profile_menu')} marginRight="5px" />
				<Badge title={t('header.profile_menu_badge')} backgroundColor="#63f1be" marginRight={i18n.languages === "en" ? "13px" : "16px"} />
				<NavLink href={`${process.env.REACT_APP_BASE_URL}/${i18n.languages[0]}/gikpedia`} title={t('header.guide_menu')} marginRight={i18n.language === "en" ? "13px" : "16px"} />
				<NavLink href="https://bit.ly/職極GigGik_Whatsapp_group" title={t('header.help_menu')} marginRight={i18n.language === "en" ? "13px" : "16px"} />
				<NavLink href="https://docs.google.com/forms/d/1rXDO4sT0REXCaK44765iCY88ip1ziG-YFFvAhgo5cTA/viewform?edit_requested=true" title={t('header.about_menu')} marginRight="5px" />
				<Badge title={t('header.about_menu_badge')} backgroundColor="#ffc700" marginRight={0} />
			</div>
		)
	}, [])

	const FindSlashButton = useCallback(() => {
		return (
			<a href={`${process.env.REACT_APP_NEWGIG_URL}/${i18n.languages[0]}`}>
				<span style={{ whiteSpace: "nowrap", color: "#36a9da", fontFamily: i18n.language === "en" && "Roboto", fontSize: i18n.language === "en" ? "14px" : "15px", fontWeight: i18n.language === "en" ? 500 : 700 }}>
					{t('header.search')}
				</span>
			</a>
		)
	}, [])

	const NavIconButton = useCallback(({ iconSource, alt, wordings, path, onClick, lastChild = false }) => {
		return (
			<a
				href={path ? `${process.env.REACT_APP_BASE_URL}/${i18n.languages[0]}/${path}` : "#"}
				onClick={onClick}
				style={{ marginRight: !lastChild && "18px", display: "flex", alignItems: "center", paddingTop: "3px" }}
			>
				<img src={iconSource} alt={alt} style={{ marginRight: "10px" }} />
				<span style={{ whiteSpace: "nowrap", fontFamily: i18n.language === "en" && "Roboto", fontWeight: 500, fontSize: i18n.language === "en" ? "14px" : "15px" }}>
					{t(wordings)}
				</span>
			</a>
		)
	}, [])

	const RightSideNavButtons = useCallback(() => {
		return (
			<>
				<NavIconButton
					path="savedjobs"
					iconSource={images.header.saved}
					alt="saved-logo"
					wordings="header.saved_job"
				/>
				<NavIconButton
					onClick={() => dispatch(toggleChatroom())}
					iconSource={images.header.chatroom}
					alt="chat-logo"
					wordings="header.chatroom"
				/>
				<NavIconButton
					path="notifications"
					iconSource={images.header.noti}
					alt="noti-logo"
					wordings="header.notification"
					lastChild={true}
				/>
			</>
		)
	}, [])

	const ProfileAvatarContainer = useCallback(() => {
		return (
			<>
				<div>
					{auth.profile_image ? (
						<img
							className={`user-avatar ${i18n.languages[0]}`}
							src={window.createImagePath(`/api/storage/user/profileImage/${auth.profile_image}`)}
						/>
					) : (
						<div className={cx(i18n.languages[0])} style={{ backgroundColor: "#f47602", display: "grid", placeItems: "center", width: "32px", aspectRatio: 1, borderRadius: "50%" }}>
							<span style={{ textAlign: "center", color: "white" }}>
								{auth.first_name ? auth.first_name.split('')[0] : ''}{' '}
							</span>
						</div>
					)}
				</div>
				<img
					className={cx('ml-2', 'chevron')}
					style={{ marginLeft: "10px" }}
					src={showUserSubmenu ? images.header.chevronUp : images.header.chevronDown}
				/>
			</>
		)
	}, [showUserSubmenu, auth])

	return (
		<>
			<CookiesPopUp />
			<header
				className={cx('wrapper', 'header-module')}
			>
				<div className={cx('control-lang', 'header-control-lang')}>
					<div className="container __flex">
						<div style={{ height: "100%", display: "flex" }} className={cx('desktop-only')}>
							<div style={{ height: "100%", display: "flex", alignItems: "center", maxWidth: "1350px" }}>
								<ul>
									<li>
										<a
											className={currentLang && currentLang === 'sc' ? styles['active'] : undefined}
											onClick={() => handleChangeLanguage('sc')}
										>
											简
										</a>
									</li>
									<li>
										<a
											className={currentLang && currentLang === 'tc' ? styles['active'] : undefined}
											onClick={() => handleChangeLanguage('tc')}
										>
											繁
										</a>
									</li>
									<li>
										<a
											className={currentLang && currentLang === 'en' ? styles['active'] : undefined}
											onClick={() => handleChangeLanguage('en')}
										>
											ENG
										</a>
									</li>
								</ul>
							</div>
						</div>
						<div className={cx('home-button', 'mobile-only')}>
							<Link to={config.routes.home}>
								<MbMenuHomeIcon />
							</Link>
						</div>
						<div className={cx('mobile-control-lang', 'mobile-only')}>
							<div className={isDropdownLanguageBtn ? cx('dropdown', 'open') : cx('dropdown')}>
								<div onClick={() => handleToggleLanguageSelect()} className={cx('header')}>
									{currentLang && currentLang === 'en' ? (
										<span>ENG</span>
									) : currentLang === 'tc' ? (
										<span>繁體</span>
									) : (
										<span>簡體</span>
									)}

									<button className={cx('icon')} onClick={() => handleToggleLanguageSelect()}>
										{isDropdownLanguageBtn === true ? (
											<FontAwesomeIcon icon={faChevronUp} />
										) : (
											<FontAwesomeIcon icon={faChevronDown} />
										)}
									</button>
								</div>
								{isDropdownLanguageBtn === true && (
									<div className={cx('body')}>
										{['tc', 'sc', 'en'].map((lang, index) => (
											<a
												className={currentLang === lang ? cx('active') : undefined}
												key={index}
												onClick={() => handleChangeLanguageMobile(lang)}
											>
												{lang === 'en' ? 'ENG' : lang === 'tc' ? '繁體' : '簡體'}
											</a>
										))}
									</div>
								)}
							</div>
						</div>
					</div>
					{auth.loggedIn && (
						<div className={cx('loggedIn-btn', 'mobile-only')}>
							<a
								href={`${process.env.REACT_APP_BASE_URL}/${i18n.languages[0]}/notifications`}
								className={cx('noti-btn')}
							>
								<img src={images.header.notiWhite} alt="noti-logo" />
							</a>

							<a href="#" className={cx('chat-btn')} onClick={() => dispatch(toggleChatroom())}>
								<img src={images.header.chatroomWhite} alt="chat-logo" />
							</a>
							<a
								className={cx('saved-btn')}
								href={`${process.env.REACT_APP_BASE_URL}/${i18n.languages[0]}/savedjobs`}
							>
								<img src={images.header.savedWhite} alt="saved-logo" />
							</a>
						</div>
					)}
				</div>

				{/* jonathan renew lower white navbar for large screens */}
				<div style={{ padding: "15px 35px", backgroundColor: "white", width: "100%", display: "grid", placeItems: "center" }} className={cx('desktop-only')}>
					{/* left side */}
					<div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", maxWidth: "1375px", width: "100%" }}>
						<div style={{ display: "flex", alignItems: "center" }}>
							<div style={{ marginRight: "15px" }}>
								<Link to={config.routes.home} className={cx('logo-link')}>
									<img src={i18n.language === 'en' ? images.logoIconEn : images.logoIcon} alt="gikgik-logo" />
								</Link>
							</div>
							<div style={{}}>
								<LeftSideNavLinks />
							</div>
						</div>
						{/* right side */}
						<div style={{ display: "flex", alignItems: "center", height: "100%" }}>
							{auth.loggedIn && (
								<div style={{ display: "flex", alignItems: "center" }}>
									<div style={{ marginRight: "30px", display: "grid", placeItems: "center" }}>
										<FindSlashButton />
									</div>
									<div style={{ display: "flex", alignItems: "center" }}>
										<RightSideNavButtons />
									</div>
									<div
										style={{ display: "flex", alignItems: "center", marginLeft: "40px", cursor: "pointer", position: "relative" }}
										onClick={() => setShowUserSubmenu(!showUserSubmenu)}
									>
										<ProfileAvatarContainer />
										{/* Profile dropdown menu start */}
										{showUserSubmenu && (
											<div className={cx('user-submenu')}>
												<div className={cx('menu')}>
													<ul>
														<li>
															<a href={`${process.env.REACT_APP_BASE_URL}/${i18n.languages[0]}/profile`}>
																<div className={cx('size-15')}>
																	<img src={images.header.userProfile} />
																</div>
																{t('header.user_profile')}
															</a>
														</li>
														<li>
															<a href={`${process.env.REACT_APP_BASE_URL}/${i18n.languages[0]}/cvmanagement`}>
																<div className={cx('size-15')}>
																	<img src={images.header.cvMgt} />
																</div>
																{t('header.cv_mgt')}
															</a>
														</li>
														<li>
															<a href={`${process.env.REACT_APP_BASE_URL}/${i18n.languages[0]}/account`}>
																<div className={cx('size-15')}>
																	<img src={images.header.accSetting} />
																</div>
																{t('header.acc_setting')}
															</a>
														</li>
														<li>
															<a href={`${process.env.REACT_APP_RECRUITER_URL}/${i18n.languages[0]}/login`}>
																<div className={cx('size-15')}>
																	<img src={images.header.accVerify} />
																</div>
																{t('header.acc_verify')}
															</a>
														</li>
													</ul>
												</div>
												<div className={cx('logout-btn')} onClick={() => dispatch(logout())}>
													{t('header.logout')}
												</div>
											</div>
										)}
										{/* Profile dropdown menu end */}
									</div>
								</div>
							)}
							{!auth.loggedIn && (
								<div>
									<Button
										className={'btn-no-padding'}
										href={`${process.env.REACT_APP_NEWGIG_URL}/${i18n.languages[0]}`}
										text
									>
										{t('header.search')}
									</Button>
									<Button
										className={'user-menu-btn'}
										href={`${process.env.REACT_APP_RECRUITER_URL}/${i18n.languages[0]}`}
										text
										rounded
										primary
										primaryDark
									>
										{t('header.invite_people')}
									</Button>
									<Button
										className={'user-menu-btn'}
										onClick={() => dispatch(open('signup'))}
										rounded
										outline
									>
										{t('header.sign_up')}
									</Button>
									<Button
										className={'user-menu-btn'}
										onClick={() => dispatch(open('login'))}
										rounded
										outline
									>
										{t('header.login')}
									</Button>
								</div>
							)}
						</div>
					</div>
				</div>

				<div style={{ display: "flex", justifyContent: "space-between", width: "100%", padding: "10px 20px", position: "relative" }} className={cx('mobile-only')}>
					<div style={{ display: "grid", placeItems: "center", marginRight: "30px" }} >
						<button onClick={() => handleToggleMobileMenu()} style={{ display: "grid", placeItems: "center", cursor: "pointer" }} className={cx('hamburger-icon-button')}>
							<MenuExpandIcon />
						</button>
					</div>
					<div style={{ height: "50px", display: "grid", placeItems: "center" }} className={cx('mobile-nav-logo')}>
						<div style={{ width: "135px" }}>
							<Link to={config.routes.home}>
								{/* <img src={images.logoIcon} alt="logo-icon-mobile" /> */}
								<img
									src={i18n.language === 'en' ? images.logoIconEn : images.logoIcon}
									alt="logo-icon-mobile"
									style={{ maxWidth: "100%" }}
								/>
							</Link>
						</div>
					</div>
					{auth.loggedIn ? (
						<div className={cx('loggedIn-btn')} style={{}}>
							<div
								className={cx('user-btn')}
								onClick={() => setShowMobileUserSubmenu(!showMobileUserSubmenu)}
								ref={mobile_submenuRef}
							>
								{' '}
								{/* toggle the profile menu */}
								{auth.profile_image ? (
									<img
										className={`user-avatar ${i18n.languages[0]}`}
										src={window.createImagePath(
											'/api/storage/user/profileImage/' + auth.profile_image,
										)}
									/>
								) : (
									<div className={cx('user-avatar', 'name', i18n.languages[0])}>
										{auth.first_name ? auth.first_name.split('')[0] : ''}{' '}
									</div>
								)}
								<img
									className={cx('ml-2', 'chevron')}
									src={showUserSubmenu ? images.header.chevronUp : images.header.chevronDown}
								/>
								{showMobileUserSubmenu && (
									<div className={cx('user-submenu')}>
										<div className={cx('menu')}>
											<ul>
												<li>
													<a href={`${process.env.REACT_APP_BASE_URL}/${i18n.languages[0]}/profile`}>
														<div className={cx('size-15')}>
															<img src={images.header.userProfile} />
														</div>
														{t('header.user_profile')}
													</a>
												</li>
												<li>
													<a href={`${process.env.REACT_APP_BASE_URL}/${i18n.languages[0]}/cvmanagement`}>
														<div className={cx('size-15')}>
															<img src={images.header.cvMgt} />
														</div>
														{t('header.cv_mgt')}
													</a>
												</li>
												<li>
													<a href={`${process.env.REACT_APP_BASE_URL}/${i18n.languages[0]}/account`}>
														<div className={cx('size-15')}>
															<img src={images.header.accSetting} />
														</div>
														{t('header.acc_setting')}
													</a>
												</li>
												<li>
													<a href={`${process.env.REACT_APP_RECRUITER_URL}/${i18n.languages[0]}/login`}>
														<div className={cx('size-15')}>
															<img src={images.header.accVerify} />
														</div>
														{t('header.acc_verify')}
													</a>
												</li>
												<li>
													<a href={`${process.env.REACT_APP_NEWGIG_URL}/${i18n.languages[0]}`}>
														<div className={cx('size-15')}>
															<img src={images.header.beSlasher} />
														</div>
														{t('header.doSlash')}
													</a>
												</li>
											</ul>
										</div>
										<div className={cx('logout-btn')} onClick={() => dispatch(logout())}>
											{t('header.logout')}
										</div>
									</div>
								)}
							</div>
						</div>
					) : (
						<Button
							rounded
							primary
							primaryDark
							small
							href={`${process.env.REACT_APP_RECRUITER_URL}/${i18n.languages[0]}`}
						>
							{t('header.invite_people')}{' '}
						</Button>
					)}

				</div>

				{/* <div className={cx('inner', 'header-inner')}> */}
				{/* <div className="container only-lg">
						<div className={cx('inner-wrapper')}>
							<Link to={config.routes.home} className={cx('logo-link')}>
								<img src={i18n.languages[0] === '/en' ? images.logoIconEn : images.logoIcon} alt="gikgik-logo" />
							</Link>

							<Menu items={navMenuItems} isShowIcon={false} />

							<div className={i18n.languages[0] === '/en' ? cx('actions', 'eng') : cx('actions')}>
								{auth.loggedIn ? (
									<div className={cx('loggedIn-btn')}>
										<Button
											className={cx('newgig-btn')}
											href={`${process.env.REACT_APP_NEWGIG_URL}/${i18n.languages[0]}`}
										>
											{t('header.search')}
										</Button>
										<a
											className={cx('saved-btn')}
											href={`${process.env.REACT_APP_BASE_URL}/${i18n.languages[0]}/savedjobs`}
										>
											<img src={images.header.saved} alt="saved-logo" />
											{t('header.saved_job')}
										</a>
										<a
											className={cx('saved-btn')}
											href="#"
											onClick={() => dispatch(toggleChatroom())}
										>
											<img src={images.header.chatroom} alt="saved-logo" />
											{t('header.chatroom')}
										</a>
										<a
											href={`${process.env.REACT_APP_BASE_URL}/${i18n.languages[0]}/notifications`}
											className={cx('noti-btn')}
										>
											<img src={images.header.noti} alt="noti-logo" />
											<a>{t('header.notification')}</a>
										</a>
										<div
											className={cx('user-btn')}
											onClick={() => setShowUserSubmenu(!showUserSubmenu)}
											ref={submenuRef}
										>
											{auth.profile_image ? (
												<img
													className={`user-avatar ${i18n.languages[0]}`}
													src={window.createImagePath(
														'/api/storage/user/profileImage/' + auth.profile_image,
													)}
												/>
											) : (
												<div className={cx('user-avatar', 'name', i18n.languages[0])}>
													{auth.first_name ? auth.first_name.split('')[0] : ''}{' '}
												</div>
											)}
											<img
												className={cx('ml-2', 'chevron')}
												src={
													showUserSubmenu
														? images.header.chevronUp
														: images.header.chevronDown
												}
											/>
											{showUserSubmenu && (
												<div className={cx('user-submenu')}>
													<div className={cx('menu')}>
														<ul>
															<li>
																<a href={`${process.env.REACT_APP_BASE_URL}/${i18n.languages[0]}/profile`}>
																	<div className={cx('size-15')}>
																		<img src={images.header.userProfile} />
																	</div>
																	{t('header.user_profile')}
																</a>
															</li>
															<li>
																<a href={`${process.env.REACT_APP_BASE_URL}/${i18n.languages[0]}/cvmanagement`}>
																	<div className={cx('size-15')}>
																		<img src={images.header.cvMgt} />
																	</div>
																	{t('header.cv_mgt')}
																</a>
															</li>
															<li>
																<a href={`${process.env.REACT_APP_BASE_URL}/${i18n.languages[0]}/account`}>
																	<div className={cx('size-15')}>
																		<img src={images.header.accSetting} />
																	</div>
																	{t('header.acc_setting')}
																</a>
															</li>
															<li>
																<a href={`${process.env.REACT_APP_RECRUITER_URL}/${i18n.languages[0]}/login`}>
																	<div className={cx('size-15')}>
																		<img src={images.header.accVerify} />
																	</div>
																	{t('header.acc_verify')}
																</a>
															</li>
														</ul>
													</div>
													<div className={cx('logout-btn')} onClick={() => dispatch(logout())}>
														{t('header.logout')}
													</div>
												</div>
											)}
										</div>
									</div>
								) : (
									<div className={cx('inner-btn')}>
										<Button
											className={'btn-no-padding'}
											href={`${process.env.REACT_APP_NEWGIG_URL}/${i18n.languages[0]}`}
											text
										>
											{t('header.search')}
										</Button>
										<Button
											className={'user-menu-btn'}
											href={`${process.env.REACT_APP_RECRUITER_URL}/${i18n.languages[0]}`}
											text
											rounded
											primary
											primaryDark
										>
											{t('header.invite_people')}
										</Button>
										<Button
											className={'user-menu-btn'}
											onClick={() => dispatch(open('signup'))}
											rounded
											outline
										>
											{t('header.sign_up')}
										</Button>
										<Button
											className={'user-menu-btn'}
											onClick={() => dispatch(open('login'))}
											rounded
											outline
										>
											{t('header.login')}
										</Button>
									</div>
								)}
							</div>
						</div>
					</div> */}


				{/* <div className={cx('container', "__m-0", '__w100,', 'only-xs')}>
						<div className={cx('inner-wrapper', '__flex-center __w100')}>
							<div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
								<span className={cx('icon')}>
									<button onClick={() => handleToggleMobileMenu()}>
										<MenuExpandIcon />
									</button>
								</span>
								<span className={cx('logo')}>
									<div>
										<Link to={config.routes.home}>
											<img
												src={i18n.languages[0] === '/en' ? images.logoIconEn : images.logoIcon}
												alt="logo-icon-mobile"
											/>
										</Link>
									</div>
								</span>
								{auth.loggedIn ? (
									<div className={cx('loggedIn-btn')}>
										<div
											className={cx('user-btn')}
											onClick={() => setShowMobileUserSubmenu(!showMobileUserSubmenu)}
											ref={mobile_submenuRef}
										>
											{auth.profile_image ? (
												<img
													className={`user-avatar ${i18n.languages[0]}`}
													src={window.createImagePath(
														'/api/storage/user/profileImage/' + auth.profile_image,
													)}
												/>
											) : (
												<div className={cx('user-avatar', 'name', i18n.languages[0])}>
													{auth.first_name ? auth.first_name.split('')[0] : ''}{' '}
												</div>
											)}
											<img
												className={cx('ml-2', 'chevron')}
												src={showUserSubmenu ? images.header.chevronUp : images.header.chevronDown}
											/>
											{showMobileUserSubmenu && (
												<div className={cx('user-submenu')}>
													<div className={cx('menu')}>
														<ul>
															<li>
																<a
																	href={`${process.env.REACT_APP_BASE_URL}/${i18n.languages[0]}/profile`}
																>
																	<div className={cx('size-15')}>
																		<img src={images.header.userProfile} />
																	</div>
																	{t('header.user_profile')}
																</a>
															</li>
															<li>
																<a
																	href={`${process.env.REACT_APP_BASE_URL}/${i18n.languages[0]}/cvmanagement`}
																>
																	<div className={cx('size-15')}>
																		<img src={images.header.cvMgt} />
																	</div>
																	{t('header.cv_mgt')}
																</a>
															</li>
															<li>
																<a
																	href={`${process.env.REACT_APP_BASE_URL}/${i18n.languages[0]}/account`}
																>
																	<div className={cx('size-15')}>
																		<img src={images.header.accSetting} />
																	</div>
																	{t('header.acc_setting')}
																</a>
															</li>
															<li>
																<a
																	href={`${process.env.REACT_APP_RECRUITER_URL}/${i18n.languages[0]}/login`}
																>
																	<div className={cx('size-15')}>
																		<img src={images.header.accVerify} />
																	</div>
																	{t('header.acc_verify')}
																</a>
															</li>
															<li>
																<a
																	href={`${process.env.REACT_APP_NEWGIG_URL}/${i18n.languages[0]}`}
																>
																	<div className={cx('size-15')}>
																		<img src={images.header.beSlasher} />
																	</div>
																	{t('header.doSlash')}
																</a>
															</li>
														</ul>
													</div>
													<div className={cx('logout-btn')} onClick={() => dispatch(logout())}>
														{t('header.logout')}
													</div>
												</div>
											)}
										</div>
									</div>
								) : (
									<Button
										rounded
										primary
										primaryDark
										small
										href={`${process.env.REACT_APP_RECRUITER_URL}/${i18n.languages[0]}`}
									>
										{t('header.invite_people')}{' '}
									</Button>
								)}
							</div>
						</div>
					</div> */}

				{/* </div> */}

				<div className={cx("mobile-only")}>
					<div className={!!showMobileMenu ? cx('toggle-menu', 'show') : styles['toggle-menu']}>
						<section>
							<div className={cx('toggle-menu__baby-icon')}>
								<img src={images.menuGigGig} alt="toggle-menu__baby-icon" />
							</div>
							<div className={cx('toggle-menu__content')}>
								<div className={cx('header')}>
									<span className={cx('hello')}>
										{t('header.hello')}，{auth.loggedIn ? auth.first_name : ''}
									</span>
									{!auth.loggedIn && (
										<div className={cx('header__button')}>
											<Button rounded small onClick={() => dispatch(open('signup'))}>
												<ToggleMenuHeaderButtonIcon />
												<span className={cx('orange')}>{t('header.sign_up')}</span>
											</Button>
											<Button rounded small onClick={() => dispatch(open('login'))}>
												<ToggleMenuHeaderButtonIcon />
												<span className={cx('blue')}>{t('header.login')}</span>
											</Button>
										</div>
									)}
								</div>
								<div className={cx('body')}>
									<div className={cx('all-careers-group')}>
										<Collapse
											headerText={t('industry.view_more_industries')}
											iconName="MbMenuAllCareerIcon"
											arrowColor="#9A9A9A"
											innerBottomBorder={true}
										>
											<div style={{ margin: '0 0 5px 30px' }} />
											{firstTenIndustries?.map((each) => (
												<a
													key={`first_ten_industries_${each.id}`}
													style={{ margin: '0 0 15px 30px', color: '#37313c', opacity: 0.9 }}
													target="_blank"
													href={`${process.env.REACT_APP_BASE_URL}/${i18n.languages[0]}/search?industries[]=${each.id}`}
												>
													{each.value}
												</a>
											))}
											<a
												style={{
													margin: '0 0 10px 30px',
													color: '#37313c',
													opacity: 0.6,
													lineHeight: '1.6rem',
												}}
												href={`${process.env.REACT_APP_BASE_URL}/${i18n.languages[0]}/search?keyword=&searchType=all`}
											>
												{t('industry.view_more_industries')}
											</a>
										</Collapse>
									</div>
									<div className={cx('recommend-links')}>
										<span className={cx('title')}>
											{t('recommendToYou.vocationalSkillsGuide.recommendations')}
										</span>
										<Menu items={mobileNavMenuItems} isShowIcon={true} />
									</div>
									<div className={cx('usually-links')}>
										<span className={cx('title')}>{t('header.general')}</span>

										<li className={cx('item')}>
											<MbToggleMenuSettingsIcon />
											<a
												className={cx('link')}
												href={`${process.env.REACT_APP_BASE_URL}/${i18n.languages[0]}/account`}
											>
												{t('header.acc_setting')}
											</a>
										</li>
										<li className={cx('item')}>
											<MbToggleMenuChecklistIcon />
											<a
												className={cx('link')}
												href={`${process.env.REACT_APP_RECRUITER_URL}/${i18n.languages[0]}/login`}
											>
												{t('header.acc_verify')}
											</a>
										</li>
									</div>
								</div>
							</div>
						</section>
					</div>
				</div>

			</header>
		</>
	);
}

export default Header;
