import React from 'react';
import classNames from 'classnames/bind';
import styles from './_GigGikStatement.module.scss';
import images from '~/assets/images';

const cx = classNames.bind(styles);

const GigGikStatement = () => {
    return (
        <div className={cx('modal-body')}>
            <h4 className={cx('header')}>慎防WhatsApp/其他通訊軟體偽冒【職極】短訊</h4>
            <p>
                近日有不少用家反映，有人冒充本公司向他們發放假卡片，訛稱介紹工作，獲取個人資料後，甚至發送不明連結要求用家在假網站註冊，藉此進行欺詐。
            </p>
            <br />
            <p>
                請留意，【職極】與此等訊息並無任何關係，【職極】員工亦不會主動私下聯絡求職者並介紹工作。對於此類欺詐行為，本公司定必遵從法律途徑嚴肅處理。
            </p>
            <br />
            <p>請求職者透過本公司正式途徑申請工作。如有任何懷疑，歡迎向我們查詢或反映。</p>
            <br />
            <div className={cx('footer')}>
                <p className={cx('last-update')}>最後更新: 27-10-2022</p>
                <img className={cx('giggik-logo')} src={images.logoIcon} alt="gikgik-logo" />
            </div>
        </div>
    );
};

export default GigGikStatement;
