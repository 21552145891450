import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames/bind';
import { Link } from 'react-router-dom';
// import AliceCarousel from 'react-alice-carousel';
// import 'react-alice-carousel/lib/alice-carousel.css';

import images from '~/assets/images';
import Button from '../Button';
import {
    BagIcon,
    FavoriteIcon,
    FavoriteSelectedIcon,
    SlidePrevArrowIcon,
    SlideNextArrowIcon,
    BagFillIcon,
    UserFillIcon,
    LocationDotIcon,
    WhiteEyeIcon,
    BlackEyeIcon,
} from '~/components/Icons';
import * as homepageService from '~/services/homepageService';
import { useTranslation } from 'react-i18next';

import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';

import 'swiper/css';
import 'swiper/css/navigation';
// import 'swiper/css/pagination';

import { useWindowDimensions } from '~/hooks';
import styles from './_Featured.module.scss';
import DetectItemShow from '../DetectItemShow';

const cx = classNames.bind(styles);

function _Featured() {
    // const [isFavorite, setIsFavorite] = useState(false);
    const [sectionData, setSectionData] = useState([]);
    const [loading, setLoading] = useState(false);
    const { t, i18n } = useTranslation();
    const windowDimensions = useWindowDimensions();

    const prevRef = useRef();
    const nextRef = useRef();

    useEffect(() => {
        const fetchApi = async () => {
            setLoading(true);
            const result = await homepageService.loadFeatureCompanies();
            if (result?.result.length > 0) {
                setSectionData(result?.result);
            }
            setLoading(false);
        };

        fetchApi();
    }, [i18n.language]);

    // const [isDivideBy10, setIsDivideBy10] = useState(false)
    const getYoutubeID = (url) => {
        var ID = '';
        url = url.replace(/(>|<)/gi, '').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
        if (url[2] !== undefined) {
            ID = url[2].split(/[^0-9a-z_\-]/i);
            ID = ID[0];
        } else {
            ID = url;
        }
        return ID;
    };

    const handleJobClick = (id) => () => {
        homepageService.itemClick({
            section: 'homepage',
            sub_section: 'Company',
            object_type: 'company',
            object_id: id,
        })
    }

    const articleItems = sectionData.map((article, index) => {
        return (
            <SwiperSlide key={index}>
                 <DetectItemShow data={{
                    section: 'homepage',
                    sub_section: 'Company',
                    object_type: 'company',
                    object_id: article?.header?.id,
                }} />
                <div key={index} className={cx('article')}>
                    <div className={cx('header')}>
                        <div className={cx('thumbnail')}>
                            <a threfo={article?.header?.link} onClick={handleJobClick(article?.header?.id)}>
                                {article?.header?.thumbnail_type == 1 && (
                                    <img src={article?.header?.thumbnail} alt="article-thumbnail" />
                                )}
                                {article?.header?.thumbnail_type == 2 && (
                                    <iframe
                                        width="100%"
                                        height="100%"
                                        src={
                                            'https://www.youtube.com/embed/' +
                                            getYoutubeID(article?.header?.thumbnail) +
                                            ''
                                        }
                                        frameBorder={0}
                                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen
                                    ></iframe>
                                )}
                            </a>
                        </div>
                        <div className={cx('logo')}>
                            <a href={article?.header?.link} onClick={handleJobClick(article?.header?.id)}>
                                <img src={article?.header?.logo} alt="article-logo" />
                            </a>
                        </div>

                        <div className={cx('title')}>
                            <a href={article?.header?.link} onClick={handleJobClick(article?.header?.id)}>
                                <h2>{article?.header?.title}</h2>
                            </a>
                        </div>
                    </div>
                    <div className={cx('spacer')}></div>
                    <div className={cx('content')}>
                        <div className={cx('description')}>
                            {article?.body?.location != '' && (
                                <div className={cx('item')}>
                                    <>
                                        <div className={cx('icon-container')}>
                                            <LocationDotIcon />
                                        </div>
                                        <span>{article?.body?.location}</span>
                                    </>
                                </div>
                            )}
                            <div className={cx('item')}>
                                <div className={cx('icon-container')}>
                                    <BagFillIcon />
                                </div>
                                <span>
                                    {t('company.industry')}：{article?.body?.industry}
                                </span>
                            </div>
                            <div className={cx('item')}>
                                {article?.body?.vacancies != undefined && article?.body?.vacancies > 0 && (
                                    <>
                                        <div className={cx('icon-container')}>
                                            <UserFillIcon />
                                        </div>
                                        {article?.body?.vacancies > 0
                                            ? t('featuredCompanies.companyCard.jobs_plural')
                                            : t('featuredCompanies.companyCard.jobs')}
                                        <a href={article?.header?.link} onClick={handleJobClick(article?.header?.id)}>
                                            <h4 className={cx('number')}>
                                                {article?.body?.vacancies <= 0
                                                    ? 0
                                                    : article?.body?.vacancies / 10 >= 1 &&
                                                      article?.body?.vacancies % 10 >= 0
                                                    ? '10+'
                                                    : article?.body?.vacancies}
                                            </h4>
                                        </a>
                                    </>
                                )}
                            </div>
                            {article?.body?.location == '' && <div className={cx('item')}></div>}
                        </div>
                    </div>
                    <div className={cx('footer')}>
                        <span className={cx('view-job')}>
                            <Button rounded href={article?.header?.link} onClick={handleJobClick(article?.header?.id)}>
                                <WhiteEyeIcon />
                                {t('company.view')}
                            </Button>
                        </span>
                        <span className={cx('view-count')}>
                            <BlackEyeIcon /> {article?.body?.views}
                        </span>
                        <span></span>
                    </div>
                </div>
            </SwiperSlide>
        );
    });

    return (
        <>
            <div className={cx('wrapper', 'wrapper-alice-carousel-optional-style')}>
                <Swiper
                    onInit={(swiper) => {
                        swiper.params.navigation.prevEl = prevRef.current;
                        swiper.params.navigation.nextEl = nextRef.current;
                        swiper.navigation.init();
                        swiper.navigation.update();
                    }}
                    // spaceBetween={20}
                    breakpoints={{
                        340: {
                            slidesPerView: 1,
                            // spaceBetween: 25,
                        },
                        640: {
                            slidesPerView: 2,
                            // spaceBetween: 50,
                        },
                        930: {
                            slidesPerView: 3,
                            slidesPerGroup: 3,
                            spaceBetween: 20,
                        },
                        1024: {
                            slidesPerView: 3,
                            slidesPerGroup: 3,
                            spaceBetween: 10,
                        },
                        1440: {
                            slidesPerView: 4,
                            slidesPerGroup: 4,
                            spaceBetween: 10,
                        },
                    }}
                    onReachBeginning={(swiper) => {
                        swiper.params.navigation.disabledClass = 'swiper-button-disabled';
                        swiper.navigation.update();
                    }}
                    onReachEnd={(swiper) => {
                        swiper.params.navigation.disabledClass = 'swiper-button-disabled';
                        swiper.navigation.update();
                    }}
                    scrollbar={{ draggable: true }}
                    modules={[Navigation]}
                    grabCursor={true}
                    className="featured-articles-carousel"
                >
                    {articleItems}

                    <button ref={prevRef} className="swiper-prev-button" style={{ background: 'transparent' }}>
                        <SlidePrevArrowIcon />
                    </button>

                    <button ref={nextRef} className="swiper-next-button" style={{ background: 'transparent' }}>
                        <SlideNextArrowIcon />
                    </button>
                </Swiper>
            </div>
            <div className={cx('view-all-btn', 'view-all-btn-style')}>
                <Button rounded href={`${process.env.REACT_APP_BASE_URL}/${i18n.languages[0]}/companies`} 
                        onClick={() => {
                            homepageService.itemClick({
                                section: 'homepage',
                                sub_section: 'Company',
                                object_type: 'company',
                                object_value: t('company.more_companies'),
                            })
                        }}>
                    {t('company.more_companies')}
                </Button>
            </div>
        </>
    );
}

export default _Featured;
