import styles from './Loading.module.scss';
import classNames from 'classnames/bind';
const cx = classNames.bind(styles);

function Loading() {
    return (
        <div className={cx('sk-fading-circle', 'small')}>
            <div className={cx('sk-circle1', 'sk-circle')}></div>
            <div className={cx('sk-circle2', 'sk-circle')}></div>
            <div className={cx('sk-circle3', 'sk-circle')}></div>
            <div className={cx('sk-circle4', 'sk-circle')}></div>
            <div className={cx('sk-circle5', 'sk-circle')}></div>
            <div className={cx('sk-circle6', 'sk-circle')}></div>
            <div className={cx('sk-circle7', 'sk-circle')}></div>
            <div className={cx('sk-circle8', 'sk-circle')}></div>
            <div className={cx('sk-circle9', 'sk-circle')}></div>
            <div className={cx('sk-circle10', 'sk-circle')}></div>
            <div className={cx('sk-circle11', 'sk-circle')}></div>
            <div className={cx('sk-circle12', 'sk-circle')}></div>
        </div>
    )
}

export default Loading