import classNames from 'classnames/bind';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './_Password.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';


const cx = classNames.bind(styles);

function _Password({ value, onChange }) {

    const [show, setShow] = useState(false)
    const { t } = useTranslation()

    return (
        <div className={cx('password-field')}>
            <input
                type={!show ? "password" : "text"}
                placeholder={t('placeholder.password')}
                name='password'
                value={value}
                onChange={onChange}
            />
            <span className="reveal-eye">
                {
                    !show ?
                        <FontAwesomeIcon icon={faEye} onClick={() => setShow(!show)} />
                        :
                        <FontAwesomeIcon icon={faEyeSlash} onClick={() => setShow(!show)} />
                }
            </span>
        </div>
    )
}

export default _Password