import React, { useEffect } from 'react';
import classNames from 'classnames/bind';
import { useState } from 'react';
import 'react-alice-carousel/lib/alice-carousel.css';
import { open } from '~/reducers/popupReducer';
import Button from '../Button';
import { HumanResourceIcon } from '~/components/Icons';

import styles from './_Popular.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as homepageService from '~/services/homepageService';
import DetectItemShow from '../DetectItemShow';
const cx = classNames.bind(styles);

function _Popular() {
    const dropdown = useSelector((state) => state.dropdown);
    const [industries, setIndustries] = useState([]);
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        let newIndustries = [];
        if (dropdown?.industries?.length > 0) {
            newIndustries = dropdown?.industries?.slice(0, 9).map((industry) => {
                return {
                    id: industry.id,
                    link: `${process.env.REACT_APP_BASE_URL}/${i18n.languages[0]}/search?industries[]=${industry.id}`,
                    textContent: industry.value,
                    slug: industry.slug,
                };
            });
        }

        if (newIndustries.length > 0) {
            setIndustries(newIndustries);
        }
    }, [dropdown]);

    const handleJobClick = (id) => () => {
        homepageService.itemClick({
            section: 'homepage',
            sub_section: 'Industry',
            object_type: 'industry',
            object_id: id,
        })
    }

    const renderArticleItems = industries.map((article, index) => {
        return (
            <a className={cx('popular-item')} key={index} href={article?.link} onClick={handleJobClick(article?.id)}>
                <DetectItemShow data={{
                    section: 'homepage',
                    sub_section: 'Industry',
                    object_type: 'industry',
                    object_id: article?.id,
                }} />
                <div className={cx('icon-wrapper')}>
                    <img
                        src={`${process.env.REACT_APP_RESOURCE_URL}/images/candidate/home/industries/${article?.slug}.png`}
                    />
                </div>
                <span>{article?.textContent}</span>
            </a>
        );
    });

    return (
        <>
            <div className={cx('wrapper', 'popular')}>{renderArticleItems}</div>
            <div className={cx('view-all-btn', 'view-all-btn-style')}>
                <Button rounded primary onClick={() => dispatch(open('industries'))}>
                    {t('industry.view_more_industries')}
                </Button>
            </div>
        </>
    );
}

export default _Popular;
