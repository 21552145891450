import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Link, useLocation } from 'react-router-dom';
import styles from './MenuExpand.module.scss';
import { useState, useEffect, useCallback } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Search from '~/layouts/components/Search';
import images from '~/assets/images';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import * as homepageService from '~/services/homepageService';
import DetectItemShow from '~/components/DetectItemShow';

const cx = classNames.bind(styles);

function MenuExpand({ popularSearchItems = [], banner = {} }) {
    // const [isFilterActive, setIsFilterActive] = useState(false);
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const [topFiveKeywords, setTopFiveKeywordData] = useState([]);
    const { pathname } = location;

    const renderItems = () => {
        return popularSearchItems.map((item, index) => {
            const isBadge = !!item.badge;
            const key = isBadge ? [item.badge.tag] : 'default';
            const classNameItem = isBadge ? cx('has-badge') : '';
            return (
                <li key={index} className={classNameItem}>
                    <Link to={item.to}>{item.title}</Link>
                    {isBadge && <span className={cx(`${styles.badge} ${styles[key]}`)}>{item.badge.title}</span>}
                </li>
            );
        });
    };

    useEffect(() => {
        homepageService.loadIsHotKeywordTopFive().then((result) => {
            if (result?.result) {
                setTopFiveKeywordData(result?.result);
            }
        })
    }, [i18n.language]);

    const handleKeywordClick = (keyword) => () => {
        homepageService.itemClick({
            section: 'homepage',
            sub_section: 'Search',
            object_type: 'keyword',
            object_value: keyword,
        })
    }

    // return <ul className={cx('menu-list')}>{renderItems()}</ul>;
    return (
        <div className={pathname === '/en' ? cx('wrapper', 'en') : cx('wrapper')}>
            <div className={cx('search-and-hint')}>
                <div className={cx('search')}>
                    <Search
                    //  onFilter={handleShowFilter}
                    />
                </div>
                <div className={cx('hint')}>
                    <span className={cx('hot-search-title')}>
                        <span className={cx('hot-search-title-text')}>{t('search.popular_search')}:</span>
                    </span>
                    <span className={cx('hot-search-key')}>
                        <ul>
                            {topFiveKeywords.map((keyword) =>
                                <li>
                                    <DetectItemShow data={{
                                        section: 'homepage',
                                        sub_section: 'Search',
                                        object_type: 'keyword',
                                        object_id: '',
                                        object_value: keyword['name_' + i18n.languages[0]],
                                    }} />
                                    <a href={keyword['url_' + i18n.languages[0]] ? keyword['url_' + i18n.languages[0]] : `${process.env.REACT_APP_BASE_URL}/${i18n.languages[0]}/search?keyword=${keyword['name_' + i18n.languages[0]]}`}
                                        onClick={handleKeywordClick(keyword['name_' + i18n.languages[0]])}>
                                        {keyword['name_' + i18n.languages[0]]}
                                    </a>
                                </li>
                            )}
                        </ul>
                    </span>

                    <span className={cx('read-more-btn', i18n.languages[0])}>
                        <a
                            href={`${process.env.REACT_APP_BASE_URL}/${i18n.languages[0]}/search?keyword=&searchType=all`}
                        >
                            {t('common.read_more')} <FontAwesomeIcon icon={faAngleRight} />
                        </a>
                    </span>
                </div>
            </div>
            <div className={cx('banner')}>
                <div className={cx('inner')}>
                    {
                        banner && banner.id &&
                        <DetectItemShow data={{
                            section: 'homepage',
                            sub_section: 'Side Banner',
                            object_type: 'banner',
                            object_value: 'search',
                            object_id: banner?.id,
                        }} />
                    }
                    {
                        banner && banner.src &&
                        <img src={banner.src} alt="header-banner" />
                    }
                </div >
            </div >
        </div >
    );
}

MenuExpand.propTypes = {
    items: PropTypes.array,
};

export default MenuExpand;
