import React, { useEffect, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import classNames from 'classnames/bind';
import { Link, useLocation } from 'react-router-dom';
import images from '~/assets/images';
import styles from './Footer.module.scss';
import ContactForm from '~/layouts/components/ContactForm';
import NetworkItems from '~/components/NetworkItems';
import config from '~/config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faLocationDot, faMapLocation, faMapMarked, faPhone } from '@fortawesome/free-solid-svg-icons';
import {
    FacebookIcon,
    InstagramIcon,
    LinkedInIcon,
    SignalIcon,
    TelegramIcon,
    WhatsAppIcon,
    YoutubeIcon,
} from '~/components/Icons';
import { useWindowDimensions } from '~/hooks';
import moment from 'moment';
import Collapse from '~/components/Collapse';
import { useSelector } from 'react-redux';
import Chatroom from '../Chatroom';
const cx = classNames.bind(styles);

function Footer() {
    const { t, i18n } = useTranslation();
    const windowWidth = useWindowDimensions().width;
    const auth = useSelector((state) => state.auth);
    const scrollTopButtonRef = useRef();
    const location = useLocation();

    const { pathname } = location;

    const footerMobileCollapseStyle = {
        headerStyle: {
            borderBottomColor: 'rgba(105, 203, 225, 1)',
        },
        wrapperHeadingStyle: {
            paddingLeft: 0,
        },
        headingStyle: {
            color: '#3E3D42',
            fontSize: '20px',
            fontWeight: '600',
            lineHeight: '40px',
            letterSpacing: '1px',
        },
        bodyStyle: {
            paddingLeft: 0,
            borderBottom: '2px solid rgba(105, 203, 225, 1)',
        },
    };

    function handleScrollTop() {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }
    useEffect(() => {
        // get offset top
        // offsetTop <= 500px > add class animation fade-out
        // offsetTop <= 500px > add class animation fade-in
        const handleScrollTopAppearance = () => {
            // const offsetTop = scrollTopButtonRef.current.offsetTop;

            if (document.body.scrollTop > 250 || document.documentElement.scrollTop > 250) {
                // mybutton.style.display = "block";
                scrollTopButtonRef.current.style.opacity = 1;
                scrollTopButtonRef.current.style.transition = 'all ease .6s';
            } else {
                scrollTopButtonRef.current.style.opacity = 0;
                scrollTopButtonRef.current.style.transition = 'all ease .6s';
            }
        };
        document.addEventListener('scroll', handleScrollTopAppearance);
        return () => {
            document.removeEventListener('scroll', handleScrollTopAppearance);
        };
    }, []);

    //'linear-gradient(360deg, rgb(253 246 222 / 82%) 0%, rgb(253 246 222 / 92%) 51.04%, rgba(247 222 129 / 40%) 78.65%, rgb(247 222 129 / 61%) 100%)',
    return (
        <div className={cx('wrapper')}>
            <div
                className={cx('inner-background')}
                style={
                    windowWidth < 768
                        ? { background: `url(${images.footerBackgroundMobile})` }
                        : {
                              // background: `url(${images.footerBackground})`
                              background:
                                  'linear-gradient(0deg, rgba(255 255 255 / 1), rgba(253 246 222 / 0.65), rgba(251 246 225 / 1), rgba(249 238 197 / 1))',
                          }
                }
            >
                <div className="container">
                    <div className="inner">
                        <div className="row">
                            <div className={pathname === '/en' ? cx('content-footer', 'en') : cx('content-footer')}>
                                <div className={cx('top-content')}>
                                    <span className={cx('send-mail')}>
                                        <div className={cx('inner')}>
                                            <img
                                                src={
                                                    pathname === '/en'
                                                        ? images.footerContact_en
                                                        : pathname === '/sc'
                                                        ? images.footerContact_sc
                                                        : images.footerContact
                                                }
                                                alt="footer-contact-image"
                                            />
                                            <div className={cx('form-wrapper')}>
                                                <ContactForm />
                                            </div>
                                            <h6 className={cx('subscribe-declaimer')}>
                                                <Trans i18nKey="footer.subscribe_declaimer">
                                                    點擊「訂閱」代表閣下已經閱讀並接受職極的
                                                    <a
                                                        href={`${process.env.REACT_APP_BASE_URL}/${i18n.languages[0]}/privacy-policy`}
                                                    >
                                                        私隱政策聲明
                                                    </a>
                                                    及
                                                    <a
                                                        href={`${process.env.REACT_APP_BASE_URL}/${i18n.languages[0]}/terms-of-use`}
                                                    >
                                                        條款及細則
                                                    </a>
                                                    。
                                                </Trans>
                                            </h6>
                                        </div>
                                    </span>
                                    <span className={cx('follow')}>
                                        <div className={cx('img-wrapper')}>
                                            <img
                                                src={
                                                    pathname === '/en'
                                                        ? images.footerFollow_en
                                                        : pathname === '/sc'
                                                        ? images.footerFollow_sc
                                                        : images.footerFollow
                                                }
                                                alt="footer-follow-image"
                                            />
                                        </div>

                                        <div className={cx('items')}>
                                            <NetworkItems
                                                large
                                                className={'footer-icon-btn'}
                                                text
                                                rounded
                                                primary
                                                href="https://www.facebook.com/giggikhk"
                                            >
                                                <FacebookIcon />
                                            </NetworkItems>
                                            <NetworkItems
                                                large
                                                className={'footer-icon-btn'}
                                                text
                                                rounded
                                                primary
                                                href="https://www.instagram.com/giggikhk/"
                                            >
                                                <InstagramIcon />
                                            </NetworkItems>
                                            <NetworkItems
                                                large
                                                className={'footer-icon-btn'}
                                                text
                                                rounded
                                                primary
                                                href="https://www.youtube.com/channel/UCeI8_4XF5jbeBGkNlJNyB5w"
                                            >
                                                <YoutubeIcon />
                                            </NetworkItems>
                                            <NetworkItems
                                                large
                                                className={'footer-icon-btn'}
                                                text
                                                rounded
                                                primary
                                                href="https://www.linkedin.com/company/giggik"
                                            >
                                                <LinkedInIcon />
                                            </NetworkItems>
                                        </div>
                                    </span>
                                    <span className={cx('chat')}>
                                        <div className={cx('img-wrapper')}>
                                            <img
                                                src={
                                                    pathname === '/en'
                                                        ? images.footerChat_en
                                                        : pathname === '/sc'
                                                        ? images.footerChat_sc
                                                        : images.footerChat
                                                }
                                                alt="footer-chat-image"
                                            />
                                        </div>
                                        <div className={cx('items')}>
                                            <NetworkItems
                                                large
                                                className={'footer-icon-btn'}
                                                text
                                                rounded
                                                primary
                                                href="https://chat.whatsapp.com/CCmrody65uN59yip5gFZys"
                                            >
                                                <WhatsAppIcon />
                                            </NetworkItems>
                                            <NetworkItems
                                                large
                                                className={'footer-icon-btn'}
                                                text
                                                rounded
                                                primary
                                                href="https://bit.ly/%E8%81%B7%E6%A5%B5GigGik_Signal_group"
                                            >
                                                <SignalIcon />
                                            </NetworkItems>
                                            <NetworkItems
                                                large
                                                className={'footer-icon-btn'}
                                                text
                                                rounded
                                                primary
                                                href="https://t.me/GigGikhkwork"
                                            >
                                                <TelegramIcon />
                                            </NetworkItems>
                                        </div>
                                    </span>
                                </div>

                                <div className={cx('links', 'only-lg __flex')}>
                                    <ul>
                                        {t('footer.job_seeker.title')}
                                        <li>
                                            {/* <Link to={'/candidate'}>{t('footer.job_seeker.children.find_job')}</Link> */}
                                            <a href={`${process.env.REACT_APP_BASE_URL}/${i18n.languages[0]}`}>
                                                {t('footer.job_seeker.children.find_job')}
                                            </a>
                                        </li>
                                        <li>
                                            {/* <Link to={'/candidate'}>{t('footer.job_seeker.children.industry')}</Link> */}
                                            <a
                                                href={`${process.env.REACT_APP_BASE_URL}/${i18n.languages[0]}?popup_action=industries`}
                                            >
                                                {t('footer.job_seeker.children.industry')}
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href={
                                                    auth.loggedIn
                                                        ? `${process.env.REACT_APP_BASE_URL}/${i18n.languages[0]}/profile`
                                                        : `${process.env.REACT_APP_BASE_URL}/${i18n.languages[0]}?popup_action=login`
                                                }
                                            >
                                                {t('footer.job_seeker.children.create_profile')}
                                            </a>
                                        </li>
                                        <li>
                                            {/* <Link to={'/candidate'}>
                                                {t('footer.job_seeker.children.features_employer')}
                                            </Link> */}
                                            <a
                                                href={`${process.env.REACT_APP_BASE_URL}/${i18n.languages[0]}/companies`}
                                            >
                                                {t('footer.job_seeker.children.features_employer')}
                                            </a>
                                        </li>
                                    </ul>
                                    <ul>
                                        {t('footer.employer.title')}
                                        <li>
                                            {/* <Link to={'/employer'}>{t('footer.employer.children.post_job')}</Link> */}
                                            <a
                                                href={`${process.env.REACT_APP_RECRUITER_URL}/${i18n.languages[0]}/job/add`}
                                            >
                                                {t('footer.employer.children.post_job')}
                                            </a>
                                        </li>
                                        <li>
                                            {/* <Link to={'/employer'}>{t('footer.employer.children.slash')}</Link> */}
                                            <a href={`${process.env.REACT_APP_NEWGIG_URL}/${i18n.languages[0]}`}>
                                                {t('footer.employer.children.slash')}
                                            </a>
                                        </li>
                                        <li>
                                            {/* <Link to={'/employer'}>{t('footer.employer.children.client')}</Link> */}
                                            <a
                                                href={`${process.env.REACT_APP_BASE_URL}/${i18n.languages[0]}/companies`}
                                            >
                                                {t('footer.employer.children.client')}
                                            </a>
                                        </li>
                                        <li>
                                            {/* <Link to={'/employer'}>
                                                {t('footer.employer.children.cooperation_opportunities')}
                                            </Link> */}
                                            <a href={`${process.env.REACT_APP_RECRUITER_URL}/${i18n.languages[0]}`}>
                                                {t('footer.employer.children.cooperation_opportunities')}
                                            </a>
                                        </li>
                                    </ul>

                                    <ul>
                                        {t('footer.professional_information.title')}

                                        <li>
                                            <a
                                                href={`https://www.giggik.com/recruit/${i18n.languages[0]}/company/giggik-technology-limited`}
                                            >
                                                {t('footer.professional_information.children.profile')}
                                            </a>
                                        </li>
                                        <li>
                                            {/* <Link to={'/more-information'}>
                                                {t('footer.professional_information.children.vocational_skills_guide')}
                                            </Link> */}
                                            <a
                                                href={`${process.env.REACT_APP_BASE_URL}/${i18n.languages[0]}/blog/%E8%85%A6%E7%B4%B0%E9%99%B7%E9%98%B1%E5%95%8F%E9%A1%8C%E4%BD%A0%E8%A6%81%E8%AD%98%E7%AD%94`}
                                            >
                                                {t('footer.professional_information.children.vocational_skills_guide')}
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://bit.ly/職極GigGik_Whatsapp_group">
                                                {t('footer.professional_information.children.helper_group')}
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://docs.google.com/forms/d/1rXDO4sT0REXCaK44765iCY88ip1ziG-YFFvAhgo5cTA/viewform?edit_requested=true">
                                                {t('footer.professional_information.children.worker_introduction')}
                                            </a>
                                        </li>
                                    </ul>
                                    <ul>
                                        {t('footer.recruitment.title')}

                                        <li>
                                            <a href="https://www.giggik.com">
                                                {t('footer.recruitment.children.about_career')}
                                            </a>
                                        </li>
                                        <li>
                                            {/* <Link to={'/recruitment'}>
                                                {t('footer.recruitment.children.join_professional')}
                                            </Link> */}
                                            <a
                                                href={`${process.env.REACT_APP_BASE_URL}/${i18n.languages[0]}/company/giggik-technology-limited`}
                                            >
                                                {t('footer.recruitment.children.join_professional')}
                                            </a>
                                        </li>
                                        <li>
                                            {/* <Link to={'/recruitment'}>{t('footer.recruitment.children.privacy')}</Link> */}
                                            <a
                                                href={`${process.env.REACT_APP_BASE_URL}/${i18n.languages[0]}/privacy-policy`}
                                            >
                                                {t('footer.recruitment.children.privacy')}
                                            </a>
                                        </li>
                                        <li>
                                            {/* <Link to={'/recruitment'}>
                                                {t('footer.recruitment.children.terms_and_condition')}
                                            </Link> */}
                                            <a
                                                href={`${process.env.REACT_APP_BASE_URL}/${i18n.languages[0]}/terms-of-use`}
                                            >
                                                {t('footer.recruitment.children.terms_and_condition')}
                                            </a>
                                        </li>
                                        <li>
                                            {/* <Link to={'/recruitment'}>{t('footer.recruitment.children.problem')}</Link> */}
                                            <a href={`${process.env.REACT_APP_BASE_URL}/${i18n.languages[0]}/faq`}>
                                                {t('footer.recruitment.children.problem')}
                                            </a>
                                        </li>
                                    </ul>
                                    <ul className={cx('last')}>
                                        {t('footer.contact_us.title')}

                                        <li>
                                            <span>
                                                <FontAwesomeIcon icon={faLocationDot} />
                                            </span>
                                            <a href="https://goo.gl/maps/jmR4uM7jERXTmMBj9">
                                                {t('footer.contact_us.children.location')}
                                            </a>
                                        </li>
                                        <li>
                                            <span>
                                                <FontAwesomeIcon icon={faPhone} />
                                            </span>
                                            <a href="tel:+852 2531 9345">+852 2531 9345</a>
                                        </li>
                                        <li>
                                            <span>
                                                <FontAwesomeIcon icon={faEnvelope} />
                                            </span>
                                            <a href="mailto:info@giggik.com">info@giggik.com</a>
                                        </li>
                                    </ul>
                                </div>

                                <div className={cx('links', 'mobile-footer-links', 'only-xs')}>
                                    <ul>
                                        {/* {t('footer.job_seeker.title')} */}
                                        <Collapse
                                            headerText={t('footer.job_seeker.title')}
                                            optionalClass={'optional-collapse'}
                                            headerStyle={footerMobileCollapseStyle.headerStyle}
                                            wrapperHeadingStyle={footerMobileCollapseStyle.wrapperHeadingStyle}
                                            headingStyle={footerMobileCollapseStyle.headingStyle}
                                            bodyStyle={footerMobileCollapseStyle.bodyStyle}
                                        >
                                            <li>
                                                {/* <Link to={'/candidate'}>{t('footer.job_seeker.children.find_job')}</Link> */}
                                                <a href={`${process.env.REACT_APP_BASE_URL}/${i18n.languages[0]}`}>
                                                    {t('footer.job_seeker.children.find_job')}
                                                </a>
                                            </li>
                                            <li>
                                                {/* <Link to={'/candidate'}>{t('footer.job_seeker.children.industry')}</Link> */}
                                                <a
                                                    href={`${process.env.REACT_APP_BASE_URL}/${i18n.languages[0]}?popup_action=industries`}
                                                >
                                                    {t('footer.job_seeker.children.industry')}
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    href={
                                                        auth.loggedIn
                                                            ? `${process.env.REACT_APP_BASE_URL}/${i18n.languages[0]}/profile`
                                                            : `${process.env.REACT_APP_BASE_URL}/${i18n.languages[0]}?popup_action=login`
                                                    }
                                                >
                                                    {t('footer.job_seeker.children.create_profile')}
                                                </a>
                                            </li>
                                            <li>
                                                {/* <Link to={'/candidate'}>
                                                {t('footer.job_seeker.children.features_employer')}
                                            </Link> */}
                                                <a
                                                    href={`${process.env.REACT_APP_BASE_URL}/${i18n.languages[0]}/companies`}
                                                >
                                                    {t('footer.job_seeker.children.features_employer')}
                                                </a>
                                            </li>
                                        </Collapse>
                                    </ul>
                                    <ul>
                                        <Collapse
                                            headerText={t('footer.employer.title')}
                                            optionalClass={'optional-collapse'}
                                            headerStyle={footerMobileCollapseStyle.headerStyle}
                                            wrapperHeadingStyle={footerMobileCollapseStyle.wrapperHeadingStyle}
                                            headingStyle={footerMobileCollapseStyle.headingStyle}
                                            bodyStyle={footerMobileCollapseStyle.bodyStyle}
                                        >
                                            <li>
                                                {/* <Link to={'/employer'}>{t('footer.employer.children.post_job')}</Link> */}
                                                <a
                                                    href={`${process.env.REACT_APP_RECRUITER_URL}/${i18n.languages[0]}/job/add`}
                                                >
                                                    {t('footer.employer.children.post_job')}
                                                </a>
                                            </li>
                                            <li>
                                                {/* <Link to={'/employer'}>{t('footer.employer.children.slash')}</Link> */}
                                                <a href={`${process.env.REACT_APP_NEWGIG_URL}/${i18n.languages[0]}`}>
                                                    {t('footer.employer.children.slash')}
                                                </a>
                                            </li>
                                            <li>
                                                {/* <Link to={'/employer'}>{t('footer.employer.children.client')}</Link> */}
                                                <a
                                                    href={`${process.env.REACT_APP_BASE_URL}/${i18n.languages[0]}/companies`}
                                                >
                                                    {t('footer.employer.children.client')}
                                                </a>
                                            </li>
                                            <li>
                                                {/* <Link to={'/employer'}>
                                                {t('footer.employer.children.cooperation_opportunities')}
                                            </Link> */}
                                                <a href={`${process.env.REACT_APP_RECRUITER_URL}/${i18n.languages[0]}`}>
                                                    {t('footer.employer.children.cooperation_opportunities')}
                                                </a>
                                            </li>
                                        </Collapse>
                                    </ul>

                                    <ul>
                                        {/* {t('footer.professional_information.title')} */}
                                        <Collapse
                                            headerText={t('footer.professional_information.title')}
                                            optionalClass={'optional-collapse'}
                                            headerStyle={footerMobileCollapseStyle.headerStyle}
                                            wrapperHeadingStyle={footerMobileCollapseStyle.wrapperHeadingStyle}
                                            headingStyle={footerMobileCollapseStyle.headingStyle}
                                            bodyStyle={footerMobileCollapseStyle.bodyStyle}
                                        >
                                            <li>
                                                <a
                                                    href={`https://www.giggik.com/recruit/${i18n.languages[0]}/company/giggik-technology-limited`}
                                                >
                                                    {t('footer.professional_information.children.profile')}
                                                </a>
                                            </li>
                                            <li>
                                                {/* <Link to={'/more-information'}>
                                                {t('footer.professional_information.children.vocational_skills_guide')}
                                            </Link> */}
                                                <a
                                                    href={`${process.env.REACT_APP_BASE_URL}/${i18n.languages[0]}/gikpedia`}
                                                >
                                                    {t(
                                                        'footer.professional_information.children.vocational_skills_guide',
                                                    )}
                                                </a>
                                            </li>
                                            <li>
                                                <a href="https://bit.ly/職極GigGik_Whatsapp_group">
                                                    {t('footer.professional_information.children.helper_group')}
                                                </a>
                                            </li>
                                            <li>
                                                <a href="https://docs.google.com/forms/d/1rXDO4sT0REXCaK44765iCY88ip1ziG-YFFvAhgo5cTA/viewform?edit_requested=true">
                                                    {t('footer.professional_information.children.worker_introduction')}
                                                </a>
                                            </li>
                                        </Collapse>
                                    </ul>
                                    <ul>
                                        <Collapse
                                            headerText={t('footer.recruitment.title')}
                                            optionalClass={'optional-collapse'}
                                            headerStyle={footerMobileCollapseStyle.headerStyle}
                                            wrapperHeadingStyle={footerMobileCollapseStyle.wrapperHeadingStyle}
                                            headingStyle={footerMobileCollapseStyle.headingStyle}
                                            bodyStyle={footerMobileCollapseStyle.bodyStyle}
                                        >
                                            <li>
                                                <a href="https://www.giggik.com">
                                                    {t('footer.recruitment.children.about_career')}
                                                </a>
                                            </li>
                                            <li>
                                                {/* <Link to={'/recruitment'}>
                                                {t('footer.recruitment.children.join_professional')}
                                            </Link> */}
                                                <a
                                                    href={`${process.env.REACT_APP_BASE_URL}/${i18n.languages[0]}/company/giggik-technology-limited`}
                                                >
                                                    {t('footer.recruitment.children.join_professional')}
                                                </a>
                                            </li>
                                            <li>
                                                {/* <Link to={'/recruitment'}>{t('footer.recruitment.children.privacy')}</Link> */}
                                                <a
                                                    href={`${process.env.REACT_APP_BASE_URL}/${i18n.languages[0]}/privacy-policy`}
                                                >
                                                    {t('footer.recruitment.children.privacy')}
                                                </a>
                                            </li>
                                            <li>
                                                {/* <Link to={'/recruitment'}>
                                                {t('footer.recruitment.children.terms_and_condition')}
                                            </Link> */}
                                                <a
                                                    href={`${process.env.REACT_APP_BASE_URL}/${i18n.languages[0]}/terms-of-use`}
                                                >
                                                    {t('footer.recruitment.children.terms_and_condition')}
                                                </a>
                                            </li>
                                            <li>
                                                {/* <Link to={'/recruitment'}>{t('footer.recruitment.children.problem')}</Link> */}
                                                <a href={`${process.env.REACT_APP_BASE_URL}/${i18n.languages[0]}/faq`}>
                                                    {t('footer.recruitment.children.problem')}
                                                </a>
                                            </li>
                                        </Collapse>
                                    </ul>
                                    <ul className={cx('last')}>
                                        <Collapse
                                            headerText={t('footer.contact_us.title')}
                                            optionalClass={'optional-collapse'}
                                            headerStyle={footerMobileCollapseStyle.headerStyle}
                                            wrapperHeadingStyle={footerMobileCollapseStyle.wrapperHeadingStyle}
                                            headingStyle={footerMobileCollapseStyle.headingStyle}
                                            bodyStyle={footerMobileCollapseStyle.bodyStyle}
                                        >
                                            <li>
                                                <span>
                                                    <FontAwesomeIcon icon={faLocationDot} />
                                                </span>
                                                <a href="https://goo.gl/maps/jmR4uM7jERXTmMBj9">
                                                    {t('footer.contact_us.children.location')}
                                                </a>
                                            </li>
                                            <li>
                                                <span>
                                                    <FontAwesomeIcon icon={faPhone} size="lg" />
                                                </span>
                                                <a href="tel:+852 2531 9345">+852 2531 9345</a>
                                            </li>
                                            <li>
                                                <span>
                                                    <FontAwesomeIcon icon={faEnvelope} />
                                                </span>
                                                <a href="mailto:info@giggik.com">info@giggik.com</a>
                                            </li>
                                        </Collapse>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={cx('copyright', 'copyright-optional')}>
                <div className="container __flex __w100">
                    <div className="inner">
                        <div className="row row-optional">
                            <p className={cx('text')}>
                                {t('footer.declaimer_1', { year: moment().format('yyyy') })}
                                <br />
                                {t('footer.declaimer_2')}
                            </p>
                            <span className={cx('logo')}>
                                <img src={images.logoIcon} alt="footer-logo" />
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div ref={scrollTopButtonRef} className={cx('scroll-top-btn')}>
                <button onClick={handleScrollTop}>
                    <img src={images.footerScrollTopBtn} />
                    {/* <ScrollTopIcon /> */}
                </button>
            </div>
            <Chatroom />
        </div>
    );
}
export default Footer;
