import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Link } from 'react-router-dom';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import images from '~/assets/images';
import styles from './Carousel.module.scss';
import { useTranslation } from 'react-i18next';
const cx = classNames.bind(styles);

function Carousel({
    // autoPlay = false,
    // autoHeight = false,

    // autoPlayControls = false,
    // autoPlayInterval = 400,
    // infinite = false,
    // keyboardNavigation = false,
    autoWidth = true,
    ...passProps
}) {
    const { i18n } = useTranslation();
    const handleDragStart = (e) => e.preventDefault();
    const responsive = {
        0: { items: 1 },
        300: { items: 1 },
        576: { items: 1 },
        1024: { items: 1 },
        1200: { items: 1 },
        1420: { items: 1 },
        1536: { items: 1 },
    };
    const navigateBannerLinks = (url) => {
        if (url) {
            window.location.href = url
        }
    };

    const BANNER_LINKS = [
        `https://api.whatsapp.com/send?phone=85257207854&text=Hello%20%E6%88%91%E6%83%B3%E6%9F%A5%E8%A9%A2%E6%9C%89%E9%97%9C%22%E5%85%83%E6%B0%97%E5%AF%BF%E5%8F%B8%22%E8%81%B7%E4%BD%8D%E7%A9%BA%E7%BC%BA%E3%80%82`,
        `https://www.giggik.com/recruit/${i18n.languages[0]}/search?keyword=Skinmax&searchType=all`,
        `https://cutt.ly/AromeBakerywhatsappgg`,
        `https://www.giggik.com/recruit/${i18n.languages[0]}/search?keyword=%E5%85%81%E8%A8%98&searchType=all`,
        `https://www.giggik.com/recruit/${i18n.languages[0]}/search?keyword=starbucks&searchType=all`,
        `https://www.giggik.com/recruit/${i18n.languages[0]}/search?keyword=cathay%20pacific%20&searchType=all`,
        `https://cutt.ly/KaiShingwhatsapp`,
    ];

    const items = passProps.banners?.map((banner) =>
        <img
            className={styles.carouselImage}
            onClick={() => navigateBannerLinks(banner.url)}
            src={banner.src}
            // onDragStart={handleDragStart}
            role="presentation"
            alt="top-banner"
        />
    )
    return <AliceCarousel items={items} {...passProps} responsive={responsive} />;
}

Carousel.propTypes = {
    autoPlay: PropTypes.bool,
    autoHeight: PropTypes.bool,
    autoWidth: PropTypes.bool,

    autoPlayControls: PropTypes.bool,
    autoPlayInterval: PropTypes.number,

    infinite: PropTypes.bool,
    keyboardNavigation: PropTypes.bool,
};

export default Carousel;
