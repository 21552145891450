import React, { useState, useEffect, useRef } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import * as Icon from '~/components/Icons';

import classNames from 'classnames/bind';
import styles from './Collapse.module.scss';
// import { HomeSectionBgTitleIconShort, HomeSectionBgTitleIconLong } from '~/components/Icons';
const cx = classNames.bind(styles);

/**
 *
 * @param {*} param0
 * title: header title
 * iconName: iconName in Icons component
 * arrowColor: optional color for arrows up and down
 *
 */
function Collapse({
    headerText,
    iconName,
    arrowColor,
    optionalClass,
    headerStyle,
    wrapperHeadingStyle,
    headingStyle,
    bodyStyle,
    children,
    innerBottomBorder,
}) {
    let HeaderIcon = iconName ? Icon[iconName] : null;

    const DropdownIcon = Icon.DropdownCollapseIcon;
    const DropdownReverseIcon = Icon.DropdownReverseIcon;

    // default color:
    const fontAwesomeArrowColor = arrowColor ? arrowColor : 'rgba(154, 154, 154, 0.9)';
    headingStyle = {
        ...headingStyle,
        color: headingStyle?.color ? headingStyle?.color : '#37313C',
    };

    const headingActiveStyle = {
        ...headingStyle,
        color: 'rgba(105, 203, 225, 1)',
    };
    const [isExpand, setIsExpand] = useState(false);
    const bodyCollapseEl = useRef(null);

    // function handleToggleItems() {
    //     setIsExpand(!isExpand);
    // }

    useEffect(() => {
        function collapseSection(element) {
            const sectionHeight = element.scrollHeight;
            // temporarily disable all css transitions
            const elementTransition = element.style.transition;
            element.style.transition = '';

            requestAnimationFrame(function () {
                element.style.height = sectionHeight + 'px';
                element.style.transition = elementTransition;
                requestAnimationFrame(function () {
                    element.style.height = 0 + 'px';
                });
            });

            // mark the section as "currently collapsed"
            element.setAttribute('data-collapsed', 'true');
        }
        function expandSection(element) {
            const sectionHeight = element.scrollHeight;
            element.style.height = sectionHeight + 'px';
            element.setAttribute('data-collapsed', 'false');
        }
        if (isExpand) {
            expandSection(bodyCollapseEl.current);
        } else {
            collapseSection(bodyCollapseEl.current);
        }

        // remove event listener
        return () =>
            window.removeEventListener('transitionend', function (e) {
                // remove this event listener so it only gets triggered once
                bodyCollapseEl.current.removeEventListener('transitionend', arguments.callee);
                // remove "height" from the element's inline styles, so it can return to its initial value
                bodyCollapseEl.current.style.height = null;
            });
    }, [isExpand]);

    return (
        <div className={cx('inner-collapse', { optionalClass })}>
            <div className={cx('inner-container')} style={innerBottomBorder && { borderBottom: '1px solid #e0e0e0' }}>
                <div className={isExpand ? cx('dropdown', 'open') : cx('dropdown')}>
                    <div className={cx('header')} style={headerStyle}>
                        <span style={wrapperHeadingStyle}>
                            {!!iconName && <HeaderIcon />}
                            <h4 style={isExpand ? headingActiveStyle : headingStyle}>{headerText}</h4>
                        </span>
                        <button className={cx('arrow-icon')} onClick={() => setIsExpand(!isExpand)}>
                            {isExpand === true ? (
                                // <FontAwesomeIcon icon={faChevronUp} style={{ color: fontAwesomeArrowColor }} />
                                <DropdownReverseIcon color={arrowColor ? arrowColor : 'rgba(154, 154, 154, 0.9)'} />
                            ) : (
                                <DropdownIcon color={arrowColor ? arrowColor : 'rgba(154, 154, 154, 0.9)'} />
                            )}
                        </button>
                    </div>
                    <div ref={bodyCollapseEl} className={cx('body')} style={bodyStyle}>
                        {isExpand === true && children}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Collapse;
