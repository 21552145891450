import React from 'react';
import ReactDOM from 'react-dom/client';

import App from '~/App';
import reportWebVitals from './reportWebVitals';
// import 'bootstrap/dist/css/bootstrap.css';
import GlobalStyles from '~/components/GlobalStyles';

import '~/i18next';
import { Provider } from 'react-redux';
import store from './store';
import { HeaderProvider } from '~/ThemeContext';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    // <Provider store={store}>
    //     <HeaderProvider>
    //         <React.StrictMode>
    //             <GlobalStyles>
    //                 <App />
    //             </GlobalStyles>
    //         </React.StrictMode>
    //     </HeaderProvider>
    // </Provider>,

    <Provider store={store}>
        <App />
    </Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
