import React, { useRef } from 'react';
import classNames from 'classnames/bind';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import images from '~/assets/images';
import Button from '../Button';
import {
    HotJobIcon,
    FavoriteIcon,
    SlidePrevArrowIcon,
    SlideNextArrowIcon,
    FavoriteSelectedIcon,
} from '~/components/Icons';

import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import styles from './_HotJob.module.scss';
import { useWindowDimensions } from '~/hooks';
import { useTranslation } from 'react-i18next';
import * as homepageService from '~/services/homepageService';
import DetectItemShow from '../DetectItemShow';

const cx = classNames.bind(styles);

function _HotJob({ type, sectionData, handleSaveJob }) {
    // const [isFavorite, setIsFavorite] = useState(false);
    const windowDimensions = useWindowDimensions();
    const { i18n, t } = useTranslation();
    const prevRef = useRef();
    const nextRef = useRef();

    const handleJobClick = (reference_no) => () => {
        homepageService.itemClick({
            section: 'homepage',
            sub_section: 'Hot Job',
            object_type: 'job',
            object_id: reference_no,
        })
    }

    const articleItems = sectionData?.jobs.map((article, index) => {
        return (
            <SwiperSlide key={index}>
                <DetectItemShow data={{
                    section: 'homepage',
                    sub_section: 'Hot Job',
                    object_type: 'job',
                    object_id: article?.reference_no,
                }} />
                <a href={article?.header?.link} onClick={handleJobClick(article?.reference_no)}>
                    <div key={index} className={cx('article')}>
                        <div className={cx('header')}>
                            <span className={cx('logo')}>
                                <a href={article?.header?.link}>
                                    <img src={article?.header?.logo} alt="article-logo" />
                                </a>
                            </span>
                            <span className={cx('favorite')}>
                                <a href="#" onClick={handleSaveJob(sectionData.section_id, article?.header.job_id)}>
                                    {article?.header?.isFavorite ? <FavoriteSelectedIcon /> : <FavoriteIcon />}
                                </a>
                            </span>
                        </div>
                        <div className={cx('content')}>
                            <div className={cx('title')}>
                                <a href={article?.header?.link}>
                                    <h2>{article?.header?.title}</h2>
                                </a>
                                <a href={article?.header?.company_link}>
                                    <h4>{article?.header?.subTitle}</h4>
                                </a>
                            </div>
                        </div>
                        <div className={cx('footer')}>
                            <div className={cx('item')}>
                                <span className={cx('count-job')}>
                                    <span className={cx('count-job-number')}>{article?.body?.availablejobs}</span>
                                    <p>{t('hotJobRecruitment.positon')}</p>
                                    {article?.body?.availablejobs > 0 && i18n.language === 'en' && <p>s</p>}
                                </span>
                                <div className={cx('tags', i18n.languages[0])}>
                                    {article?.body?.tags.map((item, index) => (
                                        <span key={index} className={styles[item.color]}>
                                            <a href={item.url}>{item.text}</a>
                                        </span>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </a>
            </SwiperSlide>
        );
    });

    const option = {
        // autoPlay = false,
        // autoHeight = false,
        // autoWidth = false,
        // autoPlayControls = false,
        // autoPlayInterval = 400,
        // infinite = false,
        // keyboardNavigation = false,
        // responsive={responsive}
        // disableDotsControls: true,
    };
    return (
        <>
            <div className={cx('heading')}>
                <span className={cx('icon')}>
                    <img src={images.pngIcon.hotJobIcon} alt="hot-job-icon" />
                </span>
                <span className={cx('text')}>
                    <h3>
                        {t('hotJobRecruitment.popularJob')} ({sectionData?.jobs.length})
                    </h3>{' '}
                </span>
                <span className={cx('read-more')}>
                    <a
                        href={
                            sectionData && sectionData.url
                                ? sectionData.url
                                : `${process.env.REACT_APP_BASE_URL}/${i18n.languages[0]}/search?keyword=&searchType=all`
                        }
                        onClick={() => {
                            homepageService.itemClick({
                                section: 'homepage',
                                sub_section: 'Hot Job',
                                object_type: 'job',
                                object_value: t('common.read_more'),
                            })
                        }}
                    >
                        <p>
                            {t('common.read_more')}
                            <FontAwesomeIcon icon={faChevronRight} />
                        </p>
                    </a>
                </span>
            </div>
            <div className={cx('wrapper', 'wrapper-alice-carousel-optional-style')}>
                <Swiper
                    onInit={(swiper) => {
                        swiper.params.navigation.prevEl = prevRef.current;
                        swiper.params.navigation.nextEl = nextRef.current;
                        swiper.navigation.init();
                        swiper.navigation.update();
                    }}
                    // spaceBetween={25}
                    breakpoints={{
                        340: {
                            slidesPerView: 1,
                            // spaceBetween: 25,
                        },
                        640: {
                            slidesPerView: 2,
                            // spaceBetween: 50,
                        },
                        930: {
                            slidesPerView: 3,
                            slidesPerGroup: 3,
                            spaceBetween: 20,
                        },
                        1024: {
                            slidesPerView: 3,
                            slidesPerGroup: 3,
                            spaceBetween: 10,
                        },
                        1440: {
                            slidesPerView: 4,
                            slidesPerGroup: 4,
                            spaceBetween: 10,
                        },
                    }}
                    maxBackfaceHiddenSlides={50}
                    onReachBeginning={(swiper) => {
                        swiper.params.navigation.disabledClass = 'swiper-button-disabled';
                        swiper.navigation.update();
                    }}
                    onReachEnd={(swiper) => {
                        swiper.params.navigation.disabledClass = 'swiper-button-disabled';
                        swiper.navigation.update();
                    }}
                    scrollbar={{ draggable: true }}
                    modules={[Navigation]}
                    grabCursor={true}
                    className={`hotJob-articles-carousel ${i18n.languages[0]}`}
                >
                    {articleItems}

                    <button ref={prevRef} className="swiper-prev-button" style={{ background: 'transparent' }}>
                        {/* 111 */}
                        <SlidePrevArrowIcon />
                    </button>

                    <button ref={nextRef} className="swiper-next-button" style={{ background: 'transparent' }}>
                        <SlideNextArrowIcon />
                    </button>
                </Swiper>
            </div>
        </>
    );
}

export default _HotJob;
