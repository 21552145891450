import { useEffect, useLayoutEffect, useState } from 'react';
import '../ProjectSupportStation/css/bootstrap.min.css';
import '../ProjectSupportStation/css/owl.carousel.min.css';
import '../ProjectSupportStation/css/owl.theme.default.min.css';
import '../ProjectSupportStation/css/style.css';
import '../ProjectSupportStation/css/responsive.css';
import { useTranslation } from 'react-i18next';
import Header from '../ProjectSupportStation/Header';
import Footer from '../ProjectSupportStation/Footer';
import * as Services from '~/services/projectSupportStationService';
import routes from '~/config/routes';
import { useWindowDimensions } from '~/hooks';

const HEIGHT_IMAGE_MOBILE = 1767;

const BUD = () => {
    const { t, i18n } = useTranslation();
    const REACT_APP_PREFIX = process.env.REACT_APP_PREFIX;

    // const { width } = useWindowDimensions();

    const _addScript = (source) => {
        let scripts = document.getElementsByTagName('script');
        for (var i = scripts.length; i--; ) {
            if (
                scripts[i].src == `http://${window.location.host}${source}` ||
                scripts[i].src == `https://${window.location.host}${source}`
            )
                return;
        }

        const script = document.createElement('script');

        script.src = process.env.PUBLIC_URL + source;
        script.async = true;

        document.body.appendChild(script);
    };

    const [menu, setMenu] = useState([]);
    const [iconFooter, setIconFooter] = useState([]);
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    // windowHeight
    useLayoutEffect(() => {
        function handleResize() {
            setWidth(window.innerWidth);
            setHeight(window.innerHeight);
        }
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [width, height]);

    // const menu = [
    //     {
    //         title: t('bud.fundingScope'),
    //         href: '#fun',
    //     },
    //     {
    //         title: t('bud.applicationAndApprovalProcess'),
    //         href: '#fund-anc',
    //     },
    //     {
    //         title: t('bud.fundedProjects'),
    //         href: '#funded-projects',
    //     },
    //     {
    //         title: t('bud.freeInitialAssessment'),
    //         href: '#bud-assessment',
    //     },
    //     {
    //         title: t('bud.contactUs'),
    //         href: '#contact',
    //     },
    //     {
    //         title: t('bud.commonProblem'),
    //         href: '#faq',
    //     },
    // ];

    const logo = {
        image: require('~/assets/images/projectsupportstation/images/bud.png'),
        content: [
            {
                title: t('logoName.home'),
                href: `${REACT_APP_PREFIX != '' ? `/${REACT_APP_PREFIX}` : ''}/${i18n.language}${routes.home}`,
                image: require('~/assets/images/projectsupportstation/images/home.png'),
            },
            {
                title: t('logoName.supportStation'),
                href: `${REACT_APP_PREFIX != '' ? `/${REACT_APP_PREFIX}` : ''}/${i18n.language}${
                    routes.projectSupportStation
                }`,
                image: require('~/assets/images/projectsupportstation/images/support-station.png'),
            },
            {
                title: t('logoName.littleRedBook'),
                image: require('~/assets/images/projectsupportstation/images/xiao-hong-shu.png'),
                href: `${REACT_APP_PREFIX != '' ? `/${REACT_APP_PREFIX}` : ''}/${i18n.language}${routes.xiaoHongShu}`,
            },
            {
                title: t('logoName.talentStation'),
                image: require('~/assets/images/projectsupportstation/images/human-resource.png'),
                href: `${REACT_APP_PREFIX != '' ? `/${REACT_APP_PREFIX}` : ''}/${i18n.language}${
                    routes.humanResourceStation
                }`,
            },
        ],
    };

    const coveringTable = [
        [
            {
                content: t('bud.operatingExpensesRelated'),
                color: 'black',
            },
            {
                content: '20%',
                color: 'black',
            },
        ],
        [
            {
                content: `${t('bud.attendExhibitions')} <span class="color-grey">${t(
                    'bud.includingBoothRental',
                )}</span>`,
                color: 'black',
            },
            {
                content: t('bud.travelExpenses'),
                color: 'black',
            },
        ],
        [
            {
                content: t('bud.productSample'),
                color: 'black',
            },
            {
                content: '30%',
                color: 'black',
            },
        ],
        [
            {
                content: t('bud.recruitAdditionalProjectRelatedStaff'),
                color: 'black',
            },
            {
                content: t('bud.existingEmployeesWillNotSubsidized'),
                color: 'orange',
            },
        ],
        [
            {
                content: t('bud.advertising'),
                color: 'black',
            },
            {
                content: '-',
                color: 'black',
            },
        ],
        [
            {
                content: t('bud.establishOnlineSalesPlatform'),
                color: 'black',
            },
            {
                content: t('bud.onlyAvailableThirdPartyPlatforms'),
                color: 'orange',
            },
        ],
        [
            {
                content: t('bud.createOptimizeMobileApplications'),
                color: 'black',
            },
            {
                content: `${t('bud.each')}50%`,
                color: 'black',
            },
        ],
        [
            {
                content: t('bud.purchaseMachinesMouldsSoftwareSystems'),
                color: 'black',
            },
            {
                content: `${t('bud.computersNotSubsidized')} <span class="color-black">70%</span>`,
                color: 'orange',
            },
        ],
        [
            {
                content: t('bud.designProducePromotionalMaterials'),
                color: 'black',
            },
            {
                content: t('bud.noUpperLimit'),
                color: 'black',
            },
        ],
        [
            {
                content: t('bud.testingCertification'),
                color: 'black',
            },
            {
                content: t('bud.noUpperLimit'),
                color: 'black',
            },
        ],
        [
            {
                content: t('bud.buildOptimizeWebsite'),
                color: 'black',
            },
            {
                content: t('bud.theMaximumWebsiteOptimization'),
                color: 'black',
            },
        ],
        [
            {
                content: t('bud.trademarkPatentRegistration'),
                color: 'black',
            },
            {
                content: t('bud.accumulated'),
                color: 'black',
            },
        ],
        [
            {
                content: t('bud.ipAuthorization'),
                color: 'black',
            },
            {
                content: t('bud.aTotal'),
                color: 'black',
            },
        ],
        [
            {
                content: t('bud.projectAuditFees'),
                color: 'black',
            },
            {
                content: t('bud.fullSubsidy'),
                color: 'black',
            },
        ],
    ];

    const notCoveringTable = [
        [
            {
                content: t('bud.dailyOperatingExpenses'),
                color: 'black',
            },
            {
                contentArr: [t('bud.currentEmployeeSalaries'), t('bud.leasingDecorationUtilities')],
                color: 'black',
            },
        ],
        [
            {
                content: t('bud.equipment'),
                color: 'black',
            },
            {
                contentArr: [t('bud.dailyUseEquipment'), t('bud.moldCosts')],
                color: 'black',
            },
        ],
        [
            {
                content: t('bud.other'),
                color: 'black',
            },
            {
                contentArr: [t('bud.productCosts'), t('bud.membershipRegistration')],
                color: 'black',
            },
        ],
        [
            {
                content: t('bud.patentTrademarkRegistration'),
                color: 'black',
            },
            {
                contentArr: [t('bud.patentTrademarkRegistrationFees')],
                color: 'black',
            },
        ],
        [
            {
                content: t('bud.floorSlabProduction'),
                color: 'black',
            },
            {
                contentArr: [t('bud.preciousMaterials')],
                color: 'black',
            },
        ],
        [
            {
                content: t('bud.transportation'),
                color: 'black',
            },
            {
                contentArr: [
                    t('bud.transportationAccommodationCosts'),
                    t('bud.localTransportationFees'),
                    t('bud.transportationAccommodationCaused'),
                ],
                color: 'black',
            },
        ],
        [
            {
                content: t('bud.marketing'),
                color: 'black',
            },
            {
                contentArr: [
                    t('bud.advertisingExhibitionsPromotional'),
                    t('bud.depositSalesDividendCommission'),
                    t('bud.giftsSouvenirsPrize'),
                    t('bud.entertainmentMealExpenses'),
                ],
                color: 'black',
            },
        ],
    ];

    const [data, setData] = useState(null);

    _addScript('/js/owl-carousel/owl.carousel.min.js');

    useEffect(() => {
        if (data) {
            _addScript('/js/custom.js');
            _addScript('/js/bootstrap.bundle.min.js');
        }
    }, [data]);

    useEffect(() => {
        Services.getBUD({ language: i18n.language }).then((res) => {
            setData(res);
            const menu = res?.menu
                ?.sort((a, b) => {
                    return a?.ordering - b?.ordering;
                })
                ?.map((value) => {
                    return {
                        title: value?.menu_text,
                        href: `#bud-${value?.ordering}`,
                        name: value?.section_name,
                        id: `bud-${value?.ordering}`,
                    };
                });

            setMenu(menu);

            const iconFooter = res?.footer?.map((value) => {
                let image = require('~/assets/images/projectsupportstation/images/whatsapp.png');
                if (value?.name?.toLocaleUpperCase() == 'WhatApps'?.toLocaleUpperCase()) {
                    image = require('~/assets/images/projectsupportstation/images/whatsapp.png');
                }
                if (value?.name?.toLocaleUpperCase() == 'Facebook'?.toLocaleUpperCase()) {
                    image = require('~/assets/images/projectsupportstation/images/facebook.png');
                }
                if (value?.name?.toLocaleUpperCase() == 'Instagram'?.toLocaleUpperCase()) {
                    image = require('~/assets/images/projectsupportstation/images/instagram.png');
                }
                if (value?.name?.toLocaleUpperCase() == 'Linkedin'?.toLocaleUpperCase()) {
                    image = require('~/assets/images/projectsupportstation/images/linkedin.png');
                }

                return {
                    icon: image,
                    href: value?.url,
                    title: value?.name,
                };
            });

            setIconFooter(iconFooter);
        });
    }, []);

    return (
        <>
            {data && (
                <div className="wrapper">
                    <Header menu={menu} logo={logo} page={routes.bud} />

                    <div className="banner">
                        <div className="container-fluid wave wave_banner spacing-0">
                            <div className="row container-fluid spacing-0">
                                <div className="col-12 spacing-0">
                                    <div className="banner-items owl-carousel owl-theme container-fluid spacing-0">
                                        {data?.banner?.map((value, index) => {
                                            return (
                                                <div
                                                    className={`item spacing-0 ${
                                                        value.media_type == 'image' ? '' : 'video'
                                                    }`}
                                                    key={String(index)}
                                                >
                                                    {value.media_type == 'image' ? (
                                                        <img src={value?.url} alt={value?.title} />
                                                    ) : (
                                                        <video
                                                            className="videoContent"
                                                            width="100%"
                                                            height="100%"
                                                            style={{
                                                                position: 'absolute',
                                                                top: 0,
                                                                left: 0,
                                                                objectFit: 'cover',
                                                            }}
                                                            // autoPlay={index == 0 ? true : false}
                                                            // playsInline
                                                            // muted="muted"
                                                            controls
                                                        >
                                                            <source src={value?.url} type="video/mp4" />
                                                            Your browser does not support the video tag.
                                                        </video>
                                                    )}
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <section className={`about bg-cream`} id={menu[0]?.id}>
                        <div className="container-fluid mt-5">
                            {/* <div className="row mt-5"> */}
                            <div className="col-12 text-center mt-5">
                                <h1 className="mb-4 color-red">{menu[0]?.name}</h1>
                            </div>
                            {/* </div> */}
                        </div>

                        <div className={`container ${width < 600 ? 'pb-5' : ''}`}>
                            <div className={`row col-12 ${width < 600 ? 'mb-5' : ''}`}>
                                {data?.fund_scope?.map((value, index) => {
                                    return (
                                        <div
                                            className={`col-md-4 ${
                                                width < 600 && index == data?.fund_scope?.length - 1 ? 'pb-5' : ''
                                            }`}
                                            key={String(index)}
                                        >
                                            <div className="ps-5 pe-5 text-center fun-scope-header">
                                                <img
                                                    src={value?.image}
                                                    alt={value?.title}
                                                    style={{ width: '80%', height: 'auto' }}
                                                />
                                            </div>
                                            <div className="color-orange mb-5 d-flex align-items-end justify-content-center ">
                                                <h1 className="color-red" style={{ fontWeight: 'bold', fontSize: 40 }}>
                                                    {value?.title?.substring(0, 1)}
                                                </h1>
                                                {value?.title?.substring(1)}
                                            </div>
                                            <div className="d-flex justify-content-center">
                                                <ul className="check-circle">
                                                    {value?.content?.map((content, iContent) => {
                                                        return (
                                                            <li
                                                                className={`${content?.color} mb-3 bold-500`}
                                                                key={String(iContent)}
                                                            >
                                                                {content?.content}
                                                            </li>
                                                        );
                                                    })}
                                                </ul>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </section>

                    <section className="service" id={menu[1]?.id}>
                        <div className="container-fluid wave wave_top">
                            {/* <div className="row"> */}
                            <div className="col-12 text-center">
                                <h1 className="mb-4 color-red">{menu[1]?.name}</h1>
                            </div>
                            {/* </div> */}
                        </div>

                        <div className="container">
                            <div className="col-12 desktop" style={{ position: 'relative' }}>
                                <img src={data?.fund_anc[0]?.image_desktop} />
                                <div className="col-12 row" style={{ minHeight: '550px' }}>
                                    <div className="col-2 mt-5 ps-4 pe-4">
                                        <ul className="check-circle">
                                            {data?.fund_anc[0]?.col_1?.map((value, index) => {
                                                return (
                                                    <li className={`${value?.color} mb-3 bold-500`} key={String(index)}>
                                                        {value?.content}
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </div>

                                    <div className="col-2 mt-5 ps-4 pe-4">
                                        <ul className="check-circle">
                                            {data?.fund_anc[0]?.col_2?.map((value, index) => {
                                                return (
                                                    <li className={`${value?.color} mb-3 bold-500`} key={String(index)}>
                                                        {value?.content}
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </div>

                                    <div className="col-2 mt-5 ps-4 pe-4">
                                        <ul className="check-circle">
                                            {data?.fund_anc[0]?.col_3?.map((value, index) => {
                                                return (
                                                    <li className={`${value?.color} mb-3 bold-500`} key={String(index)}>
                                                        {value?.content}
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </div>

                                    <div className="col-2 mt-5 ps-4 pe-4">
                                        <ul className="check-circle">
                                            {data?.fund_anc[0]?.col_4?.map((value, index) => {
                                                return (
                                                    <li className={`${value?.color} mb-3 bold-500`} key={String(index)}>
                                                        {value?.content}
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </div>

                                    <div className="col-2 mt-5 ps-4 pe-4">
                                        <ul className="check-circle">
                                            {data?.fund_anc[0]?.col_5?.map((value, index) => {
                                                return (
                                                    <li className={`${value?.color} mb-3 bold-500`} key={String(index)}>
                                                        {value?.content}
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </div>

                                    <div className="col-2 mt-5 ps-4 pe-4">
                                        <ul className="check-circle">
                                            {data?.fund_anc[0]?.col_6?.map((value, index) => {
                                                return (
                                                    <li className={`${value?.color} mb-3 bold-500`} key={String(index)}>
                                                        {value?.content}
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </div>

                                    <div
                                        className="col-4 mt-5 ps-4 pe-4 d-flex align-items-end  h-auto"
                                        style={{ position: 'absolute', right: 0, bottom: 0 }}
                                    >
                                        <div className="col-8 offset-2">
                                            <img
                                                src={require('~/assets/images/projectsupportstation/images/Gig_Gik_Math.png')}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="container">
                            <div
                                className="col-12 mobile"
                                style={{ position: 'relative', height: `${HEIGHT_IMAGE_MOBILE + 50}px` }}
                            >
                                <div className="col-2">
                                    <img
                                        src={data?.fund_anc[0]?.image_mobile}
                                        style={{ width: '70px', height: `${HEIGHT_IMAGE_MOBILE}px` }}
                                    />
                                </div>
                                <div className="col-10" style={{ height: `${HEIGHT_IMAGE_MOBILE}px` }}>
                                    <div
                                        className="col-12 pt-3 pb-3"
                                        style={{
                                            height: `${HEIGHT_IMAGE_MOBILE / 6}px`,
                                            paddingLeft: '1.7rem',
                                        }}
                                    >
                                        <ul className="check-circle-small-1">
                                            {data?.fund_anc[0]?.col_1?.map((value, index) => {
                                                return (
                                                    <li
                                                        className={`${value?.color} mb-3 bold-500 `}
                                                        key={String(index)}
                                                    >
                                                        {value?.content}
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </div>

                                    <div
                                        className="col-12 pt-3 pb-3"
                                        style={{
                                            height: `${HEIGHT_IMAGE_MOBILE / 6}px`,
                                            paddingLeft: '1.7rem',
                                        }}
                                    >
                                        <ul className="check-circle-small-1">
                                            {data?.fund_anc[0]?.col_2?.map((value, index) => {
                                                return (
                                                    <li
                                                        className={`${value?.color} mb-3 bold-500 `}
                                                        key={String(index)}
                                                    >
                                                        {value?.content}
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </div>

                                    <div
                                        className="col-12 pt-3 pb-3"
                                        style={{
                                            height: `${HEIGHT_IMAGE_MOBILE / 6}px`,
                                            paddingLeft: '1.7rem',
                                        }}
                                    >
                                        <ul className="check-circle-small-1">
                                            {data?.fund_anc[0]?.col_3?.map((value, index) => {
                                                return (
                                                    <li
                                                        className={`${value?.color} mb-3 bold-500 `}
                                                        key={String(index)}
                                                    >
                                                        {value?.content}
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </div>

                                    <div
                                        className="col-12 pt-3 pb-3"
                                        style={{
                                            height: `${HEIGHT_IMAGE_MOBILE / 6}px`,
                                            paddingLeft: '1.7rem',
                                        }}
                                    >
                                        <ul className="check-circle-small-1">
                                            {data?.fund_anc[0]?.col_4?.map((value, index) => {
                                                return (
                                                    <li
                                                        className={`${value?.color} mb-3 bold-500 `}
                                                        key={String(index)}
                                                    >
                                                        {value?.content}
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </div>

                                    <div
                                        className="col-12 pt-3 pb-3"
                                        style={{
                                            height: `${HEIGHT_IMAGE_MOBILE / 6}px`,
                                            paddingLeft: '1.7rem',
                                        }}
                                    >
                                        <ul className="check-circle-small-1">
                                            {data?.fund_anc[0]?.col_5?.map((value, index) => {
                                                return (
                                                    <li
                                                        className={`${value?.color} mb-3 bold-500 `}
                                                        key={String(index)}
                                                    >
                                                        {value?.content}
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </div>

                                    <div
                                        className="col-12 pt-3 pb-3"
                                        style={{
                                            height: `${HEIGHT_IMAGE_MOBILE / 6}px`,
                                            paddingLeft: '1.7rem',
                                        }}
                                    >
                                        <ul className="check-circle-small-1">
                                            {data?.fund_anc[0]?.col_6?.map((value, index) => {
                                                return (
                                                    <li
                                                        className={`${value?.color} mb-3 bold-500 `}
                                                        key={String(index)}
                                                    >
                                                        {value?.content}
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </div>
                                </div>

                                <div className="col-6" style={{ position: 'absolute', right: 0, bottom: '-50px' }}>
                                    <img
                                        src={require('~/assets/images/projectsupportstation/images/Gig_Gik_Math.png')}
                                    />
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="benefits bg-cream" id={menu[2]?.id}>
                        <div className="container-fluid wave wave_top_cream">
                            {/* <div className="row"> */}
                            <div className="col-12 text-center">
                                <h1 className="mb-4 color-red">{menu[2]?.name}</h1>
                            </div>
                            {/* </div> */}
                        </div>

                        <div className="container mb-5 mt-5">
                            {/* <div className="row"> */}
                            <div className="col-12 text-center">
                                <h1 className="mb-4 color-orange">{t('bud.zhijiCanApply')}</h1>
                            </div>
                            {/* </div> */}
                        </div>

                        <div className="container border pt-3 pb-3 rounded-5 bg-white justify-content-center mb-5  benefits_table">
                            <div className="row pb-4 pt-3 border-bottom">
                                <div className="col-6 color-orange">
                                    <strong>{t('bud.projectMeasures')}</strong>
                                </div>

                                <div className="col-6 content-table bold-500">
                                    <strong className="color-orange">{t('bud.budgetFundingRatioCeiling')}</strong>
                                    <span>{t('bud.calculatedApplicationProject')}</span>
                                </div>
                            </div>

                            {coveringTable?.map((value, index) => {
                                return (
                                    <div
                                        className={`row pb-3 pt-3 ${index % 2 == 1 ? 'bg-cream' : ''}`}
                                        key={String(index)}
                                    >
                                        {value?.map((item, iItem) => {
                                            return (
                                                <div
                                                    className={`col-6 color-${item?.color} content-table bold-500`}
                                                    key={`${String(index)}-${String(iItem)}`}
                                                    dangerouslySetInnerHTML={{ __html: item?.content }}
                                                ></div>
                                            );
                                        })}
                                    </div>
                                );
                            })}

                            <div>
                                <div
                                    style={{
                                        position: 'absolute',
                                        top: width < 600 ? '-7vw' : '-3.5vw',
                                        zIndex: 3,
                                        right: '0',
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                    }}
                                >
                                    <img
                                        src={require('~/assets/images/projectsupportstation/images/Gig_Gik_Tick.png')}
                                        style={{ width: 'auto', height: width < 600 ? '14vw' : '7vw' }}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="container mt-5 mb-5">
                            {/* <div className="row mt-5"> */}
                            <div className="col-12 text-center mt-5">
                                <h1 className="mb-4 color-orange">{t('bud.commonProjectsEligibleFunding')}</h1>
                            </div>
                            {/* </div> */}
                        </div>

                        <div className="container border pt-3 pb-3 rounded-5 bg-white justify-content-center mb-5  benefits_table">
                            <div className="row pb-4 pt-3 border-bottom">
                                <div className="col-4 color-orange">
                                    <strong>{t('bud.projectMeasures')}</strong>
                                </div>

                                <div className="col-8">
                                    <strong className="color-orange">{t('bud.projectsInclude')}</strong>
                                </div>
                            </div>

                            {notCoveringTable?.map((value, index) => {
                                return (
                                    <div
                                        className={`row pb-3 pt-3 ${index % 2 == 1 ? 'bg-cream' : ''}`}
                                        key={String(index)}
                                    >
                                        {value?.map((item, iItem) => {
                                            if (item?.content) {
                                                return (
                                                    <div
                                                        className={`col-4 color-${item?.color} bold-500`}
                                                        key={`${String(index)}-${String(iItem)}`}
                                                    >
                                                        {item?.content}
                                                    </div>
                                                );
                                            }

                                            return (
                                                <div
                                                    className={`col-8 color-${item?.color}`}
                                                    key={`${String(index)}-${String(iItem)}`}
                                                >
                                                    <ul className="dots">
                                                        {item?.contentArr?.map((content, iContent) => {
                                                            return (
                                                                <li
                                                                    key={`${String(index)}-${String(iItem)}-${String(
                                                                        iContent,
                                                                    )}`}
                                                                    className="bold-500"
                                                                >
                                                                    {content}
                                                                </li>
                                                            );
                                                        })}
                                                    </ul>
                                                </div>
                                            );
                                        })}
                                    </div>
                                );
                            })}

                            <div>
                                <div
                                    style={{
                                        position: 'absolute',
                                        top: width < 600 ? '-7vw' : '-3.5vw',
                                        zIndex: 3,
                                        right: '0',
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                    }}
                                >
                                    <img
                                        src={require('~/assets/images/projectsupportstation/images/Gig_Gik_Cross.png')}
                                        style={{ width: 'auto', height: width < 600 ? '14vw' : '7vw' }}
                                    />
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="service" id={menu[3]?.id}>
                        <div className="container-fluid wave wave_top">
                            {/* <div className="row"> */}
                            <div className="col-12 text-center">
                                <h1 className="mb-4 color-red">{menu[3]?.name}</h1>
                            </div>
                            {/* </div> */}
                        </div>

                        <div className="container mt-5 mb-5">
                            <div className="row col-12 align-items-center ">
                                <div className="col-md-8 intro-padding-end p-5">
                                    <h3
                                        className="color-red mb-5"
                                        dangerouslySetInnerHTML={{ __html: data['bud-assessment'][0]?.title }}
                                    ></h3>

                                    <div
                                        dangerouslySetInnerHTML={{ __html: data['bud-assessment'][0]?.content }}
                                        className="bold-500"
                                    ></div>
                                </div>
                                <div className="col-md-4 intro-padding-start">
                                    <img
                                        src={data['bud-assessment'][0]?.image}
                                        alt={data['bud-assessment'][0]?.title}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="container-fluid mt-5 mb-5" id={menu[4]?.id}>
                            {/* <div className="row"> */}
                            <div className="col-12 text-center">
                                <h1 className="mb-4 color-red">{menu[4]?.name}</h1>
                            </div>
                            {/* </div> */}
                        </div>

                        <div className="container mb-5">
                            <div className={`col-12 row ${width < 600 ? 'spacing-start-0' : ''}`}>
                                {data?.approval?.map((value, index) => {
                                    let image = require('~/assets/images/projectsupportstation/images/Gig_Gik_Number_4.png');
                                    switch (index + 1) {
                                        case 1:
                                            image = require('~/assets/images/projectsupportstation/images/Gig_Gik_Number_1.png');
                                            break;
                                        case 2:
                                            image = require('~/assets/images/projectsupportstation/images/Gig_Gik_Number_2.png');
                                            break;
                                        case 3:
                                            image = require('~/assets/images/projectsupportstation/images/Gig_Gik_Number_3.png');
                                            break;
                                        case 4:
                                            image = require('~/assets/images/projectsupportstation/images/Gig_Gik_Number_4.png');
                                            break;
                                    }
                                    return (
                                        <div className="col-md-4 spacing-0" key={String(index)}>
                                            <div className="row">
                                                <div
                                                    className={`d-flex flex-column ${
                                                        width < 600 ? 'spacing-0' : ''
                                                    } align-items-center col-md-12`}
                                                >
                                                    <div className="col-12 d-flex justify-content-center">
                                                        <div
                                                            className={`${
                                                                width < 600 ? 'col-12' : 'col-10'
                                                            } d-flex flex-column align-items-center`}
                                                        >
                                                            <img src={image} className="col-8" />
                                                        </div>

                                                        {width >= 600 && (
                                                            <div className="col-2 icon-next justify-content-end align-items-center">
                                                                {index < 2 && (
                                                                    <img
                                                                        src={require('~/assets/images/projectsupportstation/images/arrow-forward.png')}
                                                                        style={{ width: '20px', height: 'auto' }}
                                                                    />
                                                                )}
                                                            </div>
                                                        )}
                                                    </div>
                                                    <ul
                                                        className={`check-circle mt-5 ${
                                                            width < 600 ? 'ps-4 col-12' : 'col-10'
                                                        } `}
                                                    >
                                                        {value?.content?.map((content, iContent) => {
                                                            return (
                                                                <li
                                                                    className={`${content?.color} mb-3 bold-500`}
                                                                    key={String(iContent)}
                                                                >
                                                                    {content?.content}
                                                                </li>
                                                            );
                                                        })}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </section>

                    <section className="partial-contact bg-cover py-5" id={menu[5]?.id}>
                        <div className="container-fluid">
                            <div className="row">
                                <div
                                    className={`col-12 text-center p5 ${
                                        width < 600 ? 'd-flex flex-column align-items-center ' : ''
                                    }`}
                                >
                                    <a
                                        href="https://sys.giggik.com/index.php?c=Form&a=bud&source_id=13"
                                        target="_blank"
                                        className={`btn btn-default btn-large ${width < 600 ? 'mb-4' : ''}`}
                                    >
                                        {t('bud.freeEvaluation')}
                                    </a>

                                    <a
                                        href="https://sys.giggik.com/index.php?c=Form&a=bud&source_id=13"
                                        target="_blank"
                                        className={`btn btn-default btn-large ${width < 600 ? 'mb-2' : ''}`}
                                    >
                                        {t('bud.contactUs')}
                                    </a>
                                    <img
                                        src={require('~/assets/images/projectsupportstation/images/Gig_Gik.png')}
                                        className="Gig_Gik"
                                    />
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="faq" id={menu[6]?.id}>
                        <div className="container-fluid wave wave_bottom">
                            <div className="container content-faq">
                                {/* <div className="row"> */}
                                <div className="col-12 text-center">
                                    <h1 className="mb-4 color-red">{menu[6]?.name}</h1>
                                </div>
                                {/* </div> */}
                                <div className="row">
                                    <div className="col-12">
                                        <div className="accordion accordion-flush" id="accordionFlushExample">
                                            {data?.faq?.map((value, index) => {
                                                return (
                                                    <div className="accordion-item" key={String(index)}>
                                                        <div
                                                            className="accordion-header"
                                                            style={{ marginBottom: '30px' }}
                                                        >
                                                            <button
                                                                className="accordion-button collapsed"
                                                                type="button"
                                                                data-bs-toggle="collapse"
                                                                data-bs-target={`#flush-collapse${index}`}
                                                                aria-expanded="false"
                                                                aria-controls={`flush-collapse${index}`}
                                                                // style={{ backgroundColor: '#f8c03d' }}
                                                            >
                                                                <img
                                                                    src={require('~/assets/images/projectsupportstation/images/Gig_Gik_question.png')}
                                                                    className="icon"
                                                                />
                                                                {value?.category}
                                                            </button>
                                                        </div>

                                                        <div
                                                            id={`flush-collapse${index}`}
                                                            className="accordion-collapse collapse ps-5"
                                                            data-bs-parent="#accordionFlushExample"
                                                        >
                                                            {value?.question?.map((question, iQuestion) => {
                                                                return (
                                                                    <div
                                                                        key={String(iQuestion)}
                                                                        style={{ marginBottom: '30px' }}
                                                                    >
                                                                        <button
                                                                            className="accordion-button spacing-0"
                                                                            type="button"
                                                                            data-bs-toggle="collapse"
                                                                            data-bs-target={`#flush-sub-collapse${index}${iQuestion}`}
                                                                            aria-expanded="false"
                                                                            aria-controls={`flush-sub-collapse${index}${iQuestion}`}
                                                                        >
                                                                            {/* <img
                                                                        src={require('~/assets/images/projectsupportstation/images/Gig_Gik_question.png')}
                                                                        className="icon"
                                                                    /> */}
                                                                            {question?.question}
                                                                        </button>
                                                                        <div
                                                                            id={`flush-sub-collapse${index}${iQuestion}`}
                                                                            className="accordion-collapse collapse"
                                                                            data-bs-parent={`#flush-collapse${index}`}
                                                                        >
                                                                            <div className="accordion-body">
                                                                                {question?.answer}
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-12 row mt-3 align-items-center">
                                                                            <div className="col-1 border-bottom-red-3"></div>
                                                                            <div className="col-11 border-bottom"></div>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <Footer logo={data?.sticky_logo[0]} iconFooter={iconFooter} />
                </div>
            )}
        </>
    );
};

export default BUD;
