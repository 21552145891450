const routes = {
    home: '/',
    following: '/following',
    profile: '/@:nickname',
    upload: '/upload',
    search: '/search',
    live: '/live',

    // menu routes
    profile: '/profile',
    guide: '/guide',
    help: '/help',
    about: '/about',

    // user routes
    login: '/login',
    logout: '/logout',
    signup: '/signup',

    // header
    popularSearch: '/popular-search',

    // footer
    candidate: '/candidate',
    employer: '/employer',
    moreInformation: '/more-information',
    recruitment: '/recruitment',
    contactInfo: 'contact-information',

    projectSupportStation: '/outsourcing',
    humanResourceStation: '/remotestaffing',
    xiaoHongShu: '/xiaohongshu',
    bud: '/budfunding',
};

export default routes;
