import { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import classNames from 'classnames/bind';
import Button from '~/components/Button';
import Carousel from '~/components/Carousel';
import HomeSection from '~/components/HomeSection/HomeSection';
import _JobSection from '~/components/_JobSection';
import _HotJob from '~/components/_HotJob';
import Recommend from '~/components/Recommend';
import { ShortLogoIcon } from '~/components/Icons';

import styles from './Home.module.scss';
import _Featured from '~/components/_Featured';
import _Popular from '~/components/_Popular';

import * as homepageService from '~/services/homepageService';
import * as jobService from '~/services/jobService';
import Popup from '~/layouts/components/Popup';

import { open } from '~/reducers/popupReducer';
import { Helmet } from 'react-helmet';
import HomeEmblaCarousel from '~/components/HomeEmblaCarousel/HomeEmblaCarousel';

import Filter from '~/layouts/components/Filter';
import MenuExpand from '~/components/NavMenu/MenuExpand';
import {
    FilterCollapseArrowIcon,
} from '~/components/Icons';

import { HeaderContext } from '~/ThemeContext';

import DetectItemShow from '~/components/DetectItemShow';

const cx = classNames.bind(styles);

const jobSectionTypeColor = {
    full: undefined,
    part: '#FFC700',
    short: '#FF9F47',
};

function Home() {

    const filterContext = useContext(HeaderContext);
    const [jobSectionsData, setJobSectionsData] = useState([]);
    const [banners, setBanners] = useState({
        search: undefined,
        group: undefined,
        job: undefined,
        slash: undefined,
        carousel: [],
    });
    const { t, i18n } = useTranslation();
    const auth = useSelector((state) => state.auth);
    const popup = useSelector((state) => state.popup);
    const dispatch = useDispatch();

    useEffect(() => {
        homepageService.loadJobSections().then((result) => {
            if (result?.result) {
                setJobSectionsData(result?.result);
            }
        })
        homepageService.loadAllBanners().then((data) => {
            setBanners({ ...data })
        })
    }, [i18n.language]);

    const handleSaveJob = (section_id, job_id) => async (e) => {
        e.preventDefault();
        if (auth?.loggedIn) {
            const res = await jobService.saveJob(job_id);
            if (res.status === 'success') {
                let index = jobSectionsData.findIndex((section) => section.section_id === section_id);
                if (index !== -1) {
                    let jobIndex = jobSectionsData[index]?.jobs.findIndex((job) => job?.header?.job_id === job_id);
                    if (jobIndex !== -1) {
                        let job = jobSectionsData[index].jobs[jobIndex];
                        job.header.isFavorite = !job.header.isFavorite;
                        let newJobsectionData = [...jobSectionsData];
                        newJobsectionData[index].jobs[jobIndex] = job;
                        setJobSectionsData(newJobsectionData);
                    }
                }
            }
        } else {
            dispatch(open('login'));
        }
    };

    const handleJobClick = (object_value, object_id) => () => {
        homepageService.itemClick({
            section: 'homepage',
            sub_section: 'Side Banner',
            object_type: 'banner',
            object_value: object_value,
            object_id: object_id,
        })
    }

    return (
        <div className="home-wrapper">
            <Helmet>
                <title>{t('helmet.home.giggik_slogan')}</title>
            </Helmet>

            <div className={cx('searchTextContainer', 'menu-expand')}>
                <div className="container">
                    <MenuExpand banner={banners.search} />
                </div>
            </div>
            <div className={`${styles['filter']} menu-filter ${filterContext?.showFilter ? styles['expand'] : ''}`}>
                <div className="container">
                    <div
                        className={`${styles.filterFormContainer} ${filterContext?.showFilter ? styles['expand'] : ''
                            }`}
                    >
                        <Filter />
                        <div className={styles.collapseBtn}>
                            <button
                                onClick={filterContext.handleShowFilter}
                                style={{
                                    backgroundColor: 'transparent',
                                }}
                            >
                                <FilterCollapseArrowIcon />
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div className={cx('home-banners', 'home-banner')}>
                <div className="container">
                    <div className="row">
                        <div className={cx('carousel', 'home-banner-override')}>
                            {/* <Carousel
                                itemsInSlide={1}
                                disableButtonsControls
                                autoPlay={true}
                                autoPlayInterval={3000}
                                infinite={true}
                            /> */}
                            <HomeEmblaCarousel banners={banners.carousel} />
                        </div>
                        <div className={cx('banner', 'only-lg', '__block')}>
                            <div className={cx('item')}>
                                {
                                    banners.group &&
                                    <DetectItemShow data={{
                                        section: 'homepage',
                                        sub_section: 'Side Banner',
                                        object_type: 'banner',
                                        object_value: 'group',
                                        object_id: banners.group?.id,
                                    }} />
                                }
                                {
                                    banners.group &&
                                    <a href={banners.group.url ? banners.group.url : "https://bit.ly/職極GigGik_Whatsapp_group"} onClick={handleJobClick('group', banners.group.id)} target="_blank">
                                        <img src={banners.group.src} alt="職極GigGik Whatsapp group" />
                                    </a>
                                }
                            </div>
                            <div className={cx('item')}>
                                {
                                    banners.job && banners.job.id &&
                                    <DetectItemShow data={{
                                        section: 'homepage',
                                        sub_section: 'Side Banner',
                                        object_type: 'banner',
                                        object_value: 'job',
                                        object_id: banners.job?.id,
                                    }} />
                                }
                                {
                                    banners.job &&
                                    <a href={banners.job.url ? banners.job.url : "https://docs.google.com/forms/d/1rXDO4sT0REXCaK44765iCY88ip1ziG-YFFvAhgo5cTA/viewform?edit_requested=true"} onClick={handleJobClick('job', banners.job.id)} target="_blank">
                                        <img src={banners.job.src} alt="top-banner-1" />
                                    </a>
                                }
                            </div>
                            <div className={cx('item')}>
                                {
                                    banners.slash && banners.slash.id &&
                                    <DetectItemShow data={{
                                        section: 'homepage',
                                        sub_section: 'Side Banner',
                                        object_type: 'banner',
                                        object_value: 'slash',
                                        object_id: banners.slash?.id,
                                    }} />
                                }
                                {
                                    banners.slash &&
                                    <a href={banners.slash.url ? banners.slash.url : `${process.env.REACT_APP_NEWGIG_URL}/${i18n.languages[0]}/find-gigs`} onClick={handleJobClick('slash', banners.slash.id)} target="_blank">
                                        <img src={banners.slash.src} alt="top-banner-1" />
                                    </a>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <HomeSection
                    title={t('recommendToYou.vocationalSkillsGuide.recommendations')}
                    background="yellow"
                    optionClass={'_recommend'}
                    headlineEnglishType={0}
                >
                    <div className={cx('inner')}>
                        <Recommend />
                    </div>
                </HomeSection>
            </div>

            {jobSectionsData.map((jobSectionData, index) => (
                <div className="container" key={`homepage-job-section-${index}`}>
                    {jobSectionData.type == 'popular' ? (
                        <HomeSection
                            title={t('hotJobRecruitment.popularPosition')}
                            longBgTitle
                            background="blue"
                            optionClass={'_hotJob'}
                            headlineEnglishType={1}
                        >
                            <div className={cx('inner')}>
                                <_HotJob
                                    sectionData={jobSectionData}
                                    type={jobSectionData.type}
                                    handleSaveJob={handleSaveJob}
                                />
                            </div>
                        </HomeSection>
                    ) : (
                        <HomeSection
                            // title={`${jobSectionData[`name_${pathname.replace('/', '')}`]}`}
                            optionClass={'_jobsection'}
                        >
                            <div className={cx('inner')}>
                                <_JobSection
                                    sectionData={jobSectionData}
                                    type={jobSectionData.type}
                                    titleIconColor={jobSectionTypeColor[jobSectionData.type]}
                                    handleSaveJob={handleSaveJob}
                                />
                            </div>
                        </HomeSection>
                    )}
                </div>
            ))}

            <div className="container">
                <div className={cx('view-all-btn', 'view-all-btn-style')}>
                    <Button
                        rounded
                        primary
                        href={`${process.env.REACT_APP_BASE_URL}/${i18n.languages[0]}/search?keyword=&searchType=all`}
                        onClick={() => {
                            homepageService.itemClick({
                                section: 'homepage',
                                sub_section: 'Job',
                                object_type: 'job',
                                object_value: t('homepage.viewAllJob'),
                            })
                        }}
                    >
                        {t('homepage.viewAllJob')}
                    </Button>
                </div>

                <div className={cx('logo-center-page')}>
                    <ShortLogoIcon />
                </div>
            </div>

            <div className={cx('featured-and-popular-module')}>
                <div className="container">
                    <div className="row">
                        <HomeSection
                            title={t('company.title')}
                            background="yellow"
                            optionClass={'_featured'}
                            optionClassTitle={'option-title'}
                            headlineEnglishType={2}
                        >
                            <_Featured />
                        </HomeSection>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <HomeSection
                            title={t('popularRecruitment.title')}
                            longBgTitle
                            background="blue"
                            optionClass={'_popular'}
                            optionClassTitle={'option-title'}
                            headlineEnglishType={3}
                        >
                            <_Popular />
                        </HomeSection>
                    </div>
                </div>
            </div>

            <div
                className={cx('mobile-banner', 'only-xs', '__flex-center', 'flex-center__column')}
                style={{
                    marginTop: '56px',
                    marginBottom: '51px',
                }}
            >
                <div className={cx('item')}>
                    {
                        banners.group && banners.group.id &&
                        <DetectItemShow data={{
                            section: 'homepage',
                            sub_section: 'Side Banner',
                            object_type: 'banner',
                            object_value: 'group',
                            object_id: banners.group?.id,
                        }} />
                    }
                    {
                        banners.group &&
                        <a href={banners.group.url ? banners.group.url : "https://bit.ly/職極GigGik_Whatsapp_group"} onClick={handleJobClick('group', banners.group.id)} target="_blank">
                            <img src={banners.group.src} alt="職極GigGik Whatsapp group" />
                        </a>
                    }
                </div>
                <div className={cx('item')}>
                    {
                        banners.job && banners.job.id &&
                        <DetectItemShow data={{
                            section: 'homepage',
                            sub_section: 'Side Banner',
                            object_type: 'banner',
                            object_value: 'job',
                            object_id: banners.job?.id,
                        }} />
                    }
                    {
                        banners.job &&
                        <a data-id={banners?.job?.id} href={banners.job.url ? banners.job.url : "https://docs.google.com/forms/d/1rXDO4sT0REXCaK44765iCY88ip1ziG-YFFvAhgo5cTA/viewform?edit_requested=true"} onClick={handleJobClick('job', banners.job.id)} target="_blank">
                            <img src={banners.job.src} alt="top-banner-1" />
                        </a>
                    }
                </div>
                <div className={cx('item')}>
                    {
                        banners.slash && banners.slash.id &&
                        <DetectItemShow data={{
                            section: 'homepage',
                            sub_section: 'Side Banner',
                            object_type: 'banner',
                            object_value: 'slash',
                            object_id: banners.slash?.id,
                        }} />
                    }
                    {
                        banners.slash &&
                        <a data-id={banners?.slash?.id} href={banners.slash.url ? banners.slash.url : `${process.env.REACT_APP_NEWGIG_URL}/${i18n.languages[0]}/find-gigs`} onClick={handleJobClick('slash', banners.slash.id)} target="_blank">
                            <img src={banners.slash.src} alt="top-banner-1" />
                        </a>
                    }
                </div>
            </div>

            <Popup show={popup.show} modal={popup.type} />
        </div>
    );
}

export default Home;
