import React, { memo, useState, useEffect } from 'react';
import classNames from 'classnames/bind';
import styles from '../Filter.module.scss';
const cx = classNames.bind(styles);

const RangeSlider = memo(({ classes, label, onChange, value, ...sliderProps }) => {
    const [sliderVal, setSliderVal] = useState(0);
    const [mouseState, setMouseState] = useState(null);

    useEffect(() => {
        console.log(value);
        setSliderVal(value);
    }, [value]);

    const changeCallback = (e) => {
        setSliderVal(e.target.value);
        onChange(e.target.value);
    };

    useEffect(() => {
        if (mouseState === 'up') {
            onChange(sliderVal);
        }
    }, [mouseState]);

    useEffect(() => {
        const rangeInputs = document.querySelectorAll('input[type="range"]');

        function handleInputChange(e) {
            let target = e.target;
            const min = target.min;
            const max = target.max;
            const val = target.value;

            target.style.backgroundSize = ((val - min) * 100) / (max - min) + '% 100%';
        }

        if (rangeInputs) {
            rangeInputs.forEach((input) => {
                input.addEventListener('input', handleInputChange);
            });
            var event = new Event('input', {
                bubbles: true,
                cancelable: true,
            });

            rangeInputs[0].dispatchEvent(event);
        }
    });

    return (
        <div className={cx('range-slider')}>
            <input
                style={{ width: '100%' }}
                type="range"
                list="tickmarks"
                value={sliderVal}
                {...sliderProps}
                className={`slider ${classes}`}
                id="tickmarks"
                onChange={changeCallback}
                onMouseDown={() => setMouseState('down')}
                onMouseUp={() => setMouseState('up')}
            />
        </div>
    );
});
export default RangeSlider;
