import classNames from 'classnames/bind';
import React, { useState, useMemo, useCallback, useRef } from 'react';
import { useForm } from 'react-hook-form';
import RangeSlider from './SlideRange';
import useOnClickOutside from '~/hooks/useClickOutside';
import { ReactComponent as IconFilterCurrency } from './../../../../assets/images/svgIcons/filter/filter-currency.svg';
import { ReactComponent as ChevronDown } from './../../../../assets/images/svgIcons/chevron-down.svg';
import { ReactComponent as ChevronUp } from './../../../../assets/images/svgIcons/chevron-up.svg';
import styles from '../Filter.module.scss';
import { useTranslation } from 'react-i18next';
const cx = classNames.bind(styles);

function SalaryFilter() {
    const [parentVal, setParentVal] = useState(10);
    const [salaryType, setSalaryType] = useState(0); // 0 mean === Hour , 1 mean === Month
    const [currencyType, setCurrencyType] = useState(0); // 0 mean === HKD , 1 mean === RMB
    const [dropdownType, setDropdownType] = useState(-1);
    const [isDisplayDropdown, setIsDisplayDropdown] = useState(false);
    const sliderValueChanged = (val) => {
        setParentVal(val);
    };
    const dropdownRef = useRef();
    const { t } = useTranslation();

    const sliderProps = useMemo(
        () => ({
            min: salaryType === 0 ? 37.5 : 0,
            max: salaryType === 0 ? 100 : 100000,
            value: parentVal,
            step: 0.1,
            label: '',
            onChange: (e) => sliderValueChanged(e),
        }),
        [parentVal],
    );
    const currencyTypeLabel = currencyType === 0 ? 'HKD' : 'RMB';
    const handleSelectCurrency = (type) => {
        setIsDisplayDropdown(false);
        setCurrencyType(type);
    };
    const handleSelectSalaryType = (type) => {
        setSalaryType(type);
        setParentVal(type === 0 ? 37.5 : 0);
    };

    const sliderRangelabels = () => {
        const monthLabels = [0, 15, 30, 50, 70, 100];
        const monthLabelWIdth = [15, 15, 20, 20, 30, 0];

        const hourLabels = ['37.5', '50', '60', '70', '80', '90', '100+'];
        const hourLabelWIdth = [18.75, 16.25, 16.25, 16.25, 16.25, 16.25, 0];
        if (salaryType === 1) {
            return (
                <div className={cx('month-labels')} style={{ position: 'relative' }}>
                    {monthLabels.map((label, index) => (
                        <div
                            key={index}
                            style={{
                                width: index === monthLabels.length - 1 ? 'fit-content' : `${monthLabelWIdth[index]}%`,
                                position: index === monthLabels.length - 1 ? 'absolute' : 'static',
                                right: 0,
                                top: 0,
                            }}
                        >{`${label}K`}</div>
                    ))}
                </div>
            );
        }
        return (
            <div id="tickmarks" className={cx('hour-labels')} style={{ position: 'relative' }}>
                {hourLabels.map((label, index) => (
                    <div
                        style={{
                            width: index === monthLabels.length ? 'fit-content' : `${hourLabelWIdth[index]}%`,
                            position: index === monthLabels.length ? 'absolute' : 'static',
                            right: 0,
                            top: 0,
                        }}
                    >{`${label}`}</div>
                ))}
            </div>
        );
    };
    const renderDropdown = () => {
        if (dropdownType === -1 || isDisplayDropdown === false) {
            return <div></div>;
        }
        if (dropdownType === 0) {
            return (
                <div className={cx('currency-type-select')}>
                    <div
                        className={`${cx('currency-type-select-item')} ${currencyType === 0 && cx('selected')}`}
                        onClick={() => handleSelectCurrency(0)}
                    >
                        <div className={cx('prefix')}></div>
                        HKD
                    </div>
                    <div
                        className={`${cx('currency-type-select-item')} ${currencyType === 1 && cx('selected')}`}
                        onClick={() => handleSelectCurrency(1)}
                    >
                        <div className={cx('prefix')}></div>
                        RMB
                    </div>
                </div>
            );
        }
        return (
            <div>
                <div className={cx('salary-type-select')}>
                    <div className={cx('salary-labels')}>
                        <div
                            className={cx(`${salaryType === 1 && 'active'}`)}
                            onClick={() => handleSelectSalaryType(1)}
                        >
                            {t('header.filter.monthly_salary')}
                        </div>
                        <div
                            className={cx(`${salaryType === 0 && 'active'}`)}
                            onClick={() => handleSelectSalaryType(0)}
                        >
                            {t('header.filter.hourly_salary')}
                        </div>
                    </div>
                    <RangeSlider {...sliderProps} />
                    <div>{sliderRangelabels()}</div>
                </div>
            </div>
        );
    };

    useOnClickOutside(dropdownRef, () => setIsDisplayDropdown(false));

    return (
        <div ref={dropdownRef} className={cx('salaryFilter-wrapper')}>
            <div className={cx('salaryFilter-value-wrapper')}>
                <div className={cx('salaryFilter-value')}>
                    <div
                        className={cx('salaryFilter-currency-type')}
                        onClick={() => {
                            setDropdownType(0);
                            setIsDisplayDropdown(true);
                        }}
                    >
                        <IconFilterCurrency />
                        {currencyTypeLabel}
                        {isDisplayDropdown ? <ChevronUp /> : <ChevronDown />}
                    </div>
                    <div
                        style={{ flexGrow: 1 }}
                        onClick={() => {
                            setDropdownType(1);
                            setIsDisplayDropdown(true);
                        }}
                    >
                        {`${t('header.filter.salary_to')}${parentVal}${
                            salaryType === 0 ? (parentVal >= 100 ? '+' : '') : 'K'
                        }`}
                    </div>
                </div>
                <div>{renderDropdown()}</div>
            </div>
        </div>
    );
}

export default SalaryFilter;
