import axios from 'axios';

const httpRequest = axios.create({
    baseURL: process.env.REACT_APP_BASE_API,
});

export const get = async (path, options = {}) => {
    const i18nextLng = localStorage.getItem('i18nextLng')
    let locale = 'en'
    if (i18nextLng) {
        if (['en', 'tc', 'sc'].includes(i18nextLng)) {
            locale = i18nextLng
        }
    }

    let token = localStorage.getItem('token')
    if (token) {
        token = 'Bearer ' + JSON.parse(token)
    }

    options = {
        headers: {
            Authorization: token,
            Locale: locale
        },
        ...options
    }

    const response = await httpRequest.get(path, options);
    return response.data;
};

export const post = async (path, options = {}) => {
    const i18nextLng = localStorage.getItem('i18nextLng')
    let locale = 'en'
    if (i18nextLng) {
        if (['en', 'tc', 'sc'].includes(i18nextLng)) {
            locale = i18nextLng
        }
    }

    let token = localStorage.getItem('token')
    if (token) {
        token = 'Bearer ' + JSON.parse(token)
    }

    const response = await httpRequest.post(path, options, {
        headers: {
            Authorization: token,
            Locale: locale
        }
    });


    if (response.headers.authorization) localStorage.setItem('token', JSON.stringify(response.headers.authorization));

    return response.data;
};
