import { createSlice } from '@reduxjs/toolkit'

export const chatroomSlice = createSlice({
    name: 'chatroom',
    initialState: {
        open: false,
        chat: '',
        chatList: []
    },
    reducers: {
        updateChat: (state, action) => {
            return {
                ...state
            }
        },
        toggleChatroom: (state, action) => {
            return {
                ...state,
                open: !state.open
            }
        },
    },
})

// Action creators are generated for each case reducer function
export const { updateChat, toggleChatroom } = chatroomSlice.actions

export default chatroomSlice.reducer