const images = {
    headerLine: {
        1: require('~/assets/images/svgIcons/englishHeadline1.svg').default,
        2: require('~/assets/images/svgIcons/englishHeadline2.svg').default,
        3: require('~/assets/images/svgIcons/englishHeadline3.svg').default,
        4: require('~/assets/images/svgIcons/englishHeadline4.svg').default,
    },
    // header
    logo: require('~/assets/images/logo.svg').default,
    logoIcon: require('~/assets/images/logo-2x.png'),
    logoIconEn: require('~/assets/images/logo-2x-en.png'),
    noImage: require('~/assets/images/no-image.png'),
    headerBanner: require('~/assets/images/menu_expand_right_banner.svg').default,
    topBannerCarousel1: require('~/assets/images/homepage/carousel/top-banner-carousel-1.jpeg'),
    topBannerCarousel2: require('~/assets/images/homepage/carousel/top-banner-carousel-2.jpeg'),
    topBannerCarousel3: require('~/assets/images/homepage/carousel/top-banner-carousel-3.jpeg'),
    topBannerCarousel4: require('~/assets/images/homepage/carousel/top-banner-carousel-4.jpeg'),
    topBannerCarousel5: require('~/assets/images/homepage/carousel/top-banner-carousel-5.jpeg'),
    topBannerCarousel6: require('~/assets/images/homepage/carousel/top-banner-carousel-6.jpeg'),
    topBannerCarousel7: require('~/assets/images/homepage/carousel/top-banner-carousel-7.jpeg'),
    topBannerSmall1: require('~/assets/images/top-banner-small-1.svg').default,
    topBannerSmall2: require('~/assets/images/top-banner-small-2.svg').default,
    topBannerSmall3: require('~/assets/images/top-banner-small-3.svg').default,
    topBannerSmall2_en: require('~/assets/images/topBannerSmall1_en.svg').default,
    topBannerSmall3_en: require('~/assets/images/topBannerSmall2_en.svg').default,
    topBannerSmall1_en: require('~/assets/images/topBannerSmall3_en.svg').default,
    topBannerSmall1_sc: require('~/assets/images/topBanner1_sc.svg').default,
    topBannerSmall2_sc: require('~/assets/images/topBanner2_sc.svg').default,
    topBannerSmall3_sc: require('~/assets/images/topBanner3_sc.svg').default,
    menuGigGig: require('~/assets/images/menu-giggig.svg').default,
    // footer
    footerBackground: require('~/assets/images/footer-background.svg').default,
    footerBackgroundMobile: require('~/assets/images/mobile/footerBackgroundMobile.svg').default,

    // default: tc
    footerContact: require('~/assets/images/footer/tc/web/mail.png'),
    footerFollow: require('~/assets/images/footer/tc/web/follow.png'),
    footerChat: require('~/assets/images/footer/tc/web/chat.png'),
    // sc
    footerContact_sc: require('~/assets/images/footer/sc/web/mail.png'),
    footerFollow_sc: require('~/assets/images/footer/sc/web/follow.png'),
    footerChat_sc: require('~/assets/images/footer/sc/web/chat.png'),
    // en
    footerContact_en: require('~/assets/images/footer/en/web/mail.png'),
    footerFollow_en: require('~/assets/images/footer/en/web/follow.png'),
    footerChat_en: require('~/assets/images/footer/en/web/chat.png'),

    footerScrollTopBtn: require('~/assets/images/footer-scroll-top-btn.svg').default,

    // homepage
    homepage: {
        recommendArticle1Icon: require('~/assets/images/homepage/svg-images/recommend-icon1.svg').default,
        recommendArticle2Icon: require('~/assets/images/homepage/svg-images/recommend-icon2.svg').default,
        recommendArticle3Icon: require('~/assets/images/homepage/svg-images/recommend-icon3.svg').default,
        recommendArticle4Icon: require('~/assets/images/homepage/svg-images/recommend-icon4.svg').default,

        recommendArticle1Thumb1: require('~/assets/images/homepage/recommend-article1-thumb1.png'),
        recommendArticle1Thumb2: require('~/assets/images/homepage/recommend-article1-thumb2.png'),
        recommendArticle2Thumb1: require('~/assets/images/homepage/recommend-article2-thumb1.png'),
        recommendArticle2Thumb2: require('~/assets/images/homepage/recommend-article2-thumb2.png'),
        // recommendArticle3Thumb: require('~/assets/images/homepage/recommend-article3-thumb.png'),
        recommendArticle3Thumb: require('~/assets/images/homepage/recommend_banner_CV_ALL_version.svg').default,
        recommendArticle4ThumbEn: require('~/assets/images/homepage/recommend_banner_slash_EN.svg').default,
        recommendArticle4ThumbTc: require('~/assets/images/homepage/recommend_banner_slash_TC.svg').default,
        recommendArticle4ThumbSc: require('~/assets/images/homepage/recommend_banner_slash_SC.svg').default,

        hotJobArticle1Thumb: require('~/assets/images/homepage/hotjob1-logo.png'),
        hotJobArticle2Thumb: require('~/assets/images/homepage/hotjob2-logo.png'),
        hotJobArticle3Thumb: require('~/assets/images/homepage/hotjob3-logo.png'),
        hotJobArticle4Thumb: require('~/assets/images/homepage/hotjob4-logo.png'),

        fullTimeArticle: require('~/assets/images/homepage/fulltime-article-logo.png'),
        partTimeArticle: require('~/assets/images/homepage/parttime-article-logo.png'),
        shortTermArticle: require('~/assets/images/homepage/shortterm-article-logo.png'),

        featuredArticle1Thumb: require('~/assets/images/featured-article1-thumb.png'),
        featuredArticle2Thumb: require('~/assets/images/featured-article2-thumb.png'),
        featuredArticle3Thumb: require('~/assets/images/featured-article3-thumb.png'),
        featuredArticle4Thumb: require('~/assets/images/featured-article4-thumb.png'),

        featuredArticle1Logo: require('~/assets/images/featured-article1-logo.png'),
        featuredArticle2Logo: require('~/assets/images/featured-article2-logo.png'),
        featuredArticle3Logo: require('~/assets/images/featured-article3-logo.png'),
        featuredArticle4Logo: require('~/assets/images/featured-article4-logo.png'),
    },
    header: {
        saved: require('~/assets/images/savedjob.svg').default,
        savedWhite: require('~/assets/images/savedjob-white.svg').default,
        noti: require('~/assets/images/noti.svg').default,
        notiWhite: require('~/assets/images/noti-white.svg').default,
        chevronUp: require('~/assets/images/chevron-up.svg').default,
        chevronDown: require('~/assets/images/chevron-down.svg').default,
        userProfile: require('~/assets/images/user_profile.svg').default,
        cvMgt: require('~/assets/images/cv_mgt.svg').default,
        accSetting: require('~/assets/images/acc_setting.svg').default,
        accVerify: require('~/assets/images/acc_verify.svg').default,
        chatroom: require('~/assets/images/chatroom.svg').default,
        chatroomWhite: require('~/assets/images/chatroom-white.svg').default,
        searchSlash: require('~/assets/images/header-search-slash.svg').default,
        beSlasher: require('~/assets/images/be-slasher.svg').default,
    },
    pngIcon: {
        hotJobIcon: require('~/assets/images/hot-job-icon.png'),
        gikgikBabyIcon: require('~/assets/images/mobile/gikgik-baby.png'),
    },
    feature: {
        companies: require('~/assets/images/homepage/featureCompany.svg').default,
    },
};

export default images;
