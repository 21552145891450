import { useEffect, useState, useRef } from 'react';
import { faCircleXmark, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HeadlessTippy from '@tippyjs/react/headless';
import classNames from 'classnames/bind';

import * as searchServices from '~/services/searchService';
import { Wrapper as PopperWrapper } from '~/components/Popper';
import AccountItem from '~/components/AccountItem';
import { InboxIcon, MessageIcon, BellIcon, EnvelopeIcon } from '~/components/Icons';
import { useDebounce } from '~/hooks';
import styles from './ContactForm.module.scss';
import { useTranslation } from 'react-i18next';

const cx = classNames.bind(styles);

function ContactForm() {
    const [searchValue, setSearchValue] = useState('');
    const [searchResult, setSearchResult] = useState([]);
    const [showResult, setShowResult] = useState(false);
    const [loading, setLoading] = useState(false);

    const debouncedValue = useDebounce(searchValue, 500);

    const inputRef = useRef();

    const { t, i18n } = useTranslation();

    useEffect(() => {
        if (!debouncedValue.trim()) {
            setSearchResult([]);
            return;
        }

        const fetchApi = async () => {
            setLoading(true);

            const result = await searchServices.search(debouncedValue);

            setSearchResult(result);
            setLoading(false);
        };

        fetchApi();
    }, [debouncedValue]);

    const handleClear = () => {
        setSearchValue('');
        setSearchResult([]);
        inputRef.current.focus();
    };

    const handleHideResult = () => {
        setShowResult(false);
    };

    const handleChange = (e) => {
        const searchValue = e.target.value;
        if (!searchValue.startsWith(' ')) {
            setSearchValue(searchValue);
        }
    };

    return (
        // Using a wrapper <div> tag around the reference element solves
        // this by creating a new parentNode context.
          <div className={cx('form-contact-container')}>
              <div className={cx('form-contact')}>
                <span className={cx('envelope-icon')}>
                    <EnvelopeIcon />
                </span>

                <input
                    className={cx(`lang-${i18n.languages[0]}`)}
                    ref={inputRef}
                    value={searchValue}
                    placeholder="chantaiman@gmail.com"
                    spellCheck={false}
                    onChange={handleChange}
                    onFocus={() => setShowResult(true)}
                />
                <div className={cx('subcribe-button')}>
                    <span className={cx('wrapper-search-btn', `lang-${i18n.languages[0]}`)}>
                        <button
                            className={cx('submit-btn', `lang-${i18n.languages[0]}`)}
                            onMouseDown={(e) => e.preventDefault()}
                        >
                            {t('footer.subscribe')} {` `} <BellIcon width={15} height={19} />
                        </button>
                    </span>
                </div>
            </div>
          </div>

    );
}

export default ContactForm;
